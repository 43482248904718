import React, { useState } from "react";
import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import AddIcon from "@mui/icons-material/Add";
import AppModal from "components/common/appModal";
import { FormType } from "enums/formType";
import { useConfirm } from "material-ui-confirm";
import { toast } from "react-toastify";
import { useDeleteDeliveryMutation, useGetDeliveriesQuery } from "store/services/delivery.service";
import { DeliveryResponseType } from "types/delivery/deliveryResponseType";
import DeliveriesDataGrid from "components/ui/deliveriesDataGrid";
import DeliveryForm from "components/ui/forms/deliveryForm";
import AppH4 from "components/common/appH4";

const DeliveriesPage: React.FC = () => {
    const { data: deliveries } = useGetDeliveriesQuery();
    const [currentDelivery, setCurrentDelivery] = useState<DeliveryResponseType>();
    const confirm = useConfirm();
    const [deleteDelivery] = useDeleteDeliveryMutation();

    const [form, setForm] = useState<string>("");
    const [open, setOpen] = useState(false);

    const handleChooseForm = (form: string) => {
        setForm(form);
        setOpen(true);
    };

    const handleDeliveryEdit = (deliveryId: number) => {
        const delivery = deliveries?.find((delivery) => delivery.id === deliveryId);
        if (delivery) {
            setCurrentDelivery(delivery);
            handleChooseForm(FormType.DELIVERY_EDIT);
        }
    };

    const handleDeliveryDelete = (deliveryId: number) => {
        confirm({
            title: "Удаление доставки",
            description: "Вы действительно хотите удалить этот способ доставки?",
            confirmationText: "Удалить",
            cancellationText: "Отмена"
        })
            .then(async () => {
                await deleteDelivery(deliveryId).unwrap();
                setCurrentDelivery(undefined);
                toast.success("Доставка удалён");
            })
            .catch((e) => {
                if (e) {
                    toast.error("Не удалось удалить способ доставки");
                }
            });
    };

    const handleFinish = () => {
        setOpen(false);
    };

    return (
        <>
            <AppH4>Доставки</AppH4>
            <Grid>
                <Grid container justifyContent="end" mb={1}>
                    <AppButton
                        icon={<AddIcon />}
                        tooltip="Создать способ доставки"
                        onClick={() => handleChooseForm(FormType.DELIVERY_NEW)}
                    />
                </Grid>
                {deliveries && (
                    <DeliveriesDataGrid
                        deliveries={deliveries}
                        onEdit={handleDeliveryEdit}
                        onDelete={handleDeliveryDelete}
                    />
                )}
                <AppModal open={open}>
                    {form === FormType.DELIVERY_NEW && (
                        <DeliveryForm onFinish={handleFinish} />
                    )}
                    {form === FormType.DELIVERY_EDIT && (
                        <DeliveryForm
                            onFinish={handleFinish}
                            defaultValues={currentDelivery}
                        />
                    )}
                </AppModal>
            </Grid>
        </>
    );
};

export default DeliveriesPage;
