import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface IStatus {
    statusTitle: string,
    border?: boolean,
}

const Status: React.FC<IStatus> = ({ statusTitle, border = false }) => {
    const [color, setColor] = useState<"green" | "red" | "grey" | "orange" | "purple">("green");

    useEffect(() => {
        const statusTitleLowerCase = statusTitle.toLowerCase();
        switch (statusTitleLowerCase) {
            case "заказ заполняется":
                setColor("purple");
                break;
            case "неоплачен":
                setColor("orange")
                break;
            case "платёж отклонен":
            case "доставка отменена":
            case "не доставлен":
                setColor("red");
                break;
            case "доставлен":
                setColor("grey");
                break;
            default:
                setColor("green")
        }
    }, [statusTitle])

    return <Typography sx={{
        border: border ? ("1px solid " + color) : "none",
        p: 1,
        borderRadius: "10px",
        color: color
    }}>{statusTitle}</Typography>;
}

export default Status;