import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { ConfirmProvider } from "material-ui-confirm";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import store from "store";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const theme = createTheme({
    palette: {
        primary: {
            main: "#000000"
        }
    }
});

root.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <ConfirmProvider>
                <App />
            </ConfirmProvider>
        </ThemeProvider>
    </Provider>
);
