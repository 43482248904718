import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Status from 'components/ui/status';
import { useParams } from 'react-router-dom';
import { useGetOrderQuery } from 'store/services/order.service';
import { DeliveryMethodEnum } from 'types/delivery/enum/DeliveryMethod.enum';
import GalleryThumb from 'components/common/galleryThumb';
import AppH4 from '../common/appH4';
import AppModal from 'components/common/appModal';
import { FormType } from 'enums/formType';
import OrderForm from 'components/ui/forms/orderForm';
import AppIconButton from "components/common/appButton";
import EditIcon from "@mui/icons-material/Edit";
import OrderCheckOutForm from 'components/ui/forms/orderCheckOutForm';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useLazyGetPvzByCodeQuery } from 'store/services/cdek.service';
import NewTag from 'components/ui/newTag';
import { OrderItemResponseType } from 'types/order/orderItemResponseType';

interface IRow {
    title: string;
    value?: string | OrderItemResponseType[];
    sx?: Record<string, unknown>
}

const Row: React.FC<IRow> = ({ title, value = "", sx = {} }) => {

    if (Array.isArray(value)) {
        return <Grid
            sx={sx}
            container
            direction="column">
            <Typography fontWeight="bold">{title}:</Typography>

            <Grid component="ol" p={2}>
                {value.map(({ productEntity: { colorEntity, size, price }, id, dataMatrix }) => <Grid
                    component="li"
                    key={id}
                >
                    <Grid container mb={3}>
                        <Box width={100} height={100}>
                            <GalleryThumb src={colorEntity.gallery[0].src} />
                        </Box>
                        <Grid>
                            <Typography variant='body1' sx={{ textTransform: "uppercase" }}>{colorEntity.product.title}</Typography>
                            <Typography variant='body2'>{colorEntity.product.collection.value}</Typography>
                            <Typography variant='body2'>{colorEntity.color.title}</Typography>
                            <Typography variant='body2'>{size.value}</Typography>
                            <Typography variant='body2'>{(Number(price)).toFixed(2)} RUB</Typography>
                            {dataMatrix && <Typography variant='body2' sx={{ fontSize: 12 }}>Код маркировки {dataMatrix}</Typography>}
                        </Grid>
                        <Typography
                            flex={1}
                            borderBottom="1px dotted black" />
                        <Typography alignSelf="end" ml={2}>1 шт.</Typography>
                    </Grid>
                </Grid>)}
            </Grid>
        </Grid>
    }

    return <Grid sx={sx} container gap={7} alignItems="center">
        <Typography fontWeight="bold">{title}:</Typography>
        {title.toLowerCase() === "статус"
            ? <Status statusTitle={value} border />
            : <Typography sx={{ fontStyle: "italic", letterSpacing: "1px" }}>{value}</Typography>
        }
    </Grid>
}

const OrderPage = () => {
    const { orderId } = useParams();
    const { data: order } = useGetOrderQuery(orderId as string)
    const [pvz, setPvz] = useState<string>("");
    const [getPvzData] = useLazyGetPvzByCodeQuery();
    const [open, setOpen] = useState<boolean>(false);
    const [form, setForm] = useState<string>("");

    useEffect(() => {
        if (order && order.delivery?.method === DeliveryMethodEnum.COURIER) {
            getPvz(order.address);
        }
    }, [order]);

    const getPvz = async (pvzCode: string) => {
        const pvz = await getPvzData(pvzCode).unwrap()
        setPvz(pvz.location.address_full + " (" + pvzCode + ")");
    }

    const handleChooseForm = (form: string) => {
        setForm(form);
        setOpen(true);
    };

    const handleFinish = () => {
        setOpen(false);
    };

    if (!order) return <Typography>Заказ не найден...</Typography>

    const { orderNumber,
        id,
        createdAt,
        status,
        statusId,
        delivery,
        deliveryId,
        deliveryNumber,
        price,
        name,
        surname,
        email,
        phone,
        address,
        paymentNumber,
        promocodes,
        orderItems }
        = order;

    const promocode = promocodes?.[0]

    return <>
        <AppH4>
            Заказ {orderNumber}
            {statusId === 3 && <NewTag />}
        </AppH4>
        <Grid container justifyContent="end">
            <AppIconButton
                icon={<EditIcon />}
                tooltip="Редактировать заказ"
                onClick={() => handleChooseForm(FormType.ORDER_EDIT)}
            />
            {statusId === 3 && <AppIconButton
                icon={<ReceiptIcon />}
                tooltip="Собрать заказ и сформировать чек"
                onClick={() => handleChooseForm(FormType.ORDER_CHECK_OUT)}
            />}
        </Grid>
        <Grid container direction="column" gap={1}>
            <Row title='Создан' value={new Date(createdAt).toLocaleString()} />
            <Row title='Сумма' value={Number(price).toFixed(2) + " RUB"} />
            <Row title='Доставка' value={Number(delivery?.cost || 0).toFixed(2) + " RUB"} />
            {promocode && <>
                <Row title='Промокод' value={promocode.value + ", " + promocode.discount + "%"} />
                <Row title='Сумма скидки' value={Number(+price * (promocode.discount / 100)).toFixed(2) + " RUB"} />
            </>}
            <Row title='Итого' value={Number(order.totalPrice).toFixed(2) + " RUB"} />
            <Row title='Адрес доставки' value={pvz ? pvz : address} />
            <Row title='Способ доставки' value={delivery?.name} />
            <Row title='Статус' value={status.title} />
            <Row title='Номер заказа СДЭК' value={deliveryNumber} />
            <Row title='Номер платежа' value={paymentNumber} />
            <Row title='Получатель' value={name + " " + surname} />
            <Row title='E-mail' value={email} />
            <Row sx={{ mb: 3 }} title='Контактный телефон' value={"+" + phone} />
            <Row title='Товары' value={orderItems} />
        </Grid>
        <AppModal open={open}>
            {form === FormType.ORDER_EDIT && (
                <OrderForm
                    title={"Редактировать заказ " + orderNumber}
                    defaultValues={{
                        id,
                        address,
                        statusId,
                        deliveryId,
                        deliveryNumber,
                        name,
                        surname,
                        email,
                        phone,
                    }}
                    onFinish={handleFinish}
                />
            )}
            {form === FormType.ORDER_CHECK_OUT && (
                <OrderCheckOutForm
                    title={"Комплектование заказа " + orderNumber}
                    order={order}
                    onFinish={handleFinish}
                />
            )}
        </AppModal>
    </>;
}

export default OrderPage;