import React, { SyntheticEvent } from "react";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { SizeChartResponseType } from "types/sizeChart/sizeChartResponseType";
import { useNavigate } from "react-router-dom";

interface ISizeChartsDataGrid {
    sizeCharts: SizeChartResponseType[];
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
}

const SizeChartsDataGrid: React.FC<ISizeChartsDataGrid> = ({
    sizeCharts,
    onEdit,
    onDelete
}) => {
    const navigate = useNavigate();

    const rows: GridRowsProp = sizeCharts.map((c) => ({
        id: c.id,
        col1: c.title
    }));

    const handleEdit = (e: SyntheticEvent<Element>, sizeChartId: number) => {
        e.stopPropagation();
        onEdit(sizeChartId);
    }

    const handleDelete = (e: SyntheticEvent<Element>, sizeChartId: number) => {
        e.stopPropagation();
        onDelete(sizeChartId);
    }

    const columns: GridColDef[] = [
        { field: "col1", headerName: "Название", flex: 1, minWidth: 200 },
        {
            field: "col2",
            headerName: "",
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Grid justifyContent="end" container>
                        <AppButton
                            icon={<EditIcon />}
                            tooltip="Редактировать"
                            onClick={(e) => handleEdit(e, params.row.id)}
                        />
                        <AppButton
                            icon={<DeleteIcon />}
                            tooltip="Удалить"
                            onClick={(e) => handleDelete(e, params.row.id)}
                        />
                    </Grid>
                );
            }
        }
    ];

    return (
        <DataGrid
            disableRowSelectionOnClick
            autoHeight
            sx={{
                height: "500px",
                cursor: "pointer",
                "& .MuiDataGrid-cell:focus": {
                    outline: "none"
                },
                "& .MuiDataGrid-cell:focus-within": {
                    outline: "none"
                }
            }}
            rows={rows}
            columns={columns}
            onRowClick={(e) =>
                navigate(`/sizeChart/${e.id}`)
            }
        />
    );
};

export default SizeChartsDataGrid;
