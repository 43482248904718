import * as React from "react";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { ProductResponseType } from "types/product/productResponseType";
import { useNavigate, useSearchParams } from "react-router-dom";

interface IProductsDataGrid {
    products: ProductResponseType[];
}

const ProductsDataGrid: React.FC<IProductsDataGrid> = ({ products }) => {
    const navigate = useNavigate();
    const [search] = useSearchParams();

    const rows: GridRowsProp = products.map((p) => ({
        id: p.id,
        col1: p.title,
        col2: p.collection.value,
        col3: p.deletedAt
    })) as GridRowsProp;

    const columns: GridColDef[] = [
        { field: "col1", headerName: "Продукт", flex: 1, minWidth: 200 },
        { field: "col2", headerName: "Коллекция", flex: 1, minWidth: 250 },
        { field: "col3", headerName: "Удалён", flex: 1, minWidth: 250 }
    ];
    return (
        <DataGrid
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight
            sx={{
                height: "500px",
                cursor: "pointer",
                "& .MuiDataGrid-cell:focus": {
                    outline: "none"
                }
            }}
            rows={rows}
            columns={columns}
            getRowClassName={(params) => params.row.col3 ? "softDeleted" : ""}
            onRowClick={(e) =>
                navigate(`/products/${e.id}`, {
                    state: { categoryId: search.get("categoryId") }
                })
            }
        />
    );
};

export default ProductsDataGrid;
