import React from "react";
import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ProductEntitiesDataGrid from "../productEntitiesDataGrid";
import { ProductEntityResponseType } from "types/productEntity/productEntityResponseType";
import { FormType } from "enums/formType";
import AppIconButton from "components/common/appButton";

interface IProductEntitiesCard {
    currentProductEntities?: ProductEntityResponseType[];
    onFormChoose: (form: string) => void;
    onProductEntityEdit: (id: number) => void;
    onProductEntityDelete: (id: number) => void;
}

const ProductEntitiesCard: React.FC<IProductEntitiesCard> = ({
    currentProductEntities,
    onFormChoose,
    onProductEntityEdit,
    onProductEntityDelete
}) => {
    return (
        <Grid>
            <Grid container justifyContent="end" mb={1}>
                <AppIconButton
                    icon={<AddIcon />}
                    tooltip="Добавить продукт"
                    onClick={() => onFormChoose(FormType.PRODUCT_ENTITY_NEW)}
                />
            </Grid>
            {!!currentProductEntities?.length && (
                <ProductEntitiesDataGrid
                    productEntites={currentProductEntities}
                    onEdit={onProductEntityEdit}
                    onDelete={onProductEntityDelete}
                />
            )}
        </Grid>
    );
};

export default ProductEntitiesCard;
