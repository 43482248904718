import * as React from "react";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { ProductEntityResponseType } from "types/productEntity/productEntityResponseType";
import AppButton from "components/common/appButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Chip, Grid } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { TagResponseType } from "types/tag/tagResponseType";

interface IProductEntitiesDataGrid {
    productEntites: ProductEntityResponseType[];
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
}

const ProductEntitiesDataGrid: React.FC<IProductEntitiesDataGrid> = ({
    productEntites,
    onEdit,
    onDelete
}) => {
    const confirm = useConfirm();

    const rows: GridRowsProp = productEntites.map((p) => ({
        id: p.id,
        col1: p.size.value,
        col2: p.price,
        col3: p.oldPrice,
        col4: p.width,
        col5: p.length,
        col6: p.height,
        col7: p.weight,
        col8: p.stock,
        col9: p.tags,
        col10: p.deletedAt
    })) as GridRowsProp;

    const columns: GridColDef[] = [
        { field: "col1", headerName: "Размер", flex: 1, minWidth: 0 },
        { field: "col2", headerName: "Цена", flex: 1, minWidth: 0 },
        { field: "col3", headerName: "Старая цена", flex: 1, minWidth: 0 },
        { field: "col4", headerName: "Ширина (см.)", flex: 1, minWidth: 0 },
        { field: "col5", headerName: "Длина (см.)", flex: 1, minWidth: 0 },
        { field: "col6", headerName: "Высота (см.)", flex: 1, minWidth: 0 },
        { field: "col7", headerName: "Вес (гр.)", flex: 1, minWidth: 0 },
        { field: "col8", headerName: "Остаток (шт.)" },
        {
            field: "col9", headerName: "Тэги", minWidth: 0, renderCell: ({ value }) => {
                return <Grid container>
                    {(value as TagResponseType[]).map(tag => <Chip key={tag.id} label={tag.title} />)}
                </Grid>;
            }
        },
        { field: "col10", headerName: "Удалён", flex: 1 },
        {
            field: "col11",
            headerName: "",
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Grid justifyContent="end" container>
                        <AppButton
                            icon={<EditIcon />}
                            tooltip="Редактировать"
                            onClick={() => onEdit(params.row.id)}
                        />
                        <AppButton
                            icon={<DeleteIcon />}
                            tooltip="Удалить"
                            onClick={() =>
                                confirm({
                                    description:
                                        "Вы уверены, что хотите удалить продукт?"
                                })
                                    .then(() => onDelete(params.row.id))
                                    .catch(() => undefined)
                            }
                        />
                    </Grid>
                );
            }
        }
    ];
    return (
        <DataGrid
            disableRowSelectionOnClick
            autoHeight
            sx={{
                height: "500px",
                "& .MuiDataGrid-cell:focus": {
                    outline: "none"
                },
                "& .MuiDataGrid-cell:focus-within": {
                    outline: "none"
                },
                "& .softDeleted": {
                    opacity: 0.2
                }
            }}
            getRowClassName={(params) => params.row.col10 ? "softDeleted" : ""}
            rows={rows}
            columns={columns}
        />
    );
};

export default ProductEntitiesDataGrid;
