import React from "react";
import {
    createBrowserRouter,
    Navigate,
    RouterProvider
} from "react-router-dom";
import ProductsPage from "components/pages/productsPage";
import ProductPage from "components/pages/productPage";
import MainLayout from "layouts/main.layout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ColorsPage from "components/pages/colorsPage";
import SizesPage from "components/pages/sizesPage";
import CollectionsPage from "components/pages/collectionsPage";
import LoginPage from "components/pages/loginPage";
import TagsPage from "components/pages/tagsPage";
import ProtectedRoute from "components/common/protectedRoute";
import { Box } from "@mui/material";
import AppHeader from "components/common/appHeader";
import InitPage from "components/pages/initPage";
import { useGetMeQuery } from "store/services/user.service";
import SetsPage from "components/pages/setsPage";
import SetPage from "components/pages/setPage";
import DeliveriesPage from "components/pages/deliveriesPage";
import CategoriesPage from "components/pages/categoriesPage";
import OrdersPage from "components/pages/ordersPage";
import OrderPage from "components/pages/orderPage";
import SizeChartsPage from "components/pages/sizeChartsPage";
import SizeChartPage from "components/pages/sizeChartPage";
import PromocodesPage from "components/pages/promocodesPage";
import PromocodePage from "components/pages/promocodePage";
import UsersPage from "components/pages/usersPage";
import YmlFeedPage from "components/pages/ymlFeedPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <ProtectedRoute>
                <MainLayout />
            </ProtectedRoute>
        ),
        children: [
            { path: "", element: <Navigate to="products" /> },
            {
                path: "categories",
                element: <CategoriesPage />
            },
            {
                path: "products",
                element: <ProductsPage />
            },
            {
                path: "products/:productId",
                element: <ProductPage />
            },
            {
                path: "colors",
                element: <ColorsPage />
            },
            {
                path: "sets",
                element: <SetsPage />
            },
            {
                path: "sets/:setId",
                element: <SetPage />
            },
            {
                path: "sizes",
                element: <SizesPage />
            },
            {
                path: "sizeCharts",
                element: <SizeChartsPage />
            },
            {
                path: "sizeChart/:sizeChartId",
                element: <SizeChartPage />
            },
            {
                path: "collections",
                element: <CollectionsPage />
            },
            {
                path: "tags",
                element: <TagsPage />
            },
            {
                path: "promocodes",
                element: <PromocodesPage />
            },
            {
                path: "promocodes/:promocodeId",
                element: <PromocodePage />
            },
            {
                path: "delivery",
                element: <DeliveriesPage />
            },
            {
                path: "orders",
                element: <OrdersPage />
            },
            {
                path: "orders/:orderId",
                element: <OrderPage />
            },
            {
                path: "users",
                element: <UsersPage />
            },
            {
                path: "yml-feed",
                element: <YmlFeedPage />
            },
            { path: "init", element: <InitPage /> }
        ]
    },
    {
        path: "/login",
        element: <LoginPage />
    }
]);

function App() {
    const { isLoading } = useGetMeQuery();
    return (
        <>
            <AppHeader />
            <Box sx={{ pt: "64px" }}>
                {isLoading ? <div>Loading...</div> : <RouterProvider router={router} />}
            </Box>
            <ToastContainer />
        </>
    );
}

export default App;
