import { Button, Grid, Tooltip } from "@mui/material";
import React from "react";

interface IAppButton {
    icon?: React.ReactNode;
    onClick?: (e: React.SyntheticEvent) => void;
    tooltip?: string;
    children?: React.ReactNode;
    disabled?: boolean;
    variant?: "contained" | "outlined";
    type?: "submit" | "button"
}

const AppButton: React.FC<IAppButton> = ({
    icon,
    onClick,
    tooltip = "",
    disabled = false,
    variant = "outlined",
    children,
    type = "button"
}) => {
    return (
        <Tooltip title={tooltip}>
            <Grid component="span">
                <Button
                    variant={variant}
                    disabled={disabled}
                    size="small"
                    startIcon={icon}
                    onClick={onClick}
                    type={type}
                    sx={{
                        m: "2px",
                        minWidth: "fit-content",
                        "& .MuiButton-startIcon": {
                            margin: 0
                        }
                    }}
                >
                    {children}
                </Button>
            </Grid>
        </Tooltip>
    );
};

export default AppButton;
