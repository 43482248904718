import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import { GalleryResponseType } from "types/gallery/galleryResponseType";
import { productApi } from "./product.service";
import { appBaseQueryWithReauth } from "./http.service";
import { toastProgress } from "utils/toastProgress";

const galleryEndpoint = config.apiEndpoint + "/gallery";

export const galleryApi = createApi({
    reducerPath: "galleryApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: galleryEndpoint }),
    tagTypes: ["Gallery"],
    endpoints: (builder) => ({
        getGalleries: builder.query<GalleryResponseType[], void>({
            query: () => ({
                url: "/",
                method: "GET"
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.map(({ id }) => ({
                              type: "Gallery" as const,
                              id
                          })),
                          "Gallery"
                      ]
                    : ["Gallery"]
        }),
        getGalleriesByIds: builder.query<GalleryResponseType[], string[]>({
            query: (ids) => ({
                url: `/${ids}`,
                method: "GET"
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.map(({ id }) => ({
                              type: "Gallery" as const,
                              id
                          })),
                          "Gallery"
                      ]
                    : ["Gallery"]
        }),
        createGalleries: builder.mutation<GalleryResponseType[], FormData>({
            query: (payload) => ({
                url: "/",
                method: "POST",
                data: payload,
                onUploadProgress: toastProgress
            }),
            invalidatesTags: ["Gallery"],
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(productApi.util.invalidateTags(["Product"]));
            }
        }),
        updateGallery: builder.mutation<
            GalleryResponseType,
            { id: number; payload: FormData }
        >({
            query: ({ id, payload }) => ({
                url: `/${id}`,
                method: "PATCH",
                data: payload,
                onUploadProgress: toastProgress
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(productApi.util.invalidateTags(["Product"]));
            }
        }),
        deleteGallery: builder.mutation<number, number>({
            query: (id) => ({
                url: `/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["Gallery"],
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(productApi.util.invalidateTags(["Product"]));
            }
        }),
        reorderGallery: builder.mutation<GalleryResponseType[], number[]>({
            query: (ids) => ({
                url: `/reorder/${ids}`,
                method: "PATCH"
            }),
            invalidatesTags: ["Gallery"],
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(productApi.util.invalidateTags(["Product"]));
            }
        })
    })
});

export const {
    useGetGalleriesQuery,
    useCreateGalleriesMutation,
    useLazyGetGalleriesByIdsQuery,
    useGetGalleriesByIdsQuery,
    useDeleteGalleryMutation,
    useUpdateGalleryMutation,
    useReorderGalleryMutation
} = galleryApi;
