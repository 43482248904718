import React from 'react';
import AppSelect from "components/common/appSelect";
import { useGetSizesQuery } from 'store/services/size.service';

interface ISizesSelect {
    name: string;
    label: string;
    value: string;
    onChange: (target: { name: string; value: string }) => void;
    error?: string;
}

const SizesSelect: React.FC<ISizesSelect> = ({
    name,
    label,
    value,
    onChange,
    error
}) => {
    const { data: sizes } = useGetSizesQuery();

    if (!sizes) return null;

    return <AppSelect
        name={name}
        label={label}
        value={value}
        error={error}
        onChange={onChange}
    >
        {sizes.map((size) => (
            <AppSelect.AppOption
                value={size.id.toString()}
                key={size.id}
            >
                {size.value}
            </AppSelect.AppOption>
        ))}
    </AppSelect>
}

export default SizesSelect;