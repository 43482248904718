import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import { ColorResponseType } from "types/color/colorResponseType";
import { appBaseQueryWithReauth } from "./http.service";
import { toastProgress } from "utils/toastProgress";

const colorEndpoint = config.apiEndpoint + "/color";

export const colorApi = createApi({
    reducerPath: "colorApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: colorEndpoint }),
    tagTypes: ["Color"],
    endpoints: (builder) => ({
        getColors: builder.query<ColorResponseType[], void>({
            query: () => ({
                url: "/",
                method: "GET"
            }),
            providesTags: ["Color"]
        }),
        createColor: builder.mutation<ColorResponseType, FormData>({
            query: (payload) => ({
                url: "/",
                method: "POST",
                data: payload,
                onUploadProgress: toastProgress
            }),
            invalidatesTags: ["Color"]
        }),
        updateColor: builder.mutation<
            ColorResponseType,
            { id: number; payload: FormData }
        >({
            query: ({ id, payload }) => ({
                url: `/${id}`,
                method: "PATCH",
                data: payload,
                onUploadProgress: toastProgress
            }),
            invalidatesTags: ["Color"]
        }),
        deleteColor: builder.mutation<number, number>({
            query: (id) => ({
                url: `/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["Color"]
        })
    })
});

export const {
    useGetColorsQuery,
    useCreateColorMutation,
    useDeleteColorMutation,
    useUpdateColorMutation
} = colorApi;
