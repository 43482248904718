import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import AppTextField from "components/common/appTextField";
import FormHeader from "components/common/formHeader";
import React, { useEffect, useState } from "react";
import { validator } from "utils/validator";
import { toast } from "react-toastify";
import { SizeResponseType } from "types/size/sizeResponseType";
import {
    useCreateSizeMutation,
    useGetSizesQuery,
    useUpdateSizeMutation
} from "store/services/size.service";

interface IData {
    value: string;
}

interface ISizeForm {
    onFinish: () => void;
    defaultValues?: SizeResponseType;
    title?: string;
}

const SizeForm: React.FC<ISizeForm> = ({ defaultValues, onFinish, title }) => {
    const { data: sizes } = useGetSizesQuery();
    const isEdit = !!defaultValues;
    const [data, setData] = useState<IData>(
        isEdit
            ? {
                value: defaultValues.value
            }
            : {
                value: ""
            }
    );

    const [errors, setErrors] = useState<{
        value?: string;
    }>({});
    const isValid = Object.keys(errors).length === 0;
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [updateSize] = useUpdateSizeMutation();
    const [createSize] = useCreateSizeMutation();

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [data]);

    const validatorConfig = {
        value: {
            isRequired: {
                message: "Укажите размер"
            }
        }
    };

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (target: { name: string; value: string }) => {
        setIsUpdated(true);
        setData((prevState) => ({
            ...prevState,
            [target.name]: target.value
        }));
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsTrySubmit(true);
        const isValid = validate();
        if (!isValid) return;
        if (isEdit) {
            try {
                await updateSize({
                    id: defaultValues.id,
                    position: defaultValues.position,
                    ...data
                }).unwrap();
                toast.success("Размер успешно обновлён");
            } catch (e) {
                return toast.error("Не удалось обновить размер");
            }
        } else {
            try {
                const position = sizes ? sizes?.length + 1 : 1
                await createSize({ ...data, position }).unwrap();
                toast.success("Размер создан");
            } catch (e) {
                return toast.error("Не удалось создать размер");
            }
        }
        onFinish();
    };

    return (
        <>
            {title && <FormHeader title={title} />}
            <form onSubmit={handleSubmit}>
                <Grid container wrap="nowrap" alignItems="center">
                    <AppTextField
                        name="value"
                        onChange={handleChange}
                        value={data.value}
                        placeholder="Размер"
                        error={errors.value}
                    />
                </Grid>
                <Grid container justifyContent="space-between" mt={1}>
                    <AppButton onClick={onFinish} variant="contained">
                        Отмена
                    </AppButton>
                    <AppButton
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={!isValid || !isUpdated}
                    >
                        Сохранить
                    </AppButton>
                </Grid>
            </form>
        </>
    );
};

export default SizeForm;
