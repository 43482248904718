import { AxiosProgressEvent } from "axios";
import { Id, toast } from "react-toastify";

let currentToastId: Id | null = null;

export const toastProgress = (p: AxiosProgressEvent) => {
    const { loaded, total = 1 } = p;
    const progress = loaded / total;

    const label = "Загрузка " + Math.round((100 * loaded) / total) + "%";

    if (progress === 1) {
        currentToastId && toast.done(currentToastId);
        currentToastId = null;
        return;
    }

    if (currentToastId === null) {
        currentToastId = toast(label, { progress });
    } else {
        toast.update(currentToastId, { progress, render: label });
    }
};
