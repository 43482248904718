import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import { CollectionRequestType } from "types/collection/collectionRequestType";
import { CollectionResponseType } from "types/collection/collectionResponseType";
import { appBaseQueryWithReauth } from "./http.service";

const collectionEndpoint = config.apiEndpoint + "/collection";

export const collectionApi = createApi({
    reducerPath: "collectionApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: collectionEndpoint }),
    tagTypes: ["Collection"],
    endpoints: (builder) => ({
        getCollections: builder.query<CollectionResponseType[], void>({
            query: () => ({
                url: "/",
                method: "GET"
            }),
            providesTags: ["Collection"]
        }),
        createCollection: builder.mutation<
            CollectionResponseType,
            CollectionRequestType
        >({
            query: (payload) => ({
                url: "/",
                method: "POST",
                data: payload
            }),
            invalidatesTags: ["Collection"]
        }),
        updateCollection: builder.mutation<
            CollectionResponseType,
            CollectionRequestType
        >({
            query: ({ id, ...payload }) => ({
                url: `/${id}`,
                method: "PATCH",
                data: payload
            }),
            invalidatesTags: ["Collection"]
        }),
        deleteCollection: builder.mutation<number, number>({
            query: (id) => ({
                url: `/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["Collection"]
        })
    })
});

export const {
    useCreateCollectionMutation,
    useGetCollectionsQuery,
    useDeleteCollectionMutation,
    useUpdateCollectionMutation
} = collectionApi;
