import React from "react";
import { useCreateCategoryMutation } from "store/services/category.service";
import { categories } from "../../mock/categories";
import { CategoryRequestType } from "types/category/categoryRequestType";

const InitPage: React.FC = () => {
    const [createCategory] = useCreateCategoryMutation();

    const handleCreateCategories = async () => {
        for (const category of categories) {
            const formData = new FormData();
            Object.keys(category).forEach(key => {
                const typedKey = key as keyof CategoryRequestType;
                formData.append(key, category[typedKey] as string);
            })
            await createCategory(formData);
        }
    };

    return (
        <div>
            <h1>init categories</h1>
            <button onClick={handleCreateCategories}>Start</button>
        </div>
    );
};

export default InitPage;
