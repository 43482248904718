import React from "react";
import { config } from "config";

interface IColorThumb {
    src: string;
}

const ColorThumb: React.FC<IColorThumb> = ({ src }) => {
    return (
        <img
            style={{ display: "block", width: "20px", boxShadow: "0px 0px 1px rgb(0, 0, 0)", borderRadius: "100%" }}
            src={config.endpoint + src}
            alt="color thumb"
        />
    );
};

export default ColorThumb;
