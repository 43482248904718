import { ClientJS } from "clientjs";

// try ClientJS for fingerprinting!!!
export const getFingerprint = () => {
    if (typeof window !== "undefined") {
        // const { ClientJS } = require("clientjs");

        const client = new ClientJS();

        // Get the client's fingerprint id
        const fingerprint = client.getFingerprint();

        // Print the 32bit hash id to the console
        return fingerprint;
    }
};
