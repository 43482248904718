import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import { appBaseQueryWithReauth } from "./http.service";
import { StatusResponseType } from "types/status/statusResponseType";

const statusEndpoint = config.apiEndpoint + "/status";

export const statusApi = createApi({
    reducerPath: "statusApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: statusEndpoint }),
    tagTypes: ["Status"],
    endpoints: (builder) => ({
        getStatuses: builder.query<StatusResponseType[], void>({
            query: () => ({
                url: "/all",
                method: "GET"
            }),
            providesTags: ["Status"]
        })
    })
});

export const { useGetStatusesQuery } = statusApi;
