import React from 'react';
import { Link as LinkBase } from "@mui/material";
import { Link } from "react-router-dom";

interface IAppLink {
    to: string,
    children: React.ReactNode
}

const AppLink: React.FC<IAppLink> = ({ to, children }) => {
    return <LinkBase component={Link} to={to}>{children}</LinkBase>;
}

export default AppLink;