import React from 'react';
import { useGetAllSetsQuery } from "store/services/set.service";
import AppSelect from 'components/common/appSelect';

interface ISetsSelect {
    name: string;
    label: string;
    value: string;
    onChange: (target: { name: string; value: string }) => void;
    error?: string;
}

const SetsSelect: React.FC<ISetsSelect> = ({
    name,
    label,
    value,
    onChange,
    error
}) => {
    const { data: sets } = useGetAllSetsQuery();

    if (!sets) return null;

    return <AppSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        error={error}
    >
        {sets.map((set) => (
            <AppSelect.AppOption
                value={set.id.toString()}
                key={set.id}
            >
                {set.title} ({set.collection.value})
            </AppSelect.AppOption>
        ))}
    </AppSelect>;
}

export default SetsSelect;