import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import AppTextField from "components/common/appTextField";
import FormHeader from "components/common/formHeader";
import React, { useEffect, useState } from "react";
import { validator } from "utils/validator";
import { toast } from "react-toastify";
import { PromocodeResponseType } from "types/promocode/promocodeResponseType";
import { useCreatePromocodeMutation, useUpdatePromocodeMutation } from "store/services/promocode.service";
import { PromocodeRequestType } from "types/promocode/promocodeRequestType";
import PromocodeUsersMultiSelect from "./selects/promocodeUsersMultiSelect";

interface IPromocodeForm {
    onFinish: () => void;
    defaultValues?: PromocodeResponseType;
    title?: string;
}

const PromocodeForm: React.FC<IPromocodeForm> = ({ defaultValues, onFinish, title }) => {
    const isEdit = !!defaultValues;
    const [data, setData] = useState<PromocodeRequestType>(
        isEdit
            ? {
                value: defaultValues.value,
                discount: defaultValues.discount.toString(),
                users: defaultValues.users.map(u => u.id.toString())
            }
            : {
                value: "",
                discount: "",
                users: []
            }
    );

    const [errors, setErrors] = useState<Partial<PromocodeRequestType>>({});
    const isValid = Object.keys(errors).length === 0;
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [updatePromocode] = useUpdatePromocodeMutation();
    const [createPromocode] = useCreatePromocodeMutation();

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [data]);

    const validatorConfig = {
        value: {
            isRequired: {
                message: "Укажите промокод"
            }
        },
        discount: {
            isRequired: {
                message: "Укажите размер скидки"
            }
        }
    };

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (target: { name: string; value: string | string[] }) => {
        setIsUpdated(true);
        const { name, value } = target;
        setData((prevState) => {
            if (typeof value === "string") {
                return {
                    ...prevState, [name]: name === "value"
                        ? value.trim().toUpperCase()
                        : value.trim()
                };
            }
            return { ...prevState, [target.name]: target.value };
        });
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsTrySubmit(true);
        const isValid = validate();
        if (!isValid) return;
        if (isEdit) {
            try {
                await updatePromocode({
                    id: defaultValues.id,
                    ...data
                }).unwrap();
                toast.success("Промокод успешно обновлён");
            } catch (e) {
                return toast.error("Не удалось обновить промокод");
            }
        } else {
            try {
                await createPromocode(data).unwrap();
                toast.success("Промокод создан");
            } catch (e) {
                return toast.error("Не удалось создать промокод");
            }
        }
        onFinish();
    };

    return (
        <>
            {title && <FormHeader title={title} />}
            <form onSubmit={handleSubmit}>
                <Grid container wrap="nowrap" alignItems="center">
                    <AppTextField
                        name="value"
                        label="Промокод"
                        onChange={handleChange}
                        value={data.value}
                        placeholder="Промокод"
                        error={errors.value}
                    />
                    <AppTextField
                        type="number"
                        label="Размер скидки"
                        name="discount"
                        onChange={handleChange}
                        value={data.discount}
                        placeholder="Размер скидки"
                        error={errors.discount}
                    />
                    <PromocodeUsersMultiSelect
                        label="Пользователи"
                        name="users"
                        value={data.users}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid container justifyContent="space-between" mt={1}>
                    <AppButton onClick={onFinish} variant="contained">
                        Отмена
                    </AppButton>
                    <AppButton
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={!isValid || !isUpdated}
                    >
                        Сохранить
                    </AppButton>
                </Grid>
            </form>
        </>
    );
};

export default PromocodeForm;
