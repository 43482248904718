import React, { useState } from "react";
import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import AddIcon from "@mui/icons-material/Add";
import {
    useDeleteColorMutation,
    useGetColorsQuery
} from "store/services/color.service";
import AppModal from "components/common/appModal";
import ColorsDataGrid from "components/ui/colorsDataGrid";
import { FormType } from "enums/formType";
import { useConfirm } from "material-ui-confirm";
import { ColorResponseType } from "types/color/colorResponseType";
import { toast } from "react-toastify";
import ColorForm from "components/ui/forms/colorForm";
import AppH4 from "components/common/appH4";

const ColorsPage: React.FC = () => {
    const { data: colors } = useGetColorsQuery();
    const [currentColor, setCurrentColor] = useState<ColorResponseType>();
    const confirm = useConfirm();
    const [deleteColor] = useDeleteColorMutation();

    const [form, setForm] = useState<string>("");
    const [open, setOpen] = useState(false);

    const handleChooseForm = (form: string) => {
        setForm(form);
        setOpen(true);
    };

    const handleColorEdit = (colorId: number) => {
        const color = colors?.find((color) => color.id === colorId);
        if (color) {
            setCurrentColor(color);
            handleChooseForm(FormType.COLOR_EDIT);
        }
    };

    const handleColorDelete = (colorId: number) => {
        confirm({
            title: "Удаление цвета",
            description: "Вы действительно хотите удалить этот цвет?",
            confirmationText: "Удалить",
            cancellationText: "Отмена"
        })
            .then(async () => {
                await deleteColor(colorId).unwrap();
                setCurrentColor(undefined);
                toast.success("Цвет удалён");
            })
            .catch((e) => {
                if (e) {
                    toast.error("Не удалось удалить цвет");
                }
            });
    };

    const handleFinish = () => {
        setOpen(false);
    };

    return (
        <>
            <AppH4>Цвета</AppH4>
            <Grid>
                <Grid container justifyContent="end" mb={1}>
                    <AppButton
                        icon={<AddIcon />}
                        tooltip="Создать цвет"
                        onClick={() => handleChooseForm(FormType.COLOR_NEW)}
                    />
                </Grid>
                {colors && (
                    <ColorsDataGrid
                        colors={colors}
                        onEdit={handleColorEdit}
                        onDelete={handleColorDelete}
                    />
                )}
                <AppModal open={open}>
                    {form === FormType.COLOR_NEW && (
                        <ColorForm onFinish={handleFinish} />
                    )}
                    {form === FormType.COLOR_EDIT && (
                        <ColorForm
                            onFinish={handleFinish}
                            defaultValues={currentColor}
                        />
                    )}
                </AppModal>
            </Grid>
        </>
    );
};

export default ColorsPage;
