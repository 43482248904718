import { Box } from "@mui/material";
import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ru from "react-phone-input-2/lang/ru.json";
import AppFieldLabel from "./appFieldLabel";

export type AppPhoneInputType = {
    name: string;
    value: string;
    dialCode: string | any[];
    countryCode: string;
};

interface IAppPhoneInput {
    name: string;
    value: string;
    error?: string;
    onChange: (target: AppPhoneInputType) => void;
    label?: string;
}

const AppPhoneInput: React.FC<IAppPhoneInput> = ({
    name,
    error,
    value,
    onChange,
    label
}) => {
    const handleChangePhone = (
        phone: string,
        data: { dialCode: string | any[]; countryCode: string }
    ) => {
        onChange({
            name: name,
            value: phone,
            dialCode: data.dialCode,
            countryCode: data.countryCode
        });
    };
    return (
        <Box sx={{ width: "100%" }}>
            {label && <AppFieldLabel>{label}:</AppFieldLabel>}
            <PhoneInput
                onlyCountries={["ru", "kz", "by"]}
                inputStyle={{
                    flex: 1,
                    width: "100%",
                    border: "1px solid rgb(0 0 0)",
                    borderRadius: "0"
                }}
                buttonStyle={{
                    background: "none",
                    border: "none",
                    borderRight: "1px solid black"
                }}
                dropdownStyle={{
                    marginTop: "0px"
                }}
                localization={ru}
                country={"ru"}
                countryCodeEditable={false}
                value={value}
                onChange={handleChangePhone}
                copyNumbersOnly={false}
            />
            {error && <div style={{ color: "red" }}>{error}</div>}
        </Box>
    );
};

export default AppPhoneInput;
