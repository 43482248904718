import React, { useEffect, useState } from "react";
import AppTextField from "components/common/appTextField";
import { ProductEntityRequestType } from "types/productEntity/productEntityRequestType";
import { validator } from "utils/validator";
import { ProductEntityResponseType } from "types/productEntity/productEntityResponseType";
import { Grid } from "@mui/material";
import { toast } from "react-toastify";
import {
    useCreateProductEntityMutation,
    useUpdateProductEntityMutation
} from "store/services/productEntity.service";
import FormHeader from "components/common/formHeader";
import AppButton from "components/common/appButton";
import SizesSelect from "./selects/sizesSelect";
import TagsMultiSelect from "./selects/tagsMultiSelect";
import { useIsMobile } from "hook/useIsMobile";

interface IProductEntityForm {
    defaultValues: ProductEntityResponseType | { colorEntityId: number };
    onFinish: () => void;
    title?: string;
}

const ProductEntityForm: React.FC<IProductEntityForm> = ({
    defaultValues,
    onFinish,
    title
}) => {
    const isEdit = "id" in defaultValues;

    const [data, setData] = useState<ProductEntityRequestType>(
        isEdit
            ? {
                price: defaultValues.price,
                oldPrice: defaultValues.oldPrice,
                sizeId: defaultValues.sizeId?.toString(),
                colorEntityId: defaultValues.colorEntityId.toString(),
                width: defaultValues.width.toString(),
                length: defaultValues.length.toString(),
                height: defaultValues.height.toString(),
                weight: defaultValues.weight.toString(),
                stock: defaultValues.stock.toString(),
                tags: defaultValues.tags.map((t) => t.id.toString())
            }
            : {
                price: "",
                oldPrice: "",
                sizeId: "",
                colorEntityId: defaultValues.colorEntityId.toString(),
                width: "",
                length: "",
                height: "",
                weight: "",
                stock: "",
                tags: []
            }
    );
    const [errors, setErrors] = useState<{
        price?: string;
        oldPrice?: string;
        sizeId?: string;
        colorEntityId?: string;
        width?: string;
        length?: string;
        height?: string;
        weight?: string;
        stock?: string;
    }>({});
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const isValid = Object.keys(errors).length === 0;
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [updateProductEntity] = useUpdateProductEntityMutation();
    const [createProductEntity] = useCreateProductEntityMutation();
    const { isMobile } = useIsMobile();

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [data]);

    const validatorConfig = {
        price: {
            isRequired: {
                message: "Укажите цену"
            }
        },
        sizeId: {
            isRequired: {
                message: "Выберите цвет"
            }
        },
        width: {
            isRequired: {
                message: "Укажите ширину"
            }
        },
        length: {
            isRequired: {
                message: "Укажите длину"
            }
        },
        height: {
            isRequired: {
                message: "Укажите высоту"
            }
        },
        weigth: {
            isRequired: {
                message: "Укажите вес"
            }
        },
        stock: {
            isRequired: {
                message: "Укажите остаток товара на складе"
            }
        },
    };

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (target: { name: string; value: string | string[] }) => {
        setIsUpdated(true);
        setData((prevState) => {
            if (typeof target.value === "string") {
                return { ...prevState, [target.name]: target.value.toString() };
            }
            return { ...prevState, [target.name]: target.value };
        });
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsTrySubmit(true);
        const isValid = validate();
        if (!isValid) return;
        if (isEdit) {
            try {
                const updatedProductEntity = {
                    id: defaultValues.id,
                    ...data
                };
                await updateProductEntity(updatedProductEntity).unwrap();
                toast.success("Продукт успешно обновлён");
            } catch (e) {
                return toast.error("Не удалось обновить продукт");
            }
        } else {
            try {
                await createProductEntity(data).unwrap();
                toast.success("Продукт создан");
            } catch (e) {
                return toast.error("Не удалось создать продукт");
            }
        }
        onFinish();
    };

    const handleCancel = () => {
        onFinish();
    };

    return (
        <>
            {title && <FormHeader title={title} />}
            <form onSubmit={handleSubmit}>
                <Grid container flexDirection={isMobile ? "column" : "row"} wrap="nowrap" gap={1} alignItems="end">
                    <SizesSelect
                        name="sizeId"
                        label="Размер"
                        value={data.sizeId}
                        error={errors.sizeId}
                        onChange={handleChange}
                    />
                    <AppTextField
                        label="Цена"
                        name="price"
                        type="number"
                        onChange={handleChange}
                        value={data.price}
                        error={errors.price}
                        placeholder="Цена"
                    />
                    <AppTextField
                        label="Старая цена"
                        name="oldPrice"
                        type="number"
                        onChange={handleChange}
                        value={data.oldPrice}
                        error={errors.oldPrice}
                        placeholder="Цена"
                    />
                    <AppTextField
                        label="Ширина (см.)"
                        name="width"
                        type="number"
                        onChange={handleChange}
                        value={data.width}
                        error={errors.width}
                        placeholder="Ширина (см.)"
                    />
                    <AppTextField
                        label="Длина (см.)"
                        name="length"
                        type="number"
                        onChange={handleChange}
                        value={data.length}
                        error={errors.length}
                        placeholder="Длина (см.)"
                    />
                    <AppTextField
                        label="Высота (см.)"
                        name="height"
                        type="number"
                        onChange={handleChange}
                        value={data.height}
                        error={errors.height}
                        placeholder="Высота (см.)"
                    />
                    <AppTextField
                        label="Вес (гр.)"
                        name="weight"
                        type="number"
                        onChange={handleChange}
                        value={data.weight}
                        error={errors.weight}
                        placeholder="Вес (гр.)"
                    />
                    <AppTextField
                        label="Остаток"
                        name="stock"
                        type="number"
                        onChange={handleChange}
                        value={data.stock}
                        error={errors.stock}
                        placeholder="Остаток"
                    />
                    <TagsMultiSelect
                        label="Тэги"
                        name="tags"
                        value={data.tags}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid container justifyContent="space-between" mt={1}>
                    <AppButton onClick={handleCancel} variant="contained">
                        Отмена
                    </AppButton>
                    <AppButton
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={!isValid || !isUpdated}
                    >
                        Сохранить
                    </AppButton>
                </Grid>
            </form >
        </>
    );
};

export default ProductEntityForm;
