import AppSelect from "components/common/appSelect";
import React, { useEffect, useState } from "react";
import { useGetCategoriesQuery } from "store/services/category.service";

export enum CategoriesTreeMode {
    SEARCH = "search",
    CREATE = "create"
}

export enum CategoriesTreeType {
    SET = "set",
    PRODUCT = "product",
    YML_FEED = "yml_feed"
}

interface ICategoriesSelect {
    treeType?: CategoriesTreeType;
    treeMode?: CategoriesTreeMode;
    name: string;
    value: string;
    label: string;
    onChange: (target: { name: string; value: string }) => void;
    clearable?: boolean,
    error?: string
}

const CategoriesSelect: React.FC<ICategoriesSelect> = ({
    treeType = CategoriesTreeType.PRODUCT,
    treeMode = CategoriesTreeMode.SEARCH,
    name,
    value,
    label,
    onChange,
    clearable = false,
    error
}) => {
    const { data: categoriesTree } = useGetCategoriesQuery(treeType);
    const [mappedCategoriesTree, setMappedCategoriesTree] = useState<React.ReactNode[]>([]);

    useEffect(() => {
        if (categoriesTree) {
            const tempMappedTree: React.ReactNode[] = []
            categoriesTree.forEach(section => {
                tempMappedTree.push(
                    treeMode === CategoriesTreeMode.CREATE ?
                        (
                            <AppSelect.AppOptGroup key={section.id}>
                                {section.title}
                            </AppSelect.AppOptGroup>
                        ) : (
                            <AppSelect.AppOption
                                value={section.id.toString()}
                                key={section.id}
                            >
                                {section.title}
                            </AppSelect.AppOption>
                        )
                );
                treeType !== CategoriesTreeType.YML_FEED && section.children?.forEach(category => {
                    tempMappedTree.push(
                        treeMode === CategoriesTreeMode.CREATE ? (
                            <AppSelect.AppOptGroup
                                sx={{ pl: 5 }}
                                key={category.id}
                            >
                                {category.title}
                            </AppSelect.AppOptGroup>
                        ) : (
                            <AppSelect.AppOption
                                sx={{ pl: 5 }}
                                value={category.id.toString()}
                                key={category.id}
                            >
                                {category.title}
                            </AppSelect.AppOption>
                        ))
                    category.children?.map(subcategory => {
                        tempMappedTree.push(
                            <AppSelect.AppOption
                                sx={{ pl: 7 }}
                                value={subcategory.id.toString()}
                                key={subcategory.id}
                            >
                                {subcategory.title}
                            </AppSelect.AppOption>)
                    }
                    )
                })
            })
            setMappedCategoriesTree(tempMappedTree);
        }
    }, [categoriesTree]);

    if (!mappedCategoriesTree?.length) return null;

    return <AppSelect
        name={name}
        value={value}
        label={label}
        onChange={onChange}
        clearable={clearable}
        error={error}
    >
        {mappedCategoriesTree}
    </AppSelect>;
}

export default CategoriesSelect;