import { createApi } from "@reduxjs/toolkit/query/react";
import { getFingerprint } from "utils/fingerprint";
import { logOut, setAuthData } from "store/slices/auth.slice";
import { userApi } from "./user.service";
import { appBaseQueryWithReauth } from "./http.service";
import { LoginWithPasswordResponseType } from "types/auth/loginWithPasswordResponseType";
import { LoginWithPasswordRequestType } from "types/auth/loginWithPasswordRequestType";
import { config } from "config";

const authEndpoint = config.apiEndpoint + "/auth";

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: appBaseQueryWithReauth({
        baseUrl: authEndpoint
    }),
    endpoints: (builder) => ({
        loginWithPassword: builder.mutation<
            LoginWithPasswordResponseType,
            LoginWithPasswordRequestType
        >({
            query: (payload) => ({
                url: "/loginWithPassword",
                method: "POST",
                data: {
                    ...payload,
                    fingerprint: getFingerprint()
                },
                withCredentials: true
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(setAuthData({ accessToken: data.accessToken }));
            }
        }),
        logout: builder.mutation<number, void>({
            query: () => ({
                url: "/logout",
                method: "DELETE",
                withCredentials: true
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(logOut());
                    dispatch(userApi.util.resetApiState());
                } catch (e) {
                    console.log(e);
                }
            }
        })
    })
});

export const { useLoginWithPasswordMutation, useLogoutMutation } = authApi;
