import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import { ProductRequestType } from "types/product/productRequestType";
import { ProductResponseType } from "types/product/productResponseType";
import { appBaseQueryWithReauth } from "./http.service";
import { setApi } from "./set.service";

const productEndpoint = config.apiEndpoint + "/product";

export const productApi = createApi({
    reducerPath: "productApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: productEndpoint }),
    tagTypes: ["Product"],
    endpoints: (builder) => ({
        getProducts: builder.query<ProductResponseType[], string>({
            query: (categoryId) => ({
                url: `/list?categoryId=${categoryId}&paranoid`
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.map(({ id }) => ({
                              type: "Product" as const,
                              id
                          })),
                          "Product"
                      ]
                    : ["Product"]
        }),
        getProduct: builder.query<ProductResponseType, string>({
            query: (id) => ({
                url: `/?id=${id}&paranoid`
            }),
            providesTags: ["Product"]
        }),
        createProduct: builder.mutation<
            ProductResponseType,
            ProductRequestType
        >({
            query: (payload) => ({
                url: "/",
                method: "POST",
                data: payload
            }),
            invalidatesTags: ["Product"]
        }),
        updateProduct: builder.mutation<
            ProductResponseType,
            ProductRequestType
        >({
            query: ({ id, ...payload }) => ({
                url: `/${id}`,
                method: "PATCH",
                data: payload
            }),
            invalidatesTags: ["Product"],
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(setApi.util.invalidateTags(["Set"]));
            }
        }),
        deleteProduct: builder.mutation<number, number>({
            query: (id) => ({
                url: `/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["Product"]
        })
    })
});

export const {
    useCreateProductMutation,
    useLazyGetProductsQuery,
    useGetProductsQuery,
    useGetProductQuery,
    useLazyGetProductQuery,
    useUpdateProductMutation,
    useDeleteProductMutation
} = productApi;

export const { getProducts, getProduct } = productApi.endpoints;
