import { CategoryRequestType } from "types/category/categoryRequestType";

export const categories: CategoryRequestType[] = [
    {
        id: 1,
        slug: "спальня",
        title: "bedroom",
        parentId: null
    },
    {
        id: 2,
        slug: "постельное белье",
        title: "bed-linen",
        parentId: 1,
        isVisible: true
    },
    {
        id: 3,
        slug: "комплекты",
        title: "bed-linen-sets",
        parentId: 2
    },
    {
        id: 4,
        slug: "простыни",
        title: "bed-sheets",
        parentId: 2
    },
    {
        id: 5,
        slug: "натяжные простыни",
        title: "stretch-bed-sheets",
        parentId: 2
    },
    {
        id: 6,
        slug: "наволочки",
        title: "pillowcases",
        parentId: 2
    },
    {
        id: 7,
        slug: "пододеяльники",
        title: "duvet-covers",
        parentId: 2
    },
    {
        id: 8,
        slug: "стёганные покрывала",
        title: "quilted-bed-spreads",
        parentId: 1
    },
    {
        id: 9,
        slug: "пледы",
        title: "plaids",
        parentId: 1
    },
    {
        id: 10,
        slug: "ванная",
        title: "bathroom",
        parentId: null
    },
    {
        id: 11,
        slug: "полотенца",
        title: "bathroom-towels",
        parentId: 10
    },
    {
        id: 12,
        slug: "халаты",
        title: "bathrobes",
        parentId: 10
    },
    {
        id: 13,
        slug: "кухня / столовая",
        title: "kitchen",
        parentId: null
    },
    {
        id: 14,
        slug: "скатерти и салфетки",
        title: "tableclothes-tissues",
        parentId: 13
    },
    {
        id: 15,
        slug: "скатерти",
        title: "tableclothes",
        parentId: 14
    },
    {
        id: 16,
        slug: "салфетки",
        title: "tissues",
        parentId: 14
    },
    {
        id: 17,
        slug: "дорожки на стол",
        title: "table-rugs",
        parentId: 14
    },
    {
        id: 18,
        slug: "полотенца",
        title: "kitchen-towels",
        parentId: 14
    },
    {
        id: 19,
        slug: "одежда / аксессуары",
        title: "clothes-accessories",
        parentId: null
    },
    {
        id: 20,
        slug: "одежда",
        title: "clothes",
        parentId: 19
    },
    {
        id: 21,
        slug: "пижамы",
        title: "sleepwear",
        parentId: 20
    },
    {
        id: 22,
        slug: "одежда для дома",
        title: "homewear",
        parentId: 20,
        isVisible: true
    },
    {
        id: 23,
        slug: "шорты / штаны",
        title: "shorts-pants",
        parentId: 20
    },
    {
        id: 24,
        slug: "рубашки / майки",
        title: "shirts-tshirts",
        parentId: 20
    },
    {
        id: 25,
        slug: "сумки",
        title: "bags",
        parentId: 19
    },
    {
        id: 26,
        slug: "дети",
        title: "kids",
        parentId: null
    },
    {
        id: 27,
        slug: "постельное бельё",
        title: "kids-bed-linen",
        parentId: 26
    },
    {
        id: 28,
        slug: "комплекты",
        title: "kids-bed-linen-sets",
        parentId: 27
    },
    {
        id: 29,
        slug: "простыни",
        title: "kids-bed-sheets",
        parentId: 27
    },
    {
        id: 30,
        slug: "натяжные простыни",
        title: "kids-stretch-bed-sheets",
        parentId: 27
    },
    {
        id: 31,
        slug: "наволочки",
        title: "kids-pillowcases",
        parentId: 27
    },
    {
        id: 32,
        slug: "пододеяльники",
        title: "kids-duvet-covers",
        parentId: 27
    },
    {
        id: 33,
        slug: "стёганные покрывала",
        title: "kids-quilted-bed-spreads",
        parentId: 26
    },
    {
        id: 34,
        slug: "пледы",
        title: "kids-plaids",
        parentId: 26
    },
    {
        id: 35,
        slug: "распродажа",
        title: "sale",
        parentId: null
    }
];
