import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import { appBaseQueryWithReauth } from "./http.service";
import { SizeChartResponseType } from "types/sizeChart/sizeChartResponseType";
import { SizeChartRequestType } from "types/sizeChart/sizeChartRequestType";

const sizeChartEndpoint = config.apiEndpoint + "/sizeChart";

export const sizeChartApi = createApi({
    reducerPath: "sizeChartApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: sizeChartEndpoint }),
    tagTypes: ["SizeChart"],
    endpoints: (builder) => ({
        getSizeCharts: builder.query<SizeChartResponseType[], void>({
            query: () => ({
                url: "/",
                method: "GET"
            }),
            providesTags: ["SizeChart"]
        }),
        getSizeChart: builder.query<SizeChartResponseType, string>({
            query: (id) => ({
                url: `/${id}`,
                method: "GET"
            }),
            providesTags: ["SizeChart"]
        }),
        createSizeChart: builder.mutation<
            SizeChartResponseType,
            SizeChartRequestType
        >({
            query: (payload) => ({
                url: "/",
                method: "POST",
                data: payload
            }),
            invalidatesTags: ["SizeChart"]
        }),
        updateSizeChart: builder.mutation<
            SizeChartResponseType,
            SizeChartRequestType
        >({
            query: ({ id, ...payload }) => ({
                url: `/${id}`,
                method: "PATCH",
                data: payload
            }),
            invalidatesTags: ["SizeChart"]
        }),
        deleteSizeChart: builder.mutation<number, number>({
            query: (id) => ({
                url: `/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["SizeChart"]
        })
    })
});

export const {
    useCreateSizeChartMutation,
    useDeleteSizeChartMutation,
    useGetSizeChartsQuery,
    useGetSizeChartQuery,
    useUpdateSizeChartMutation
} = sizeChartApi;
