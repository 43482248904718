import { Typography } from '@mui/material';
import React, { ReactNode } from 'react';

interface IAppFieldLabel {
    children: ReactNode;
}

const AppFieldLabel: React.FC<IAppFieldLabel> = ({ children }) => {
    return <Typography sx={{ color: "gray", mb: 1, fontSize: 14 }}>{children}</Typography>;
}

export default AppFieldLabel;