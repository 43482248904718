export function validator(data: any, config: any) {
    const errors: {
        [key: string]: any;
    } = {};
    function validate(validateMethod: any, data: any, config: any) {
        let statusValidate;
        switch (validateMethod) {
            case "isRequired":
                if (typeof data === "number") {
                    statusValidate = !data;
                } else if (data instanceof FileList) {
                    statusValidate = Array.from(data).length === 0;
                } else if (typeof data === "string") {
                    statusValidate = data.trim() === "";
                } else {
                    statusValidate = !data;
                }
                break;
            case "isEmail": {
                const emailRegExp = /^\S+@\S+\.\S+$/g;
                statusValidate = data && !emailRegExp.test(data.trim());
                break;
            }
            case "isPhone": {
                if (config.countryCode && config.dialCode) {
                    let numLength;
                    switch (config.countryCode) {
                        case "by":
                            numLength = 9;
                            break;
                        case "ru":
                            numLength = 10;
                            break;
                        case "kz":
                            numLength = 10;
                            break;
                        default:
                            break;
                    }
                    statusValidate =
                        data.slice(config.dialCode.length).length !== numLength;
                } else {
                    statusValidate = !data;
                }
                break;
            }
            case "isTnved": {
                statusValidate =
                    isNaN(data) && data.length < 10 && data.length > 14;
                break;
            }
            default:
                break;
        }
        if (statusValidate) return config.message;
    }
    for (const fieldName in data) {
        for (const validateMethod in config[fieldName]) {
            const error = validate(
                validateMethod,
                data[fieldName],
                config[fieldName][validateMethod]
            );
            if (error && !errors[fieldName]) {
                errors[fieldName] = error;
            }
        }
    }
    return errors;
}
