import React, { ReactNode } from 'react';
import { Typography } from '@mui/material';

interface IAppH4 {
    children: ReactNode;
}

const AppH4: React.FC<IAppH4> = ({ children }) => {
    return <Typography variant='h4' mb={3} style={{ textTransform: "capitalize" }}>{children}</Typography>;
}

export default AppH4;