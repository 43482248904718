import React, { useState } from "react";
import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import AddIcon from "@mui/icons-material/Add";
import AppModal from "components/common/appModal";
import { FormType } from "enums/formType";
import { useConfirm } from "material-ui-confirm";
import { toast } from "react-toastify";
import { useDeleteSizeChartMutation, useGetSizeChartsQuery } from "store/services/sizeCharts.service";
import { SizeChartResponseType } from "types/sizeChart/sizeChartResponseType";
import SizeChartsDataGrid from "components/ui/sizesChartGrid";
import SizeChartForm from "components/ui/forms/sizeChartForm";
import AppH4 from "components/common/appH4";

const SizeChartsPage: React.FC = () => {
    const { data: sizeCharts } = useGetSizeChartsQuery();
    const [currentSizeChart, setCurrentSizeChart] = useState<SizeChartResponseType>();
    const confirm = useConfirm();
    const [deleteSizeChart] = useDeleteSizeChartMutation();

    const [form, setForm] = useState<string>("");
    const [open, setOpen] = useState(false);

    const handleChooseForm = (form: string) => {
        setForm(form);
        setOpen(true);
    };

    const handleSizeChartEdit = (sizeChartId: number) => {
        const sizeChart = sizeCharts?.find((sizeChart) => sizeChart.id === sizeChartId);
        if (sizeChart) {
            setCurrentSizeChart(sizeChart);
            handleChooseForm(FormType.SIZE_CHART_EDIT);
        }
    };

    const handleSizeChartDelete = (sizeChartId: number) => {
        confirm({
            title: "Удаление таблицы размеров",
            description: "Вы действительно хотите удалить эту таблицу размеров?",
            confirmationText: "Удалить",
            cancellationText: "Отмена"
        })
            .then(async () => {
                await deleteSizeChart(sizeChartId).unwrap();
                setCurrentSizeChart(undefined);
                toast.success("Таблица размеров");
            })
            .catch((e) => {
                if (e) {
                    toast.error("Не удалось удалить таблицу размеров");
                }
            });
    };

    const handleFinish = () => {
        setOpen(false);
    };

    return (
        <>
            <AppH4>Таблицы размеров</AppH4>
            <Grid>
                <Grid container justifyContent="end" mb={1}>
                    <AppButton
                        icon={<AddIcon />}
                        tooltip="Создать таблицу размеров"
                        onClick={() => handleChooseForm(FormType.SIZE_CHART_NEW)}
                    />
                </Grid>
                {sizeCharts && (
                    <SizeChartsDataGrid
                        sizeCharts={sizeCharts}
                        onEdit={handleSizeChartEdit}
                        onDelete={handleSizeChartDelete}
                    />
                )}
                <AppModal open={open}>
                    {form === FormType.SIZE_CHART_NEW && (
                        <SizeChartForm onFinish={handleFinish} />
                    )}
                    {form === FormType.SIZE_CHART_EDIT && (
                        <SizeChartForm
                            onFinish={handleFinish}
                            defaultValues={currentSizeChart}
                        />
                    )}
                </AppModal>
            </Grid>
        </>
    );
};

export default SizeChartsPage;
