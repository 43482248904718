import React from "react";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { CollectionResponseType } from "types/collection/collectionResponseType";

interface ICollectionsDataGrid {
    collections: CollectionResponseType[];
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
}

const CollectionsDataGrid: React.FC<ICollectionsDataGrid> = ({
    collections,
    onEdit,
    onDelete
}) => {
    const rows: GridRowsProp = collections.map((c) => ({
        id: c.id,
        col1: c.title,
        col2: c.value,
        col3: c.slug
    }));

    const columns: GridColDef[] = [
        {
            field: "col1",
            headerName: "Название (рус.)",
            flex: 1
        },
        {
            field: "col2",
            headerName: "Название (англ.)",
            flex: 1
        },
        {
            field: "col3",
            headerName: "Slug",
            flex: 1
        },
        {
            field: "col4",
            headerName: "",
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Grid justifyContent="end" container>
                        <AppButton
                            icon={<EditIcon />}
                            tooltip="Редактировать"
                            onClick={() => onEdit(params.row.id)}
                        />
                        <AppButton
                            icon={<DeleteIcon />}
                            tooltip="Удалить"
                            onClick={() => onDelete(params.row.id)}
                        />
                    </Grid>
                );
            }
        }
    ];

    return (
        <DataGrid
            disableRowSelectionOnClick
            autoHeight
            sx={{
                height: "500px",
                "& .MuiDataGrid-cell:focus": {
                    outline: "none"
                },
                "& .MuiDataGrid-cell:focus-within": {
                    outline: "none"
                }
            }}
            rows={rows}
            columns={columns}
        />
    );
};

export default CollectionsDataGrid;
