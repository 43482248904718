import React from "react";
import { config } from "config";

interface IGallery {
    src: string;
}

const GalleryThumb: React.FC<IGallery> = ({ src }) => {
    return (
        <img
            style={{
                display: "block",
                width: "100%",
                height: "100%",
                objectFit: "contain"
            }}
            src={config.endpoint + src}
            alt="gallery thumb"
        />
    );
};

export default GalleryThumb;
