import React from 'react';
import AppSelect from "components/common/appSelect";
import { useGetDeliveriesQuery } from 'store/services/delivery.service';

interface IDeliveriesSelect {
    name: string;
    label: string;
    value: string;
    onChange: (target: { name: string; value: string }) => void;
    error?: string;
    clearable?: boolean
}

const DeliveriesSelect: React.FC<IDeliveriesSelect> = ({
    name,
    label,
    value,
    onChange,
    error,
    clearable = false
}) => {
    const { data: deliveries } = useGetDeliveriesQuery();

    if (!deliveries) return null;

    return <AppSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        error={error}
        clearable={clearable}
    >
        {deliveries.map((delivery) => (
            <AppSelect.AppOption
                value={delivery.id.toString()}
                key={delivery.id}
            >
                {delivery.name}
            </AppSelect.AppOption>
        ))}
    </AppSelect>;
}

export default DeliveriesSelect;