import React from 'react';
import AppMultiSelect from 'components/common/appMultiSelect';
import { useGetUsersQuery } from 'store/services/user.service';

interface IPromocodeUsersMultiSelect {
    name: string;
    label: string;
    value: string[];
    onChange: (target: { name: string; value: string | string[] }) => void;
    error?: string;
    clearable?: boolean
}

const PromocodeUsersMultiSelect: React.FC<IPromocodeUsersMultiSelect> = ({
    name,
    label,
    value,
    onChange,
    error,
    clearable = false
}) => {
    const { users } = useGetUsersQuery(undefined, {
        selectFromResult: ({ data }) => ({
            users: data?.map((user) => ({
                value: user.id.toString(),
                title: user.email
            }))
        })
    });

    if (!users) return null;

    return <AppMultiSelect
        label={label}
        name={name}
        value={value}
        options={users}
        onChange={onChange}
        clearable={clearable}
        error={error}
    />

}

export default PromocodeUsersMultiSelect;