import { Grid, Typography } from "@mui/material";
import AppButton from "components/common/appButton";
import AppTextField from "components/common/appTextField";
import FormHeader from "components/common/formHeader";
import React, { useEffect, useState } from "react";
import { validator } from "utils/validator";
import { toast } from "react-toastify";
import { OrderResponseType } from "types/order/orderResponseType";
import { useBulkUpdateOrderItemsMutation } from "store/services/orderItem.service";
import { useCreateReceiptMutation } from "store/services/payment.service";
import { useCreateCdekOrderMutation } from "store/services/cdek.service";

interface IData {
    [key: string]: string;
}

interface IOrderCheckOutForm {
    onFinish: () => void;
    order: OrderResponseType;
    title?: string;
}

const OrderCheckOutForm: React.FC<IOrderCheckOutForm> = ({
    order,
    onFinish,
    title
}) => {
    const [data, setData] = useState<IData>(
        order.orderItems.reduce((acc, curr) => {
            if (curr.productEntity.colorEntity.product.marking) {
                return {
                    ...acc,
                    [curr.id.toString()]: curr.dataMatrix || ""
                };
            } else {
                return acc;
            }
        }, {})
    );

    const [errors, setErrors] = useState<Partial<IData>>({});
    const isValid = Object.keys(errors).length === 0;
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const [bulkUpdateOrderItems] = useBulkUpdateOrderItemsMutation();
    const [createReceipt] = useCreateReceiptMutation();
    const [createCdekOrder] = useCreateCdekOrderMutation();

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [data]);

    const validatorConfig = Object.keys(data).reduce((acc, curr) => {
        return {
            ...acc,
            [curr.toString()]: {
                isRequired: {
                    message: "Введите или отсканируйте код маркировки"
                }
            }
        };
    }, {});

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (target: { name: string; value: string }) => {
        setData((prevState) => ({
            ...prevState,
            [target.name]: target.value.replace(" ", "\u001d")
        }));
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsTrySubmit(true);
        const isValid = validate();
        if (!isValid) return;
        try {
            if (Object.keys(data).length) {
                const updatedOrderItems = order.orderItems.map((oi) => ({
                    id: oi.id,
                    orderId: oi.orderId,
                    productEntityId: oi.productEntityId,
                    dataMatrix: data[oi.id.toString()]
                }));
                await bulkUpdateOrderItems(updatedOrderItems).unwrap();
            }
            await createReceipt(order.id).unwrap();
            await createCdekOrder(order.id).unwrap();

            toast.success(
                "Чек к заказy №" +
                    order.orderNumber +
                    " сформирован. Заказ на доставку создан"
            );

            onFinish();
        } catch (e: any) {
            toast.error(e.message);
        }
    };

    const [active, setActive] = useState(document.activeElement);

    useEffect(() => {
        const handleFocusIn = () => {
            setActive(document.activeElement);
        };

        document.addEventListener("focusin", handleFocusIn);
        return () => {
            document.removeEventListener("focusin", handleFocusIn);
        };
    }, []);

    const getFullProductEntityName = (orderItemId: string) => {
        let fullName = "";
        const orderItem = order.orderItems.find(
            (oi) => oi.id.toString() === orderItemId
        );
        if (orderItem) {
            const { productEntity } = orderItem;
            const size = productEntity.size.value;
            const color = productEntity.colorEntity.color.title;
            const name = productEntity.colorEntity.product.title;
            const collection =
                productEntity.colorEntity.product.collection.title;
            fullName = name + " " + collection + " " + size + " " + color;
        }
        return fullName;
    };

    return (
        <>
            {title && <FormHeader title={title} />}
            <form onSubmit={handleSubmit}>
                {Object.keys(data).map((orderItemId) => (
                    <Grid key={orderItemId} sx={{ mb: 4 }}>
                        <Typography
                            sx={{
                                fontSize: "18px",
                                textTransform: "uppercase",
                                mb: 1
                            }}
                        >
                            {getFullProductEntityName(orderItemId)}
                        </Typography>
                        <AppTextField
                            name={orderItemId}
                            onChange={handleChange}
                            value={data[orderItemId]}
                            placeholder="Введите или отсканируйте код маркировки"
                            error={errors[orderItemId]}
                        />
                    </Grid>
                ))}
                <Grid container justifyContent="space-between" mt={1}>
                    <AppButton onClick={onFinish} variant="contained">
                        Отмена
                    </AppButton>
                    <AppButton
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={!isValid}
                    >
                        Выписать чек
                    </AppButton>
                </Grid>
            </form>
        </>
    );
};

export default OrderCheckOutForm;
