import { Box, Grid, Typography } from "@mui/material";
import AppButton from "components/common/appButton";
import AppTextField from "components/common/appTextField";
import FormHeader from "components/common/formHeader";
import React, { useEffect, useState } from "react";
import { config } from "config";
import { validator } from "utils/validator";
import { toast } from "react-toastify";
import AppFileField from "components/common/appFileField";
import { CategoryResponseType } from "types/category/categoryResponseType";
import {
    useCreateCategoryMutation,
    useGetCategoriesQuery,
    useUpdateCategoryMutation
} from "store/services/category.service";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { ImageActions } from "enums/imageActions";
import AppTextArea from "components/common/appTextArea";

interface IData {
    title: string;
    slug: string;
    bannerDesktop: string | FileList | null | ImageActions.IMAGE_DELETED;
    bannerMobile: string | FileList | null | ImageActions.IMAGE_DELETED;
    bannerDescription: string;
    description: string;
    tnved: string;
}

interface ICategoryForm {
    parentId?: number;
    onFinish: () => void;
    defaultValues?: CategoryResponseType;
    title?: string;
}

const CategoryForm: React.FC<ICategoryForm> = ({
    defaultValues,
    onFinish,
    title,
    parentId
}) => {
    const { data: categories } = useGetCategoriesQuery();
    const isEdit = !!defaultValues;
    const [data, setData] = useState<IData>(
        isEdit
            ? {
                  title: defaultValues.title,
                  slug: defaultValues.slug,
                  bannerDesktop: defaultValues.bannerDesktop,
                  bannerMobile: defaultValues.bannerMobile,
                  bannerDescription: defaultValues.bannerDescription || "",
                  description: defaultValues.description || "",
                  tnved: defaultValues.tnved?.toString() || ""
              }
            : {
                  title: "",
                  slug: "",
                  bannerDesktop: "",
                  bannerMobile: "",
                  bannerDescription: "",
                  description: "",
                  tnved: ""
              }
    );

    const [errors, setErrors] = useState<{
        title?: string;
        bannerDesktop?: string;
        bannerMobile?: string;
        slug?: string;
        description?: string;
        tnved?: string;
    }>({});
    const isValid = Object.keys(errors).length === 0;
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [createCategory] = useCreateCategoryMutation();
    const [updateCategory] = useUpdateCategoryMutation();
    const isOldBannerDesktop =
        data.bannerDesktop &&
        typeof data.bannerDesktop === "string" &&
        data.bannerDesktop !== ImageActions.IMAGE_DELETED;
    const isOldBannerMobile =
        data.bannerMobile &&
        typeof data.bannerMobile === "string" &&
        data.bannerMobile !== ImageActions.IMAGE_DELETED;

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [data]);

    const validatorConfig = {
        slug: {
            isRequired: {
                message: "Укажите название цвета"
            }
        },
        title: {
            isRequired: {
                message: "Укажите название цвета (англ.)"
            }
        },
        tnved: {
            isTnved: {
                message: "ТНВЭД должен состоять из 10 цифр"
            }
        }
    };

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (target: {
        name: string;
        value: string | FileList;
    }) => {
        setIsUpdated(true);
        setData((prevState) => ({
            ...prevState,
            [target.name]: target.value
        }));
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsTrySubmit(true);
        const isValid = validate();
        if (!isValid) return;
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            const typedKey = key as keyof IData;
            if (data[typedKey] instanceof FileList) {
                formData.append(key, data[typedKey]?.[0] as Blob);
            } else {
                formData.append(key, (data[typedKey] || "") as string);
            }
        });
        if (isEdit) {
            try {
                await updateCategory({
                    id: defaultValues.id,
                    payload: formData
                }).unwrap();
                toast.success("Категория успешно обновлена");
            } catch (e) {
                return toast.error("Не удалось обновить категорию");
            }
        } else {
            try {
                if (categories?.length) {
                    formData.append("id", (categories.length + 1).toString());
                    parentId &&
                        formData.append("parentId", parentId.toString());
                    await createCategory(formData).unwrap();
                    toast.success("Категория добавлена");
                }
            } catch (e) {
                return toast.error("Не удалось добавить категорию");
            }
        }
        onFinish();
    };

    return (
        <>
            {title && <FormHeader title={title} />}
            <form onSubmit={handleSubmit}>
                <Grid container flexDirection="column" alignItems="center">
                    <AppTextField
                        name="slug"
                        onChange={handleChange}
                        value={data.slug}
                        placeholder="Название (рус.)"
                        error={errors.slug}
                    />
                    <AppTextField
                        name="title"
                        onChange={handleChange}
                        value={data.title}
                        placeholder="Название (англ)"
                        error={errors.title}
                    />
                    <AppTextField
                        name="tnved"
                        onChange={handleChange}
                        value={data.tnved}
                        type="number"
                        placeholder="ТНВЭД (10-14 цифр)"
                        error={errors.tnved}
                    />
                    <AppTextArea
                        name="description"
                        onChange={handleChange}
                        value={data.description}
                        placeholder="Описание"
                        error={errors.description}
                    />
                    <Box alignSelf="start" mb={3}>
                        <Typography sx={{ textTransform: "uppercase" }}>
                            Баннер для десктопа
                        </Typography>
                        <AppFileField
                            name="bannerDesktop"
                            accept="image/*"
                            multiple={false}
                            error={errors.bannerDesktop}
                            onChange={handleChange}
                        />
                        {isOldBannerDesktop && (
                            <Box sx={{ position: "relative" }}>
                                <img
                                    width={50}
                                    src={config.endpoint + data.bannerDesktop}
                                    alt="thumb"
                                />
                                <HighlightOffIcon
                                    onClick={() =>
                                        handleChange({
                                            name: "bannerDesktop",
                                            value: ImageActions.IMAGE_DELETED
                                        })
                                    }
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                    <Box alignSelf="start">
                        <Typography sx={{ textTransform: "uppercase" }}>
                            Баннер для мобильных устройств
                        </Typography>
                        <AppFileField
                            name="bannerMobile"
                            accept="image/*"
                            multiple={false}
                            error={errors.bannerMobile}
                            onChange={handleChange}
                        />
                        {isOldBannerMobile && (
                            <Box sx={{ position: "relative" }}>
                                <img
                                    width={50}
                                    src={config.endpoint + data.bannerMobile}
                                    alt="thumb"
                                />
                                <HighlightOffIcon
                                    onClick={() =>
                                        handleChange({
                                            name: "bannerMobile",
                                            value: ImageActions.IMAGE_DELETED
                                        })
                                    }
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                    <AppTextArea
                        name="bannerDescription"
                        onChange={handleChange}
                        value={data.bannerDescription}
                        placeholder="Описание баннера"
                        error={errors.description}
                    />
                </Grid>
                <Grid container justifyContent="space-between" mt={1}>
                    <AppButton onClick={onFinish} variant="contained">
                        Отмена
                    </AppButton>
                    <AppButton
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={!isValid || !isUpdated}
                    >
                        Сохранить
                    </AppButton>
                </Grid>
            </form>
        </>
    );
};

export default CategoryForm;
