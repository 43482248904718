import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import FormHeader from "components/common/formHeader";
import React, { useEffect, useState } from "react";
import { validator } from "utils/validator";
import { toast } from "react-toastify";
import { SizeChartValueResponseType } from "types/sizeChartValue/sizeChartValueResponseType";
import { SizeChartValueRequestType } from "types/sizeChartValue/sizeChartValueRequestType";
import { useCreateSizeChartValueMutation, useUpdateSizeChartValueMutation } from "store/services/sizeChartValues.service";
import AppTextField from "components/common/appTextField";

interface ISizeChartValueForm {
    onFinish: () => void;
    defaultValues: SizeChartValueResponseType | { sizeChartId: number };
    title?: string;
}

const SizeChartValueForm: React.FC<ISizeChartValueForm> = ({ defaultValues, onFinish, title }) => {
    const isEdit = "id" in defaultValues;
    const [data, setData] = useState<SizeChartValueRequestType>(
        isEdit
            ? {
                RUS: defaultValues.RUS,
                INT: defaultValues.INT,
                chest: defaultValues.chest,
                waist: defaultValues.waist,
                hips: defaultValues.hips,
                height: defaultValues.height,
                sizeChartId: defaultValues.sizeChartId
            }
            : {
                RUS: "",
                INT: "",
                chest: "",
                waist: "",
                hips: "",
                height: "",
                sizeChartId: defaultValues.sizeChartId
            }
    );

    const [errors, setErrors] = useState<Partial<SizeChartValueRequestType>>({});
    const isValid = Object.keys(errors).length === 0;
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [updateSizeChartValue] = useUpdateSizeChartValueMutation();
    const [createSizeChartValue] = useCreateSizeChartValueMutation();

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [data]);

    const validatorConfig = {
        RUS: {
            isRequired: {
                message: "Укажите российский размер"
            }
        },
        INT: {
            isRequired: {
                message: "Укажите международный размер"
            }
        },
        chest: {
            isRequired: {
                message: "Укажите обхват груди"
            }
        },
        waist: {
            isRequired: {
                message: "Укажите обхват талии"
            }
        },
        hips: {
            isRequired: {
                message: "Укажите обхват бёдер"
            }
        },
    };

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (target: { name: string; value: string }) => {
        setIsUpdated(true);
        setData((prevState) => ({
            ...prevState,
            [target.name]: target.value
        }));
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsTrySubmit(true);
        const isValid = validate();
        if (!isValid) return;
        if (isEdit) {
            try {
                await updateSizeChartValue({
                    id: defaultValues.id,
                    ...data
                }).unwrap();
                toast.success("Значение успешно обновлено");
            } catch (e) {
                return toast.error("Не удалось обновить значение");
            }
        } else {
            try {
                await createSizeChartValue(data).unwrap();
                toast.success("Значение создано");
            } catch (e) {
                console.log(e)
                return toast.error("Не удалось создать значение");
            }
        }
        onFinish();
    };

    return (
        <>
            {title && <FormHeader title={title} />}
            <form onSubmit={handleSubmit}>
                <Grid container wrap="nowrap" alignItems="center">
                    <AppTextField
                        name="RUS"
                        label="RUS"
                        onChange={handleChange}
                        value={data.RUS}
                        placeholder="Российский размер"
                        error={errors.RUS}
                    />
                    <AppTextField
                        name="INT"
                        label="INT"
                        onChange={handleChange}
                        value={data.INT}
                        placeholder="Международный размер"
                        error={errors.INT}
                    />
                    <AppTextField
                        name="chest"
                        label="Обхват груди"
                        onChange={handleChange}
                        value={data.chest}
                        placeholder="Обхват груди"
                        error={errors.chest}
                    />
                    <AppTextField
                        name="waist"
                        label="Обхват талии"
                        onChange={handleChange}
                        value={data.waist}
                        placeholder="Обхват талии"
                        error={errors.waist}
                    />
                    <AppTextField
                        name="hips"
                        label="Обхват бёдер"
                        onChange={handleChange}
                        value={data.hips}
                        placeholder="Обхват бёдер"
                        error={errors.hips}
                    />
                    <AppTextField
                        name="height"
                        label="Рост"
                        onChange={handleChange}
                        value={data.height}
                        placeholder="Рост"
                        error={errors.height}
                    />
                </Grid>
                <Grid container justifyContent="space-between" mt={1}>
                    <AppButton onClick={onFinish} variant="contained">
                        Отмена
                    </AppButton>
                    <AppButton
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={!isValid || !isUpdated}
                    >
                        Сохранить
                    </AppButton>
                </Grid>
            </form>
        </>
    );
};

export default SizeChartValueForm;
