import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import { appBaseQueryWithReauth } from "./http.service";
import { SizeChartValueResponseType } from "types/sizeChartValue/sizeChartValueResponseType";
import { SizeChartValueRequestType } from "types/sizeChartValue/sizeChartValueRequestType";
import { sizeChartApi } from "./sizeCharts.service";

const sizeChartValueEndpoint = config.apiEndpoint + "/sizeChartValue";

export const sizeChartValueApi = createApi({
    reducerPath: "sizeChartValueApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: sizeChartValueEndpoint }),
    endpoints: (builder) => ({
        createSizeChartValue: builder.mutation<
            SizeChartValueResponseType,
            SizeChartValueRequestType
        >({
            query: (payload) => ({
                url: "/",
                method: "POST",
                data: payload
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(sizeChartApi.util.invalidateTags(["SizeChart"]));
            }
        }),
        updateSizeChartValue: builder.mutation<
            SizeChartValueResponseType,
            SizeChartValueRequestType
        >({
            query: ({ id, ...payload }) => ({
                url: `/${id}`,
                method: "PATCH",
                data: payload
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(sizeChartApi.util.invalidateTags(["SizeChart"]));
            }
        }),
        deleteSizeChartValue: builder.mutation<number, number>({
            query: (id) => ({
                url: `/${id}`,
                method: "DELETE"
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(sizeChartApi.util.invalidateTags(["SizeChart"]));
            }
        })
    })
});

export const {
    useCreateSizeChartValueMutation,
    useDeleteSizeChartValueMutation,
    useUpdateSizeChartValueMutation
} = sizeChartValueApi;
