import { Box, Grid, Typography } from "@mui/material";
import AppButton from "components/common/appButton";
import AppTextField from "components/common/appTextField";
import FormHeader from "components/common/formHeader";
import React, { useEffect, useState } from "react";
import { validator } from "utils/validator";
import { toast } from "react-toastify";
import AppPhoneInput, { AppPhoneInputType } from "components/common/appPhoneInput";
import WarningIcon from '@mui/icons-material/Warning';
import { useUpdateOrderMutation } from "store/services/order.service";
import DeliveriesSelect from "./selects/deliveriesSelect";
import StatusesSelect from "./selects/statusesSelect";

interface IData {
    id: number;
    statusId?: number;
    deliveryId: number;
    deliveryNumber?: string;
    name: string;
    surname: string;
    email: string;
    phone: string;
    address: string;
}

interface IOrderForm {
    onFinish: () => void;
    defaultValues: IData;
    title?: string;
}

const OrderForm: React.FC<IOrderForm> = ({ defaultValues, onFinish, title }) => {
    const [data, setData] = useState<IData>({ ...defaultValues }
    );
    const [phoneData, setPhoneData] = useState<{
        dialCode: string | any[];
        countryCode: string;
    }>({ dialCode: "", countryCode: "" });
    const [errors, setErrors] = useState<Partial<IData>>({});
    const isValid = Object.keys(errors).length === 0;
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [updateOrder] = useUpdateOrderMutation();

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [data]);

    const validatorConfig = {
        name: {
            isRequired: {
                message: "Укажите имя получателя"
            }
        },
        surname: {
            isRequired: {
                message: "Укажите фамилию получателя"
            }
        },
        email: {
            isRequired: {
                message: "Укажите фамилию получателя"
            },
            isEmail: {
                message: "Неверный формат e-mail"
            }
        },
        phone: {
            isRequired: {
                message: "Укажите номер телефона получателя"
            },
            isPhone: {
                message: "Неверный формат номера телефона",
                dialCode: phoneData.dialCode,
                countryCode: phoneData.countryCode,
            }
        },
        delivery: {
            isRequired: {
                message: "Укажите фамилию получателя"
            },
        },
    };

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (target: { name: string; value: string } | AppPhoneInputType) => {
        setIsUpdated(true);
        if ("dialCode" in target) {
            const { dialCode, countryCode } = target;
            setPhoneData({ dialCode, countryCode });
        }

        setData((prevState) => ({
            ...prevState,
            [target.name]: target.value,
        }));
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsTrySubmit(true);
        const isValid = validate();
        if (!isValid) return;

        try {
            await updateOrder(data).unwrap();
            toast.success("Заказ успешно обновлён");
        } catch (e) {
            return toast.error("Не удалось обновить цвет");
        }

        onFinish();
    };

    return (
        <>
            {title && <FormHeader title={title} />}
            <form onSubmit={handleSubmit}>
                <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    alignItems="center">
                    <AppTextField
                        label="Имя"
                        name="name"
                        onChange={handleChange}
                        value={data.name}
                        placeholder="Имя"
                        error={errors.name}
                    />
                    <AppTextField
                        label="Фамилия"
                        name="surname"
                        onChange={handleChange}
                        value={data.surname}
                        placeholder="Фамилия"
                        error={errors.surname}
                    />
                    <AppTextField
                        label="E-mail"
                        name="email"
                        onChange={handleChange}
                        value={data.email}
                        placeholder="e-mail"
                        error={errors.email}
                    />
                    <AppPhoneInput
                        label="Номер телефона"
                        name="phone"
                        value={data.phone}
                        error={errors.phone}
                        onChange={handleChange}
                    />
                    <DeliveriesSelect
                        clearable={false}
                        name="deliveryId"
                        label="Способ доставки"
                        value={data.deliveryId.toString()}
                        error={errors.deliveryId?.toString()}
                        onChange={handleChange}
                    />
                    <AppTextField
                        label="Номер заказа СДЭК"
                        name="deliveryNumber"
                        onChange={handleChange}
                        value={data.deliveryNumber || ""}
                        placeholder="Фамилия"
                        error={errors.deliveryNumber}
                    />
                    <AppTextField
                        label="Адрес доставки"
                        name="address"
                        onChange={handleChange}
                        value={data.address}
                        placeholder="Фамилия"
                        error={errors.address}
                    />
                    <StatusesSelect
                        clearable={false}
                        name="statusId"
                        label="Статус заказа"
                        value={data.statusId?.toString() || ""}
                        error={errors.statusId?.toString()}
                        onChange={handleChange}
                    />
                </Grid>
                <Box sx={{
                    border: "1px solid red",
                    borderRadius: 2,
                    p: 5,
                }}>
                    <WarningIcon sx={{ color: "red", mr: 1 }} />
                    <Typography variant="caption">Внимание! При изменении способа доставки необходимо подкорректировать адрес доставки:<br /> - при доставке курьером указывается полный адрес доставки; <br /> - при доставке в ПВЗ указывается код пункта ПВЗ.<br /> При ручном изменении статуса заказа на &quot;Заказ на доставку создан&quot; требуется указать:<br /> - номер заказа СДЭК;<br /> - адрес доставки (ПВЗ или полный в зависимости от способа доставки)</Typography>
                </Box>
                <Grid container justifyContent="space-between" mt={1}>
                    <AppButton onClick={onFinish} variant="contained">
                        Отмена
                    </AppButton>
                    <AppButton
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={!isValid || !isUpdated}
                    >
                        Сохранить
                    </AppButton>
                </Grid>
            </form>
        </>
    );
};

export default OrderForm;
