import React from "react";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { TagResponseType } from "types/tag/tagResponseType";

interface ITagsDataGrid {
    tags: TagResponseType[];
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
}

const TagssDataGrid: React.FC<ITagsDataGrid> = ({ tags, onEdit, onDelete }) => {
    const rows: GridRowsProp = tags.map((t) => ({
        id: t.id,
        col1: t.title,
        col2: t.value
    }));

    const columns: GridColDef[] = [
        { field: "col1", headerName: "Название", flex: 1, minWidth: 200 },
        {
            field: "col2",
            headerName: "Значение",
            minWidth: 250
        },
        {
            field: "col5",
            headerName: "",
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Grid justifyContent="end" container>
                        <AppButton
                            icon={<EditIcon />}
                            tooltip="Редактировать"
                            onClick={() => onEdit(params.row.id)}
                        />
                        <AppButton
                            icon={<DeleteIcon />}
                            tooltip="Удалить"
                            onClick={() => onDelete(params.row.id)}
                        />
                    </Grid>
                );
            }
        }
    ];

    return (
        <DataGrid
            disableRowSelectionOnClick
            autoHeight
            sx={{
                height: "500px",
                "& .MuiDataGrid-cell:focus": {
                    outline: "none"
                },
                "& .MuiDataGrid-cell:focus-within": {
                    outline: "none"
                }
            }}
            rows={rows}
            columns={columns}
        />
    );
};

export default TagssDataGrid;
