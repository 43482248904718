import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AppButton from "components/common/appButton";
import AppModal from "components/common/appModal";
import { FormType } from "enums/formType";
import { useLazyGetSetsQuery } from "store/services/set.service";
import SetsDataGrid from "components/ui/setsDataGrid";
import SetForm from "components/ui/forms/setForm";
import CategoriesSelect, { CategoriesTreeType } from "components/ui/forms/selects/categoriesSelect";
import AppH4 from "components/common/appH4";

const SetsPage: React.FC = () => {
    const [getSets, { data: sets }] = useLazyGetSetsQuery();
    const [search, setSearch] = useSearchParams();
    const categoryId = search.get("categoryId") || "";
    const [form, setForm] = useState<string>("");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (categoryId) {
            getSets(categoryId);
        } else {
            setSearch({ categoryId: "3" });
        }
    }, [search]);

    const handleChooseForm = (form: string) => {
        setForm(form);
        setOpen(true);
    };

    const handleChange = (target: { name: string; value: string }) => {
        setSearch({ categoryId: target.value });
    };

    const handleFinish = () => {
        setOpen(false);
    };

    return (
        <>
            <AppH4>Комплекты</AppH4>
            <Grid>
                <Grid container justifyContent="end" mb={1}>
                    <AppButton
                        icon={<AddIcon />}
                        tooltip="Создать продукт"
                        onClick={() => handleChooseForm(FormType.SET_NEW)}
                    />
                </Grid>
                <form>
                    <CategoriesSelect
                        name="categoryId"
                        value={categoryId}
                        label="Категория"
                        onChange={handleChange}
                        clearable={false}
                        treeType={CategoriesTreeType.SET}
                    />
                </form>
                {sets && categoryId && <SetsDataGrid sets={sets} />}
                <AppModal open={open}>
                    {form === FormType.SET_NEW && (
                        <SetForm onFinish={handleFinish} />
                    )}
                </AppModal>
            </Grid>
        </>
    );
};

export default SetsPage;
