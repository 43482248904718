import React, { useState } from "react";
import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import AddIcon from "@mui/icons-material/Add";
import AppModal from "components/common/appModal";
import { FormType } from "enums/formType";
import { useConfirm } from "material-ui-confirm";
import { toast } from "react-toastify";
import {
    useDeleteTagMutation,
    useGetTagsQuery
} from "store/services/tag.service";
import { TagResponseType } from "types/tag/tagResponseType";
import TagsDataGrid from "components/ui/tagsDataGrid";
import TagForm from "components/ui/forms/tagForm";
import AppH4 from "components/common/appH4";

const TagsPage: React.FC = () => {
    const { data: tags } = useGetTagsQuery();
    const [currentTag, setCurrentTag] = useState<TagResponseType>();
    const confirm = useConfirm();
    const [deleteTag] = useDeleteTagMutation();

    const [form, setForm] = useState<string>("");
    const [open, setOpen] = useState(false);

    const handleChooseForm = (form: string) => {
        setForm(form);
        setOpen(true);
    };

    const handleTagEdit = (tagId: number) => {
        const tag = tags?.find((tag) => tag.id === tagId);
        if (tag) {
            setCurrentTag(tag);
            handleChooseForm(FormType.TAG_EDIT);
        }
    };

    const handleTagDelete = (tagId: number) => {
        confirm({
            title: "Удаление тэга",
            description: "Вы действительно хотите удалить этот тэг?",
            confirmationText: "Удалить",
            cancellationText: "Отмена"
        })
            .then(async () => {
                await deleteTag(tagId).unwrap();
                setCurrentTag(undefined);
                toast.success("Тэг удалён");
            })
            .catch((e) => {
                if (e) {
                    toast.error("Не удалось удалить тэг");
                }
            });
    };

    const handleFinish = () => {
        setOpen(false);
    };

    return (
        <>
            <AppH4>Тэги</AppH4>
            <Grid>
                <Grid container justifyContent="end" mb={1}>
                    <AppButton
                        icon={<AddIcon />}
                        tooltip="Создать тэг"
                        onClick={() => handleChooseForm(FormType.TAG_NEW)}
                    />
                </Grid>
                {tags && <TagsDataGrid
                    tags={tags}
                    onEdit={handleTagEdit}
                    onDelete={handleTagDelete}
                />}

                <AppModal open={open}>
                    {form === FormType.TAG_NEW && (
                        <TagForm onFinish={handleFinish} />
                    )}
                    {form === FormType.TAG_EDIT && (
                        <TagForm
                            onFinish={handleFinish}
                            defaultValues={currentTag}
                        />
                    )}
                </AppModal>
            </Grid>
        </>
    );
};

export default TagsPage;
