import React from "react";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";

interface IAppModal {
    children: React.ReactNode;
    open: boolean;
}

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    minWidth: 400,
    maxHeight: "80vh",
    boxShadow: 24,
    overflow: "scroll",
    p: 4
};

const AppModal: React.FC<IAppModal> = ({ open, children }) => {
    return (
        <Modal open={open}>
            <Box sx={style}>{children}</Box>
        </Modal>
    );
};

export default AppModal;
