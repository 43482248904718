import React from "react";
import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import AppIconButton from "components/common/appButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirm } from "material-ui-confirm";
import { FormType } from "enums/formType";
import { SetResponseType } from "types/set/setResponseType";
import { config } from "config";

interface ISetCard {
    set: SetResponseType;
    onFormChoose: (form: string) => void;
    onDelete: () => void;
}

const SetCard: React.FC<ISetCard> = ({
    set,
    onFormChoose,
    onDelete
}) => {
    const confirm = useConfirm();

    return (
        <Card sx={{ mb: 1 }}>
            <CardHeader
                sx={{ float: "right" }}
                action={
                    <Grid container justifyContent="end" mb={1}>
                        <AppIconButton
                            icon={<EditIcon />}
                            tooltip="Редактировать общее описание"
                            onClick={() => onFormChoose(FormType.SET_EDIT)}
                        />
                        <AppIconButton
                            icon={<DeleteIcon />}
                            tooltip="Удалить комплект"
                            onClick={() =>
                                confirm({
                                    title: "Удаление комплекта",
                                    description:
                                        "Вы действительно хотите удалить этот комплект?",
                                    confirmationText: "Удалить",
                                    cancellationText: "Отмена"
                                })
                                    .then(() => onDelete())
                                    .catch(() => undefined)
                            }
                        />
                    </Grid>
                }
            />
            <CardContent>
                <Typography
                    variant="h6"
                    sx={{ textTransform: "uppercase" }}
                    gutterBottom
                >
                    {set.title}
                </Typography>
                <Typography
                    variant="caption"
                    sx={{ textTransform: "uppercase", display: "block", marginBottom: 2 }}
                >
                    {set.collection.value}
                </Typography>
                {set.banner && <Typography>
                    <img width={100} src={config.endpoint + set.banner} />
                </Typography>}
            </CardContent>
        </Card>
    );
};

export default SetCard;
