import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import AppTextField from "components/common/appTextField";
import FormHeader from "components/common/formHeader";
import { validator } from "utils/validator";
import { toast } from "react-toastify";
import { CollectionResponseType } from "types/collection/collectionResponseType";
import {
    useCreateCollectionMutation,
    useUpdateCollectionMutation
} from "store/services/collection.service";

interface IData {
    title: string;
    value: string;
    slug: string;
}

interface ICollectionForm {
    onFinish: () => void;
    defaultValues?: CollectionResponseType;
    title?: string;
}

const CollectionForm: React.FC<ICollectionForm> = ({
    defaultValues,
    onFinish,
    title
}) => {
    const isEdit = !!defaultValues;
    const [data, setData] = useState<IData>(
        isEdit
            ? {
                title: defaultValues.title,
                value: defaultValues.value,
                slug: defaultValues.slug
            }
            : {
                title: "",
                value: "",
                slug: ""
            }
    );

    const [errors, setErrors] = useState<{
        title?: string;
        value?: string;
        slug?: string
    }>({});
    const isValid = Object.keys(errors).length === 0;
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [updateCollection] = useUpdateCollectionMutation();
    const [createCollection] = useCreateCollectionMutation();

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [data]);

    const validatorConfig = {
        title: {
            isRequired: {
                message: "Укажите название на русском языке"
            }
        },
        value: {
            isRequired: {
                message: "Укажите название на английском языке"
            }
        },
        slug: {
            isRequired: {
                message: "Укажите slug"
            }
        },
    };

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (target: { name: string; value: string }) => {
        setIsUpdated(true);

        if (target.name === "value") {
            setData((prevState) => ({
                ...prevState,
                [target.name]: target.value,
                slug: target.value.replaceAll(" ", "-").toLowerCase()
            }));
        } else {
            setData((prevState) => ({
                ...prevState,
                [target.name]: target.value,
            }));
        }
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsTrySubmit(true);
        const isValid = validate();
        if (!isValid) return;
        if (isEdit) {
            try {
                await updateCollection({
                    id: defaultValues.id,
                    ...data
                }).unwrap();
                toast.success("Коллекция успешно обновлёна");
            } catch (e) {
                return toast.error("Не удалось обновить коллекцию");
            }
        } else {
            try {
                await createCollection(data).unwrap();
                toast.success("Коллекция создана");
            } catch (e) {
                return toast.error("Не удалось создать коллекцию");
            }
        }
        onFinish();
    };

    return (
        <>
            {title && <FormHeader title={title} />}
            <form onSubmit={handleSubmit}>
                <Grid container wrap="nowrap" alignItems="center">
                    <AppTextField
                        label="Название (рус.)"
                        name="title"
                        onChange={handleChange}
                        value={data.title}
                        placeholder="Название (рус.)"
                        error={errors.title}
                    />
                    <AppTextField
                        label="Название (англ.)"
                        name="value"
                        onChange={handleChange}
                        value={data.value}
                        placeholder="Название (англ.)"
                        error={errors.value}
                    />
                    <AppTextField
                        label="Slug"
                        name="slug"
                        onChange={handleChange}
                        value={data.slug}
                        placeholder="Slug"
                        error={errors.slug}
                    />
                </Grid>
                <Grid container justifyContent="space-between" mt={1}>
                    <AppButton onClick={onFinish} variant="contained">
                        Отмена
                    </AppButton>
                    <AppButton
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={!isValid || !isUpdated}
                    >
                        Сохранить
                    </AppButton>
                </Grid>
            </form>
        </>
    );
};

export default CollectionForm;
