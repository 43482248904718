import { useEffect, useState } from "react";

interface IUseIsMobile {
    isMobile: boolean;
}

export const useIsMobile = (): IUseIsMobile => {
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        const updateDimensions = () => {
            setIsMobile(window.innerWidth <= 1180);
        };
        updateDimensions();

        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return { isMobile };
};
