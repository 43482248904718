import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    useDeleteProductMutation,
    useGetProductQuery
} from "store/services/product.service";
import ColorEntityForm from "components/ui/forms/colorEntityForm";
import { GalleryResponseType } from "types/gallery/galleryResponseType";
import { ProductEntityResponseType } from "types/productEntity/productEntityResponseType";
import { ColorEntityResponseType } from "types/colorEntity/colorEntityResponseType";
import AppModal from "components/common/appModal";
import { useDeleteGalleryMutation } from "store/services/gallery.service";
import { useDeletColorEntityMutation } from "store/services/colorEntity.service";
import { toast } from "react-toastify";
import { useDeleteProductEntityMutation } from "store/services/productEntity.service";
import ProductForm from "components/ui/forms/productForm";
import GalleryForm from "components/ui/forms/galleryForm";
import ProductEntityForm from "components/ui/forms/productEntityForm";
import { FormType } from "enums/formType";
import ProductCard from "components/ui/cards/productCard";
import ColorEntitiesCard from "components/ui/cards/colorEntitiesCard";
import GalleryCard from "components/ui/cards/galleryCard";
import ProductEntitiesCard from "components/ui/cards/productEntitiesCard";
import AppH4 from "components/common/appH4";
import MarkingLogo from "components/ui/markingLogo";
import { Box } from "@mui/material";

const ProductPage: React.FC = () => {
    const { productId } = useParams();
    const categoryId = useLocation().state?.categoryId;
    const { data: product } = useGetProductQuery(productId as string);
    const [form, setForm] = useState<string>("");
    const [open, setOpen] = useState<boolean>(false);
    const [currentColorEntity, setCurrentColorEntity] =
        useState<ColorEntityResponseType>();
    const [currentGallery, setCurrentGallery] =
        useState<GalleryResponseType[]>();
    const [currentProductEntities, setCurrentProductEntities] =
        useState<ProductEntityResponseType[]>();
    const [currentProductEntity, setCurrentProductEntity] =
        useState<ProductEntityResponseType>();

    const [currentThumb, setCurrentThumb] = useState<GalleryResponseType>();
    const [deleteProduct] = useDeleteProductMutation();
    const [deleteGallery] = useDeleteGalleryMutation();
    const [deleteColorEntity] = useDeletColorEntityMutation();
    const [deleteProductEntity] = useDeleteProductEntityMutation();
    const navigate = useNavigate();
    const colorEntityNextPosition = useMemo(() => product?.colorEntities.length
        ? Math.max(...product.colorEntities.map(ce => ce.position)) + 1
        : 1,
        [product]);
    const galleryMaxPosition = useMemo(() => currentGallery?.length
        ? Math.max(...currentGallery.map(g => g.position)) + 1
        : 1, [currentGallery])

    useEffect(() => {
        const colorEntity = product?.colorEntities.find(
            (ce) => ce.id === currentColorEntity?.id
        );
        if (colorEntity) {
            const { gallery, productEntities } = colorEntity;
            setCurrentColorEntity(colorEntity);
            setCurrentGallery(gallery);
            setCurrentProductEntities(productEntities);
        } else if (product?.colorEntities[0]) {
            setCurrentColorEntity(product.colorEntities[0]);
        } else {
            setCurrentGallery(undefined);
            setCurrentProductEntities(undefined);
        }
    }, [currentColorEntity, product]);

    if (!product) return null;

    const handleChooseForm = (form: string) => {
        setForm(form);
        setOpen(true);
    };

    const handleFinish = () => {
        setOpen(false);
    };

    const handleColorEntityChoose = (colorEntityId: number) => {
        const colorEntity = product.colorEntities.find(
            (ce) => ce.id === colorEntityId
        );
        setCurrentColorEntity(colorEntity);
        setCurrentThumb(undefined);
    };

    const handleProductEntityEdit = (productEntityId: number) => {
        const productEntity = currentProductEntities?.find(
            (productEntity) => productEntity.id === productEntityId
        );
        setCurrentProductEntity(productEntity);
        handleChooseForm(FormType.PRODUCT_ENTITY_EDIT);
    };

    const handleGalleryChoose = (galleryId: number) => {
        if (currentGallery) {
            const gallery = currentGallery.find(
                (g) => g.id === galleryId
            );
            setCurrentThumb(gallery);
        }
    };

    const handleDeleteProduct = async () => {
        if (productId) {
            try {
                await deleteProduct(Number(productId)).unwrap();
                toast.success("Продукт удалён");
                navigate(
                    categoryId
                        ? `/products?categoryId=${categoryId}`
                        : "/products"
                );
            } catch (e) {
                toast.error("Не удалось удалить продукт");
            }
        }
    };

    const handleDeleteGallery = async () => {
        if (currentThumb) {
            try {
                await deleteGallery(currentThumb.id).unwrap();
                setCurrentThumb(undefined);
                toast.success("Фото удалено");
            } catch (e) {
                toast.error("Не удалось удалить фото");
            }
        }
    };

    const handleDeleteColorEntity = async () => {
        if (currentColorEntity) {
            try {
                await deleteColorEntity(currentColorEntity.id).unwrap();
                setCurrentColorEntity(undefined);
                toast.success("Цвет удалён");
            } catch (e) {
                toast.error("Не удалось удалить цвет");
            }
        }
    };

    const handleProductEntityDelete = async (id: number) => {
        try {
            await deleteProductEntity(id).unwrap();
            toast.success("Продукт удалён");
        } catch (e) {
            toast.error("Не удалось удалить продукт");
        }
    };

    return (
        <>
            <AppH4>{product.title}</AppH4>
            <Box width={70}>
                {product.marking && <MarkingLogo />}
            </Box>
            <ProductCard
                product={product}
                onFormChoose={handleChooseForm}
                onDelete={handleDeleteProduct}
            />
            <ColorEntitiesCard
                colorEntities={product.colorEntities}
                currentColorEntity={currentColorEntity}
                onColorEntityChoose={handleColorEntityChoose}
                onFormChoose={handleChooseForm}
                onDelete={handleDeleteColorEntity}
            />
            {currentColorEntity && (
                <GalleryCard
                    currentGallery={currentGallery}
                    currentThumb={currentThumb}
                    onDelete={handleDeleteGallery}
                    onFormChoose={handleChooseForm}
                    onGalleryChoose={handleGalleryChoose}
                />
            )}
            {currentColorEntity && (
                <ProductEntitiesCard
                    currentProductEntities={currentProductEntities}
                    onFormChoose={handleChooseForm}
                    onProductEntityEdit={handleProductEntityEdit}
                    onProductEntityDelete={handleProductEntityDelete}
                />
            )}
            <AppModal open={open}>
                {form === FormType.PRODUCT_EDIT && (
                    <ProductForm
                        title="Редактировать продукт"
                        defaultValues={product}
                        onFinish={handleFinish}
                    />
                )}
                {form === FormType.COLOR_ENTITY_NEW && (
                    <ColorEntityForm
                        title="Добавить цвет"
                        defaultValues={{ productId: product.id, position: colorEntityNextPosition }}
                        onFinish={handleFinish}
                    />
                )}
                {form === FormType.COLOR_ENTITY_EDIT && currentColorEntity && (
                    <ColorEntityForm
                        title="Редактировать цвет"
                        defaultValues={currentColorEntity}
                        onFinish={handleFinish}
                    />
                )}
                {form === FormType.GALLERY_NEW && currentColorEntity && (
                    <GalleryForm
                        title="Добавить фото"
                        defaultValues={{
                            colorEntityId: currentColorEntity.id,
                            position: galleryMaxPosition
                        }}
                        onFinish={handleFinish}
                    />
                )}
                {form === FormType.GALLERY_EDIT && currentThumb && (
                    <GalleryForm
                        title="Редактировать фото"
                        defaultValues={currentThumb}
                        onFinish={handleFinish}
                    />
                )}
                {form === FormType.PRODUCT_ENTITY_NEW && currentColorEntity && (
                    <ProductEntityForm
                        title="Добавить продукт"
                        defaultValues={{ colorEntityId: currentColorEntity.id }}
                        onFinish={handleFinish}
                    />
                )}
                {form === FormType.PRODUCT_ENTITY_EDIT &&
                    currentProductEntity && (
                        <ProductEntityForm
                            title="Редактировать продукт"
                            defaultValues={currentProductEntity}
                            onFinish={handleFinish}
                        />
                    )}
            </AppModal>
        </>
    );
};

export default ProductPage;
