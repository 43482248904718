import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useLazyGetProductsQuery } from "store/services/product.service";
import AppButton from "components/common/appButton";
import AppModal from "components/common/appModal";
import ProductsDataGrid from "components/ui/productsDataGrid";
import ProductForm from "components/ui/forms/productForm";
import { FormType } from "enums/formType";
import AppH4 from "../common/appH4";
import CategoriesSelect, { CategoriesTreeType } from "components/ui/forms/selects/categoriesSelect";

const ProductsPage: React.FC = () => {
    const [getProducts, { data: products }] = useLazyGetProductsQuery();
    const [search, setSearch] = useSearchParams();
    const categoryId = search.get("categoryId") || "";
    const [form, setForm] = useState<string>("");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (categoryId) {
            getProducts(categoryId);
        } else {
            setSearch({ categoryId: "1" });
        }
    }, [search]);

    const handleChooseForm = (form: string) => {
        setForm(form);
        setOpen(true);
    };

    const handleChange = (target: { name: string; value: string }) => {
        setSearch({ categoryId: target.value });
    };

    const handleFinish = () => {
        setOpen(false);
    };

    return (<>
        <AppH4>Продукты</AppH4>
        <Grid>
            <Grid container justifyContent="end" mb={1}>
                <AppButton
                    icon={<AddIcon />}
                    tooltip="Создать продукт"
                    onClick={() => handleChooseForm(FormType.PRODUCT_NEW)}
                />
            </Grid>
            <form>
                <CategoriesSelect
                    name="categoryId"
                    value={categoryId}
                    label="Категория"
                    onChange={handleChange}
                    clearable={false}
                    treeType={CategoriesTreeType.PRODUCT}
                />
            </form>
            {products && categoryId && <ProductsDataGrid products={products} />}
            <AppModal open={open}>
                {form === FormType.PRODUCT_NEW && (
                    <ProductForm onFinish={handleFinish} />
                )}
            </AppModal>
        </Grid>
    </>
    );
};

export default ProductsPage;
