import React from 'react';
import AppSelect from "components/common/appSelect";
import { useGetStatusesQuery } from 'store/services/status.service';

interface IStatusesSelect {
    name: string;
    label: string;
    value: string;
    onChange: (target: { name: string; value: string }) => void;
    error?: string;
    clearable?: boolean
}

const StatusesSelect: React.FC<IStatusesSelect> = ({
    name,
    label,
    value,
    onChange,
    error,
    clearable = false
}) => {
    const { data: statuses } = useGetStatusesQuery();

    if (!statuses) return null;

    return <AppSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        error={error}
        clearable={clearable}
    >
        {statuses.map((status) => (
            <AppSelect.AppOption
                value={status.id.toString()}
                key={status.id}
            >
                {status.title}
            </AppSelect.AppOption>
        ))}
    </AppSelect>;
}

export default StatusesSelect;