import React from "react";
import { Box, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import AppIconButton from "components/common/appButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { FormType } from "enums/formType";
import { useConfirm } from "material-ui-confirm";
import { GalleryResponseType } from "types/gallery/galleryResponseType";
import AppDraggableList from "components/common/appDraggableList";
import { useReorderGalleryMutation } from "store/services/gallery.service";
import { toast } from "react-toastify";
import GalleryThumb from "components/common/galleryThumb";

interface IGallery {
    currentGallery?: GalleryResponseType[];
    currentThumb?: GalleryResponseType;
    onFormChoose: (form: string) => void;
    onDelete: () => void;
    onGalleryChoose: (galleryId: number) => void;
}

const GalleryCard: React.FC<IGallery> = ({
    currentGallery,
    currentThumb,
    onFormChoose,
    onDelete,
    onGalleryChoose
}) => {
    const confirm = useConfirm();
    const [reorderGallery] = useReorderGalleryMutation();

    const handleReorder = async (data: { sourceId: number, destinationId: number }) => {
        const { sourceId, destinationId } = data;
        try {
            await reorderGallery([sourceId, destinationId]).unwrap();
        } catch (e) {
            toast.error("Не удалось отсортировать галерею");
        }
    }

    return (
        <Card sx={{ mb: 1 }}>
            <CardHeader
                sx={{ float: "right" }}
                action={
                    <Grid container justifyContent="end" mb={1}>
                        <AppIconButton
                            icon={<FileUploadIcon />}
                            tooltip="Добавить фото"
                            onClick={() => onFormChoose(FormType.GALLERY_NEW)}
                        />
                        {currentThumb && (
                            <AppIconButton
                                icon={<EditIcon />}
                                tooltip="Редактировать фото"
                                onClick={() =>
                                    onFormChoose(FormType.GALLERY_EDIT)
                                }
                            />
                        )}

                        {currentThumb && (
                            <AppIconButton
                                icon={<DeleteIcon />}
                                tooltip="Удалить фото"
                                onClick={() =>
                                    confirm({
                                        description:
                                            "Вы действительно хотите удалить фото?"
                                    })
                                        .then(() => onDelete())
                                        .catch(() => undefined)
                                }
                            />
                        )}
                    </Grid>
                }
            />
            <CardContent>
                {currentGallery?.length ? (
                    <Box sx={{ display: "flex", gap: "5px", maxWidth: "80%", flexWrap: "wrap" }}>
                        <AppDraggableList
                            items={currentGallery}
                            currentItem={currentThumb}
                            style={{
                                width: "100px"
                            }}
                            activeStyle={{
                                outline: "1px solid black",
                            }}
                            onChooseItem={onGalleryChoose}
                            onDrop={handleReorder}
                            renderComponent={GalleryThumb}
                            srcPath={"src"}
                        />
                    </Box>
                ) : (
                    <Typography
                        variant="subtitle2"
                        sx={{ fontStyle: "italic", color: "#c2c2c2" }}
                    >
                        Фото пока нет...
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default GalleryCard;
