import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import { appBaseQueryWithReauth } from "./http.service";
import { PvzResponseType } from "types/cdek/pvzResponseType";
import { CdekOrderResponseType } from "types/cdek/cdekOrderResponseType";
import { orderApi } from "./order.service";

const cdekEndpoint = config.apiEndpoint + "/cdek";

export const cdekApi = createApi({
    reducerPath: "cdekApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: cdekEndpoint }),
    endpoints: (builder) => ({
        createCdekOrder: builder.mutation<CdekOrderResponseType, number>({
            query: (orderId) => ({
                url: `/create/${orderId}`,
                method: "POST"
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(orderApi.util.invalidateTags(["Order"]));
            }
        }),
        getPvzByCode: builder.query<PvzResponseType, string>({
            query: (pvzCode) => ({
                url: `/pvz/${pvzCode}`,
                method: "GET"
            })
        })
    })
});

export const { useCreateCdekOrderMutation, useLazyGetPvzByCodeQuery } = cdekApi;
