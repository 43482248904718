import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import AppTextField from "components/common/appTextField";
import FormHeader from "components/common/formHeader";
import { validator } from "utils/validator";
import { toast } from "react-toastify";
import { DeliveryRequestType } from "types/delivery/deliveryRequestType";
import { DeliveryResponseType } from "types/delivery/deliveryResponseType";
import { useCreateDeliveryMutation, useUpdateDeliveryMutation } from "store/services/delivery.service";

interface IDeliveryForm {
    onFinish: () => void;
    defaultValues?: DeliveryResponseType;
    title?: string;
}

const DeliveryForm: React.FC<IDeliveryForm> = ({
    defaultValues,
    onFinish,
    title
}) => {
    const isEdit = !!defaultValues;
    const [data, setData] = useState<DeliveryRequestType>(
        isEdit
            ? {
                name: defaultValues.name,
                provider: defaultValues.provider,
                tariff: defaultValues.tariff,
                method: defaultValues.method,
                cost: defaultValues.cost
            }
            : {
                name: "",
                provider: "",
                tariff: 0,
                method: 1,
                cost: 0
            }
    );

    const [errors, setErrors] = useState<Partial<Record<keyof DeliveryResponseType, string>>>({});
    const isValid = Object.keys(errors).length === 0;
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [updateDelivery] = useUpdateDeliveryMutation();
    const [createDelivery] = useCreateDeliveryMutation();

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [data]);

    const validatorConfig = {
        name: {
            isRequired: {
                message: "Укажите название на русском языке"
            }
        },
        provider: {
            isRequired: {
                message: "Укажите название службы доставки"
            }
        },
        tariff: {
            isRequired: {
                message: "Укажите тариф"
            }
        },
    };

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (target: { name: string; value: string | number }) => {
        setIsUpdated(true);

        setData((prevState) => ({
            ...prevState,
            [target.name]: target.value
        }));
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsTrySubmit(true);
        const isValid = validate();
        if (!isValid) return;
        if (isEdit) {
            try {
                await updateDelivery({
                    id: defaultValues.id,
                    ...data
                }).unwrap();
                toast.success("Способ доставки успешно обновлён");
            } catch (e) {
                return toast.error("Не удалось обновить способ доставки");
            }
        } else {
            try {
                await createDelivery(data).unwrap();
                toast.success("Способ доставки создан");
            } catch (e) {
                return toast.error("Не удалось создать способ доставки");
            }
        }
        onFinish();
    };

    return (
        <>
            {title && <FormHeader title={title} />}
            <form onSubmit={handleSubmit}>
                <Grid container wrap="nowrap" alignItems="center">
                    <AppTextField
                        name="name"
                        onChange={handleChange}
                        value={data.name}
                        placeholder="Название (рус.)"
                        error={errors.name}
                    />
                    <AppTextField
                        name="provider"
                        onChange={handleChange}
                        value={data.provider}
                        placeholder="Название службы доставки"
                        error={errors.provider}
                    />
                    <AppTextField
                        name="tariff"
                        onChange={handleChange}
                        value={data.tariff.toString()}
                        placeholder="Тариф"
                        error={errors.tariff}
                    />
                    <AppTextField
                        name="method"
                        onChange={handleChange}
                        value={data.method.toString()}
                        placeholder="Метод доставки"
                        error={errors.method}
                    />
                    <AppTextField
                        name="cost"
                        type="number"
                        onChange={handleChange}
                        value={data.cost.toString()}
                        placeholder="Стоимость доставки"
                        error={errors.cost}
                    />
                </Grid>
                <Grid container justifyContent="space-between" mt={1}>
                    <AppButton onClick={onFinish} variant="contained">
                        Отмена
                    </AppButton>
                    <AppButton
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={!isValid || !isUpdated}
                    >
                        Сохранить
                    </AppButton>
                </Grid>
            </form>
        </>
    );
};

export default DeliveryForm;
