import React from 'react';
import { useGetTagsQuery } from 'store/services/tag.service';
import AppMultiSelect from 'components/common/appMultiSelect';

interface ITagsMultiSelect {
    name: string;
    label: string;
    value: string[];
    onChange: (target: { name: string; value: string | string[] }) => void;
    error?: string;
    clearable?: boolean
}

const TagsMultiSelect: React.FC<ITagsMultiSelect> = ({
    name,
    label,
    value,
    onChange,
    error,
    clearable = false
}) => {
    const { tags } = useGetTagsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            tags: data?.map((tag) => ({
                value: tag.id.toString(),
                title: tag.title
            }))
        })
    });

    if (!tags) return null;

    return <AppMultiSelect
        label={label}
        name={name}
        value={value}
        options={tags}
        onChange={onChange}
        clearable={clearable}
        error={error}
    />

}

export default TagsMultiSelect;