import React from 'react';
import AppSelect from "components/common/appSelect";
import { useGetColorsQuery } from 'store/services/color.service';
import { Grid } from '@mui/material';
import ColorThumb from 'components/common/colorThumb';

interface IColorsSelect {
    name: string;
    label: string;
    value: string;
    onChange: (target: { name: string; value: string }) => void;
    error?: string;
}

const ColorsSelect: React.FC<IColorsSelect> = ({
    name,
    label,
    value,
    onChange,
    error
}) => {
    const { data: colors } = useGetColorsQuery();

    if (!colors) return null;

    return <AppSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        error={error}
    >
        {colors.map((color) => (
            <AppSelect.AppOption
                value={color.id.toString()}
                key={color.id}
            >
                <Grid container gap={1} alignItems="center">
                    <span>{color.title}</span>
                    <ColorThumb src={color.src} />
                </Grid>
            </AppSelect.AppOption>
        ))}
    </AppSelect>;
}

export default ColorsSelect;