import React from 'react';
import AppMultiSelect from 'components/common/appMultiSelect';
import { useGetCategoriesQuery } from 'store/services/category.service';

interface ICategoriesMultiSelect {
    name: string;
    label: string;
    value: string[];
    onChange: (target: { name: string; value: string | string[] }) => void;
    error?: string;
    clearable?: boolean
}

const CategoriesMultiSelect: React.FC<ICategoriesMultiSelect> = ({
    name,
    label,
    value,
    onChange,
    error,
    clearable = false
}) => {
    const { categories } = useGetCategoriesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            categories: data
                // ?.filter(c =>
                // !c.children?.length && !c.slug.includes("sets"))
                ?.map((category) => ({
                    value: category.id.toString(),
                    title: category.title,
                    children: category.children,
                    parentId: category.parentId
                }))
        })
    });

    if (!categories) return null;

    return <AppMultiSelect
        label={label}
        name={name}
        value={value}
        options={categories}
        onChange={onChange}
        clearable={clearable}
        error={error}
    />

}

export default CategoriesMultiSelect;