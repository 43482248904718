import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import AppTextField from "components/common/appTextField";
import FormHeader from "components/common/formHeader";
import React, { useEffect, useState } from "react";
import { ColorResponseType } from "types/color/colorResponseType";
import { config } from "config";
import { validator } from "utils/validator";
import { toast } from "react-toastify";
import AppFileField from "components/common/appFileField";
import {
    useCreateColorMutation,
    useUpdateColorMutation
} from "store/services/color.service";

interface IData {
    title: string;
    value: string;
    slug: string;
    cover: string | FileList | null;
}

interface IColorForm {
    onFinish: () => void;
    defaultValues?: ColorResponseType;
    title?: string;
}

const ColorForm: React.FC<IColorForm> = ({
    defaultValues,
    onFinish,
    title
}) => {
    const isEdit = !!defaultValues;
    const [data, setData] = useState<IData>(
        isEdit
            ? {
                title: defaultValues.title,
                value: defaultValues.value,
                slug: defaultValues.slug,
                cover: defaultValues.src
            }
            : {
                title: "",
                value: "",
                slug: "",
                cover: null
            }
    );

    const [errors, setErrors] = useState<{
        title?: string;
        cover?: string;
        value?: string;
        slug?: string;
    }>({});
    const isValid = Object.keys(errors).length === 0;
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [createColor] = useCreateColorMutation();
    const [updateColor] = useUpdateColorMutation();
    const isOldCover = data.cover && typeof data.cover === "string";

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [data]);

    const validatorConfig = {
        title: {
            isRequired: {
                message: "Укажите название цвета"
            }
        },
        value: {
            isRequired: {
                message: "Укажите название цвета (англ.)"
            }
        },
        cover: {
            isRequired: {
                message: "Загрузите обложку цвета"
            }
        },
        slug: {
            isRequired: {
                message: "Укажите понятный url"
            }
        },
    };

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (target: {
        name: string;
        value: string | FileList;
    }) => {

        const { name, value } = target;

        setIsUpdated(true);
        setData((prevState) => ({
            ...prevState,
            [name]: value,
            slug: name === "value" || name === "slug"
                ? (value as string).replaceAll(" ", "-").toLowerCase()
                : data.slug
        }));
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsTrySubmit(true);
        const isValid = validate();
        if (!isValid) return;
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            const typedKey = key as keyof IData;
            if (data[typedKey] instanceof FileList) {
                formData.append(key, data[typedKey]?.[0] as Blob);
            } else {
                formData.append(key, data[typedKey] as string);
            }
        });
        if (isEdit) {
            try {
                await updateColor({
                    id: defaultValues.id,
                    payload: formData
                }).unwrap();
                toast.success("Цвет успешно обновлён");
            } catch (e) {
                return toast.error("Не удалось обновить цвет");
            }
        } else {
            try {
                await createColor(formData).unwrap();
                toast.success("Цвет создан");
            } catch (e) {
                return toast.error("Не удалось создать цвет");
            }
        }
        onFinish();
    };

    return (
        <>
            {title && <FormHeader title={title} />}
            <form onSubmit={handleSubmit}>
                <Grid container wrap="nowrap" alignItems="center">
                    <AppTextField
                        label="Название"
                        name="title"
                        onChange={handleChange}
                        value={data.title}
                        placeholder="Название"
                        error={errors.title}
                    />
                    <AppTextField
                        label="Название (англ)"
                        name="value"
                        onChange={handleChange}
                        value={data.value}
                        placeholder="Название (англ)"
                        error={errors.value}
                    />
                    <AppTextField
                        label="Slug"
                        name="slug"
                        onChange={handleChange}
                        value={data.slug}
                        placeholder="Slug"
                        error={errors.slug}
                    />
                    <AppFileField
                        name="cover"
                        accept="image/*"
                        multiple={false}
                        error={errors.cover}
                        onChange={handleChange}
                    />
                    {isOldCover && (
                        <img
                            width={50}
                            src={config.endpoint + data.cover}
                            alt="thumb"
                        />
                    )}
                </Grid>
                <Grid container justifyContent="space-between" mt={1}>
                    <AppButton onClick={onFinish} variant="contained">
                        Отмена
                    </AppButton>
                    <AppButton
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={!isValid || !isUpdated}
                    >
                        Сохранить
                    </AppButton>
                </Grid>
            </form>
        </>
    );
};

export default ColorForm;
