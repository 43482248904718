import React, { useEffect, useState } from "react";
import { validator } from "utils/validator";
import {
    useCreateProductMutation,
    useUpdateProductMutation
} from "store/services/product.service";
import AppTextField from "components/common/appTextField";
import { Grid } from "@mui/material";
import { toast } from "react-toastify";
import { ProductResponseType } from "types/product/productResponseType";
import { useNavigate } from "react-router-dom";
import { ProductRequestType } from "types/product/productRequestType";
import FormHeader from "components/common/formHeader";
import AppButton from "components/common/appButton";
import AppTextArea from "components/common/appTextArea";
import CategoriesSelect, { CategoriesTreeMode, CategoriesTreeType } from "./selects/categoriesSelect";
import CollectionsSelect from "./selects/collectionsSelect";
import SetsSelect from "./selects/setsSelect";
import AppCheckBoxField from "components/common/appCheckboxField";
import chZnakLogo from "../../../assets/chZnak_logo.jpg";

interface IProductForm {
    onFinish: () => void;
    defaultValues?: ProductResponseType;
    title?: string;
}

const ProductForm: React.FC<IProductForm> = ({
    defaultValues,
    onFinish,
    title
}) => {
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const navigate = useNavigate();
    const isEdit = !!defaultValues;

    const [data, setData] = useState<ProductRequestType>(
        isEdit
            ? {
                categoryId: defaultValues.categoryId.toString(),
                collectionId: defaultValues.collectionId.toString(),
                title: defaultValues.title,
                setId: defaultValues.setId || null,
                description: defaultValues.description,
                care: defaultValues.care || "",
                marking: defaultValues.marking
            }
            : {
                categoryId: "",
                collectionId: "",
                title: "",
                setId: null,
                description: "",
                care: "",
                marking: false
            }
    );

    const [errors, setErrors] = useState<Partial<Record<keyof ProductResponseType, string>>>({});

    const isValid = Object.keys(errors).length === 0;
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const [updateProduct] = useUpdateProductMutation();
    const [createProduct] = useCreateProductMutation();

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [data]);

    const validatorConfig = {
        categoryId: {
            isRequired: {
                message: "Выберите категорию"
            }
        },
        collectionId: {
            isRequired: {
                message: "Выберите коллекцию"
            }
        },
        title: {
            isRequired: {
                message: "Введите название продукта"
            }
        },
        description: {
            isRequired: {
                message: "Введите описание продукта"
            }
        },
    };

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (target: { name: string; value: number | string | boolean }) => {
        setIsUpdated(true);
        setData((prevState) => ({
            ...prevState,
            [target.name]: target.value
        }));
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsTrySubmit(true);
        const isValid = validate();
        if (!isValid) return;
        if (isEdit) {
            try {
                const updatedProduct = {
                    id: defaultValues.id.toString(),
                    ...data
                };
                await updateProduct(updatedProduct).unwrap();
                toast.success("Продукт успешно обновлён");
                onFinish();
            } catch (e) {
                toast.error("Не удалось обновить продукт");
            }
        } else {
            try {
                const { id } = await createProduct(data).unwrap();
                toast.success("Продукт создан");
                navigate(`/products/${id}`);
            } catch (e) {
                toast.error("Не удалось создать продукт");
            }
        }
    };

    return (
        <>
            {title && <FormHeader title={title} />}
            <form onSubmit={handleSubmit}>
                <AppTextField
                    name="title"
                    onChange={handleChange}
                    value={data.title}
                    placeholder="Название"
                    error={errors.title}
                />
                <Grid container alignItems="center">
                    <AppCheckBoxField
                        name="marking"
                        onChange={handleChange}
                        checked={data.marking}
                        error={errors.marking}
                    />
                    <img
                        width={70}
                        src={chZnakLogo}
                    />
                </Grid>
                <Grid container wrap="nowrap" alignItems="center">
                    <CategoriesSelect
                        name="categoryId"
                        value={data.categoryId}
                        label="Категория"
                        onChange={handleChange}
                        clearable={false}
                        error={errors.categoryId}
                        treeType={CategoriesTreeType.PRODUCT}
                        treeMode={CategoriesTreeMode.CREATE}
                    />
                    <CollectionsSelect
                        label="Коллекция"
                        name="collectionId"
                        value={data.collectionId}
                        onChange={handleChange}
                        error={errors.collectionId}
                    />
                    <SetsSelect
                        label="Комплект"
                        name="setId"
                        value={data.setId?.toString() || ""}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid container flexDirection="column">
                    <AppTextArea
                        name="description"
                        onChange={handleChange}
                        value={data.description}
                        placeholder="Описание"
                        error={errors.description}
                    />
                    <AppTextArea
                        name="care"
                        onChange={handleChange}
                        value={data.care}
                        placeholder="Уход"
                        error={errors.care}
                    />
                </Grid>
                <Grid container justifyContent="space-between" mt={1}>
                    <AppButton onClick={onFinish} variant="contained">
                        Отмена
                    </AppButton>
                    <AppButton
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={!isValid || !isUpdated}
                    >
                        Сохранить
                    </AppButton>
                </Grid>
            </form>
        </>
    );
};

export default ProductForm;
