import React, { useEffect, useState } from "react";
import AppButton from "components/common/appButton";
import { validator } from "utils/validator";
import AppTextField from "components/common/appTextField";
import FormHeader from "components/common/formHeader";
import { Box, Grid } from "@mui/material";
import { LoginWithPasswordRequestType } from "types/auth/loginWithPasswordRequestType";
import { useLoginWithPasswordMutation } from "store/services/auth.service";

const LoginWithPasswordForm = () => {
    const [data, setData] = useState<LoginWithPasswordRequestType>({
        email: "",
        password: ""
    })
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const [errors, setErrors] = useState<Partial<LoginWithPasswordRequestType>>({});
    const isValid = Object.keys(errors).length === 0;
    const [loginWithPassword] = useLoginWithPasswordMutation();

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [isTrySubmit, data]);

    const handleChange = (target: { name: string, value: string }) => {
        setData((prevState) => ({
            ...prevState,
            [target.name]: target.value
        }));
    };

    const validatorConfig = {
        email: {
            isRequired: {
                message: "Укажите ваш e-mail",
            },
            isEmail: {
                message: "Неверный формат e-mail",
            },
        },
        password: {
            isRequired: {
                message: "Пароль обязателен"
            },
        }
    };

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleLogin = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsTrySubmit(true);
        const isValid = validate();
        if (!isValid) return;
        try {
            await loginWithPassword(data).unwrap();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Grid container direction="column">
            <>
                <FormHeader title="Войти или зарегистрироваться" />
                <form onSubmit={handleLogin}>
                    <Box marginBottom={2}>
                        <AppTextField
                            name="email"
                            placeholder="E-mail"
                            value={data.email}
                            onChange={handleChange}
                            error={errors.email}
                        />
                        <AppTextField
                            name="password"
                            onChange={handleChange}
                            value={data.password}
                            placeholder="Пароль"
                            error={errors.password}
                            type="password"
                        />
                    </Box>
                    <Box textAlign="center">
                        <AppButton
                            type="submit"
                            disabled={!isValid}
                            onClick={handleLogin}
                            variant="contained"
                        >
                            Войти
                        </AppButton>
                    </Box>
                </form>
            </>
        </Grid>
    );
};

export default LoginWithPasswordForm;
