import React, { useState } from "react";
import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import AppModal from "components/common/appModal";
import { FormType } from "enums/formType";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import AppH4 from "components/common/appH4";
import { useGetPromocodeQuery } from "store/services/promocode.service";
import { PromocodeResponseType } from "types/promocode/promocodeResponseType";
import PromocodeForm from "components/ui/forms/promocodeForm";
import PromocodeUsersDataGrid from "components/ui/promocodeUsersDataGrid";

const PromocodePage: React.FC = () => {
    const { promocodeId } = useParams();
    const { data: promocode } = useGetPromocodeQuery(promocodeId as string);

    const [form, setForm] = useState<string>("");
    const [open, setOpen] = useState(false);

    const handleChooseForm = (form: string) => {
        setForm(form);
        setOpen(true);
    };

    const handleFinish = () => {
        setOpen(false);
    };

    if (!promocode) return null;

    return (
        <>
            <AppH4>{promocode.value}</AppH4>
            <Grid>
                <Grid container justifyContent="end" mb={1}>
                    <AppButton
                        icon={<EditIcon />}
                        tooltip="Редактировать промокод"
                        onClick={() => handleChooseForm(FormType.PROMOCODE_EDIT)}
                    />
                </Grid>
                {promocode && (
                    <PromocodeUsersDataGrid
                        users={promocode.users}
                    />
                )}
                <AppModal open={open}>
                    {form === FormType.PROMOCODE_EDIT && (
                        <PromocodeForm
                            onFinish={handleFinish}
                            defaultValues={promocode as PromocodeResponseType}
                        />
                    )}
                </AppModal>
            </Grid>
        </>
    );
};

export default PromocodePage;
