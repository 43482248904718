import * as React from "react";
import { NavLink } from "react-router-dom";

interface INavLink {
    to: string;
    children: React.ReactNode;
}

const AppNavLink: React.FC<INavLink> = ({ to, children }) => {
    return (
        <NavLink
            to={to}
            style={({ isActive }) => ({
                textDecoration: isActive ? "underline" : "none",
                color: "#000",
                width: "100%"
            })}
        >
            {children}
        </NavLink>
    );
};

export default AppNavLink;
