import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import { appBaseQueryWithReauth } from "./http.service";
import { OrderItemResponseType } from "types/order/orderItemResponseType";
import { orderApi } from "./order.service";
import { OrderItemRequestType } from "types/order/orderItemRequestType";

const orderItemEndpoint = config.apiEndpoint + "/order-item";

export const orderItemApi = createApi({
    reducerPath: "orderItemApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: orderItemEndpoint }),
    tagTypes: ["Order-Item"],
    endpoints: (builder) => ({
        bulkUpdateOrderItems: builder.mutation<
            OrderItemResponseType[],
            OrderItemRequestType[]
        >({
            query: (payload) => ({
                url: "/",
                method: "PATCH",
                data: {
                    orderItems: payload
                }
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(orderApi.util.invalidateTags(["Order"]));
            }
        })
    })
});

export const { useBulkUpdateOrderItemsMutation } = orderItemApi;
