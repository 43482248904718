import { Typography } from "@mui/material";
import React from "react";

interface IFormHeader {
    title: string;
}

const FormHeader: React.FC<IFormHeader> = ({ title }) => {
    return (
        <Typography
            variant="h6"
            textAlign="center"
            gutterBottom
            sx={{ textTransform: "uppercase" }}
        >
            {title}
        </Typography>
    );
};

export default FormHeader;
