import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import AppTextField from "components/common/appTextField";
import FormHeader from "components/common/formHeader";
import React, { useEffect, useState } from "react";
import { validator } from "utils/validator";
import { toast } from "react-toastify";
import { UserResponseType } from "types/user/userResponseType";
import { UserRequestType } from "types/user/userRequestType";
import { useCreateUserMutation, useUpdateUserMutation } from "store/services/user.service";

interface IUserForm {
    onFinish: () => void;
    defaultValues?: UserResponseType;
    title?: string;
}

const UserForm: React.FC<IUserForm> = ({ defaultValues, onFinish, title }) => {
    const isEdit = !!defaultValues;
    const [data, setData] = useState<UserRequestType>(
        isEdit
            ? {
                email: defaultValues.email
            }
            : {
                email: ""
            }
    );

    const [errors, setErrors] = useState<Partial<UserRequestType>>({});
    const isValid = Object.keys(errors).length === 0;
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [updateUser] = useUpdateUserMutation();
    const [createUser] = useCreateUserMutation();

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [data]);

    const validatorConfig = {
        email: {
            isRequired: {
                message: "Укажите почту"
            },
            isEmail: {
                message: "Неверный формат e-mail"
            }
        }
    };

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (target: { name: string; value: string }) => {
        setIsUpdated(true);
        setData((prevState) => ({
            ...prevState,
            [target.name]: target.value.trim()
        }));
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsTrySubmit(true);
        const isValid = validate();
        if (!isValid) return;
        if (isEdit) {
            try {
                await updateUser({
                    id: defaultValues.id,
                    ...data
                }).unwrap();
                toast.success("Данные пользователя успешно обновлены");
            } catch (e) {
                return toast.error("Не удалось обновить данные пользователя");
            }
        } else {
            try {
                await createUser(data).unwrap();
                toast.success("Пользователь создан");
            } catch (e) {
                return toast.error("Не удалось создать пользователя");
            }
        }
        onFinish();
    };

    return (
        <>
            {title && <FormHeader title={title} />}
            <form onSubmit={handleSubmit}>
                <Grid container wrap="nowrap" alignItems="center">
                    <AppTextField
                        name="email"
                        onChange={handleChange}
                        value={data.email}
                        placeholder="Размер"
                        error={errors.email}
                    />
                </Grid>
                <Grid container justifyContent="space-between" mt={1}>
                    <AppButton onClick={onFinish} variant="contained">
                        Отмена
                    </AppButton>
                    <AppButton
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={!isValid || !isUpdated}
                    >
                        Сохранить
                    </AppButton>
                </Grid>
            </form>
        </>
    );
};

export default UserForm;
