import React, { useState } from "react";
import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import AddIcon from "@mui/icons-material/Add";
import AppModal from "components/common/appModal";
import { FormType } from "enums/formType";
import { useConfirm } from "material-ui-confirm";
import { toast } from "react-toastify";
import { useDeleteCategoryMutation, useGetCategoriesQuery } from "store/services/category.service";
import { CategoryResponseType } from "types/category/categoryResponseType";
import AppList from "components/common/appList";
import CategoryForm from "components/ui/forms/categoryForm";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AppH4 from "components/common/appH4";

const CategoriesPage: React.FC = () => {
    const { data: categories } = useGetCategoriesQuery();
    const [currentCategory, setCurrentCategory] = useState<CategoryResponseType>();
    const confirm = useConfirm();
    const [deleteCategory] = useDeleteCategoryMutation();

    const [form, setForm] = useState<FormType>();
    const [open, setOpen] = useState(false);

    const handleChooseForm = (form: FormType, category?: CategoryResponseType) => {
        category && setCurrentCategory(category);
        setForm(form);
        setOpen(true);
    };

    const handleCategoryEdit = (categoryId: number) => {
        const category = categories?.find((category) => category.id === categoryId);
        if (category) {
            setCurrentCategory(category);
            handleChooseForm(FormType.CATEGORY_EDIT);
        }
    };

    const handleCategoryDelete = (categoryId: number) => {
        confirm({
            title: "Удаление категории",
            description: "Вы действительно хотите удалить эту категорию?",
            confirmationText: "Удалить",
            cancellationText: "Отмена"
        })
            .then(async () => {
                await deleteCategory(categoryId).unwrap();
                setCurrentCategory(undefined);
                toast.success("Категория удалёна");
            })
            .catch((e) => {
                if (e) {
                    toast.error(e.data.message);
                }
            });
    };

    const handleFinish = () => {
        setOpen(false);
    };

    const getControls = (category: CategoryResponseType, hasChild = true) => {
        return <Grid>
            {hasChild && <AppButton
                icon={<AddIcon />}
                tooltip="Добавить категорию"
                onClick={() => handleChooseForm(FormType.CATEGORY_NEW, category)}
            />}
            <AppButton
                icon={<EditIcon />}
                tooltip="Редактировать"
                onClick={() => handleCategoryEdit(category.id)}
            />
            <AppButton
                icon={<DeleteIcon />}
                tooltip="Удалить"
                onClick={() => handleCategoryDelete(category.id)}
            />
        </Grid>
    }

    return (
        <>
            <AppH4>Категории</AppH4>
            <Grid>
                <Grid container justifyContent="end" mb={1}>
                    <AppButton
                        icon={<AddIcon />}
                        tooltip="Добавить категорию"
                        onClick={() => handleChooseForm(FormType.CATEGORY_NEW)}
                    />
                </Grid>
                <AppList sx={{ display: "flex", flexWrap: "wrap" }}>
                    {categories
                        ?.filter(section => section.parentId === null)
                        .map(section => <React.Fragment key={section.id}>
                            <AppList.AppListItem sx={{ flex: "50%" }}>
                                <Grid container gap="40px">
                                    <AppList.AppListItemText>
                                        {section.title}
                                    </AppList.AppListItemText>
                                    {getControls(section)}
                                </Grid>
                                <AppList>
                                    {
                                        categories
                                            .filter(category => category.parentId === section.id)
                                            .map(category => <React.Fragment key={category.id}>
                                                <AppList.AppListItem key={category.id}>
                                                    <Grid container gap="40px">
                                                        <AppList.AppListItemText>{category.title}</AppList.AppListItemText>
                                                        {getControls(category)}
                                                    </Grid>
                                                    <AppList>
                                                        {categories
                                                            .filter(subcategory => subcategory.parentId === category.id)
                                                            .map(subcategory => <AppList.AppListItem key={subcategory.id}>
                                                                <Grid container gap="40px">
                                                                    <AppList.AppListItemText key={subcategory.id}>{subcategory.title}</AppList.AppListItemText>
                                                                    {getControls(subcategory, false)}
                                                                </Grid>
                                                            </AppList.AppListItem>
                                                            )}
                                                    </AppList>
                                                </AppList.AppListItem>
                                            </React.Fragment>)
                                    }
                                </AppList >
                            </AppList.AppListItem>
                        </React.Fragment>)}
                </AppList>
                <AppModal open={open}>
                    {form === FormType.CATEGORY_NEW && (
                        <CategoryForm parentId={currentCategory?.id} onFinish={handleFinish} />
                    )}
                    {form === FormType.CATEGORY_EDIT && (
                        <CategoryForm
                            onFinish={handleFinish}
                            defaultValues={currentCategory}
                        />
                    )}
                </AppModal>
            </Grid >
        </>
    );
};

export default CategoriesPage;
