import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
    QueryDefinition
} from "@reduxjs/toolkit/query/react";
import { QueryStateSelector } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { CategoryResponseType } from "types/category/categoryResponseType";
import { appBaseQueryWithReauth } from "./http.service";
import { toastProgress } from "utils/toastProgress";
import { config } from "config";
import { CategoriesTreeType } from "components/ui/forms/selects/categoriesSelect";

const categoryEndpoint = config.apiEndpoint + "/category";

type QueryDefinitionType = QueryDefinition<
    void,
    BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        unknown,
        FetchBaseQueryMeta
    >,
    "Category",
    CategoryResponseType[],
    "categoryApi"
>;

export const categoryApi = createApi({
    reducerPath: "categoryApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: categoryEndpoint }),
    tagTypes: ["Category"],
    endpoints: (builder) => ({
        getCategories: builder.query<
            CategoryResponseType[],
            CategoriesTreeType | void
        >({
            query: (type) => ({
                url: type ? `?type=${type}` : "/"
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.map(({ id }) => ({
                              type: "Category" as const,
                              id
                          })),
                          "Category"
                      ]
                    : ["Category"]
        }),
        createCategory: builder.mutation<CategoryResponseType, FormData>({
            query: (payload) => ({
                url: "/",
                method: "POST",
                data: payload
            }),
            invalidatesTags: ["Category"]
        }),
        updateCategory: builder.mutation<
            CategoryResponseType,
            { id: number; payload: FormData }
        >({
            query: ({ id, payload }) => ({
                url: `/${id}`,
                method: "PATCH",
                data: payload,
                onUploadProgress: toastProgress
            }),
            invalidatesTags: ["Category"]
        }),
        deleteCategory: builder.mutation<number, number>({
            query: (id) => ({
                url: `/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["Category"]
        })
    })
});

export const selectCategoryById =
    (
        id: number
    ):
        | QueryStateSelector<
              {
                  category: CategoryResponseType;
              },
              QueryDefinitionType
          >
        | undefined =>
    ({ data }) => ({
        category: data?.find(
            (category) => category.id === Number(id)
        ) as CategoryResponseType
    });

export const getCategoriesBySlugs =
    (
        slugs: string[]
    ):
        | QueryStateSelector<
              {
                  tabs: CategoryResponseType[];
              },
              QueryDefinitionType
          >
        | undefined =>
    ({ data }) => ({
        tabs: slugs.reduce((acc: CategoryResponseType[], curr: string) => {
            const foundCategory = data?.find(
                (category) => category.slug === curr
            );
            return foundCategory ? [...acc, foundCategory] : acc;
        }, [])
    });

export const {
    useCreateCategoryMutation,
    useGetCategoriesQuery,
    useLazyGetCategoriesQuery,
    useDeleteCategoryMutation,
    useUpdateCategoryMutation
} = categoryApi;
export const { getCategories } = categoryApi.endpoints;
