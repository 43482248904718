import React from "react";
import { Container } from "@mui/material";
import LoginWithPasswordForm from "components/ui/forms/loginWithPasswordForm";
import { useSelector } from "react-redux";
import { getIsLoggedIn } from "store/slices/auth.slice";
import { Navigate } from "react-router-dom";

const LoginPage = () => {
    const isLoggedIn = useSelector(getIsLoggedIn);

    if (isLoggedIn) {
        return <Navigate to="/products" />
    }

    return <Container maxWidth="xs" sx={{ pt: "40px" }}>
            <LoginWithPasswordForm />
        </Container>

};

export default LoginPage;
