import { List, ListItem, ListItemText } from "@mui/material";
import React, { ReactNode } from "react";

interface IAppList {
    children: ReactNode;
    sx?: Record<string, string>
}

interface IAppListItem {
    children: ReactNode;
    sx?: Record<string, string>
}

interface IAppListItemText {
    children: ReactNode;
    sx?: Record<string, string>
}

const AppList: React.FC<IAppList> & {
    AppListItem: React.FC<IAppListItem>,
    AppListItemText: React.FC<IAppListItemText>
} = ({ children, sx = {} }) => {
    return <List sx={sx} disablePadding>
        {children}
    </List>;
}

const AppListItem: React.FC<IAppListItem> = ({ children, sx }) => {
    return <ListItem sx={{ display: "block", ...sx }}>
        {children}
    </ListItem>
}

const AppListItemText: React.FC<IAppListItemText> = ({ children, sx = {} }) => {
    return <ListItemText sx={{ flex: "none", textTransform: "capitalize", ...sx }}>{children}</ListItemText>
}

AppList.AppListItem = AppListItem;
AppList.AppListItemText = AppListItemText

export default AppList;