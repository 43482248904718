import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import AppTextField from "components/common/appTextField";
import FormHeader from "components/common/formHeader";
import React, { useEffect, useState } from "react";
import { validator } from "utils/validator";
import { toast } from "react-toastify";
import { TagResponseType } from "types/tag/tagResponseType";
import {
    useCreateTagMutation,
    useUpdateTagMutation
} from "store/services/tag.service";
import { TagRequestType } from "types/tag/tagRequestType";

interface ITagForm {
    onFinish: () => void;
    defaultValues?: TagResponseType;
    title?: string;
}

const TagForm: React.FC<ITagForm> = ({ defaultValues, onFinish, title }) => {
    const isEdit = !!defaultValues;
    const [data, setData] = useState<TagRequestType>(
        isEdit
            ? {
                value: defaultValues.value,
                title: defaultValues.title,
                slug: defaultValues.slug,
                label: defaultValues.label,
            }
            : {
                value: "",
                title: "",
                slug: "",
                label: ""
            }
    );

    const [errors, setErrors] = useState<Partial<TagRequestType>>({});
    const isValid = Object.keys(errors).length === 0;
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [updateTag] = useUpdateTagMutation();
    const [createTag] = useCreateTagMutation();

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [data]);

    const validatorConfig = {
        value: {
            isRequired: {
                message: "Укажите значение"
            }
        },
        title: {
            isRequired: {
                message: "Укажите название"
            }
        },
        slug: {
            isRequired: {
                message: "Укажите понятный url"
            }
        },
        label: {
            isRequired: {
                message: "Укажите лейбл"
            }
        },
    };

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (target: {
        name: string;
        value: string;
    }) => {

        const { name, value } = target;

        setIsUpdated(true);
        setData((prevState) => ({
            ...prevState,
            [name]: value,
            slug: name === "value" || name === "slug"
                ? (value as string).replaceAll(" ", "-").toLowerCase()
                : data.slug
        }));
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsTrySubmit(true);
        const isValid = validate();
        if (!isValid) return;
        if (isEdit) {
            try {
                await updateTag({
                    id: defaultValues.id,
                    ...data
                }).unwrap();
                toast.success("Тэг успешно обновлён");
            } catch (e) {
                return toast.error("Не удалось обновить тэг");
            }
        } else {
            try {
                await createTag(data).unwrap();
                toast.success("Тэг создан");
            } catch (e) {
                return toast.error("Не удалось создать тэг");
            }
        }
        onFinish();
    };

    return (
        <>
            {title && <FormHeader title={title} />}
            <form onSubmit={handleSubmit}>
                <Grid container wrap="nowrap" alignItems="center">
                    <AppTextField
                        label="Название (рус.)"
                        name="title"
                        onChange={handleChange}
                        value={data.title}
                        placeholder="Название"
                        error={errors.title}
                    />
                    <AppTextField
                        label="Название (англ.)"
                        name="value"
                        onChange={handleChange}
                        value={data.value}
                        placeholder="Значение"
                        error={errors.value}
                    />
                    <AppTextField
                        label="Slug"
                        name="slug"
                        onChange={handleChange}
                        value={data.slug}
                        placeholder="Slug"
                        error={errors.slug}
                    />
                    <AppTextField
                        label="Лейбл"
                        name="label"
                        onChange={handleChange}
                        value={data.label}
                        placeholder="Лейбл"
                        error={errors.label}
                    />
                </Grid>
                <Grid container justifyContent="space-between" mt={1}>
                    <AppButton onClick={onFinish} variant="contained">
                        Отмена
                    </AppButton>
                    <AppButton
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={!isValid || !isUpdated}
                    >
                        Сохранить
                    </AppButton>
                </Grid>
            </form>
        </>
    );
};

export default TagForm;
