export enum FormType {
    PRODUCT_NEW = "PRODUCT_NEW",
    PRODUCT_EDIT = "PRODUCT_EDIT",
    SET_NEW = "SET_NEW",
    SET_EDIT = "SET_EDIT",
    COLOR_ENTITY_EDIT = "COLOR_ENTITY_EDIT",
    COLOR_ENTITY_NEW = "COLOR_ENTITY_NEW",
    GALLERY_EDIT = "GALLERY_EDIT",
    GALLERY_NEW = "GALLERY_NEW",
    PRODUCT_ENTITY_EDIT = "PRODUCT_ENTITY_EDIT",
    PRODUCT_ENTITY_NEW = "PRODUCT_ENTITY_NEW",
    COLOR_NEW = "COLOR_NEW",
    COLOR_EDIT = "COLOR_EDIT",
    SIZE_NEW = "SIZE_NEW",
    SIZE_EDIT = "SIZE_EDIT",
    SIZE_CHART_NEW = "SIZE_CHART_NEW",
    SIZE_CHART_EDIT = "SIZE_CHART_EDIT",
    SIZE_CHART_VALUE_NEW = "SIZE_CHART_VALUE_NEW",
    SIZE_CHART_VALUE_EDIT = "SIZE_CHART_VALUE_EDIT",
    USER_NEW = "USER_NEW",
    USER_EDIT = "USER_EDIT",
    PROMOCODE_NEW = "PROMOCODE_NEW",
    PROMOCODE_EDIT = "PROMOCODE_EDIT",
    COLLECTION_NEW = "COLLECTION_NEW",
    COLLECTION_EDIT = "COLLECTION_EDIT",
    DENSITY_NEW = "DENSITY_NEW",
    DENSITY_EDIT = "DENSITY_EDIT",
    TAG_EDIT = "TAG_EDIT",
    TAG_NEW = "TAG_NEW",
    DELIVERY_NEW = "DELIVERY_NEW",
    DELIVERY_EDIT = "DELIVERY_EDIT",
    CATEGORY_NEW = "CATEGORY_NEW",
    CATEGORY_EDIT = "CATEGORY_EDIT",
    ORDER_EDIT = "ORDER_EDIT",
    ORDER_CHECK_OUT = "ORDER_CHECK_OUT"
}
