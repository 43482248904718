import React, { SyntheticEvent } from "react";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { PromocodeResponseType } from "types/promocode/promocodeResponseType";
import { useNavigate } from "react-router-dom";

interface IPromocodesDataGrid {
    promocodes: PromocodeResponseType[];
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
}

const PromocodesDataGrid: React.FC<IPromocodesDataGrid> = ({
    promocodes,
    onEdit,
    onDelete
}) => {
    const navigate = useNavigate();
    const rows: GridRowsProp = promocodes.map((c) => ({
        id: c.id,
        col1: c.value
    }));

    const handleEdit = (e: SyntheticEvent<Element>, promocodeId: number) => {
        e.stopPropagation();
        onEdit(promocodeId);
    }

    const handleDelete = (e: SyntheticEvent<Element>, promocodeId: number) => {
        e.stopPropagation();
        onDelete(promocodeId);
    }

    const columns: GridColDef[] = [
        { field: "col1", headerName: "Промокод", flex: 1, minWidth: 200 },
        {
            field: "col2",
            headerName: "",
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Grid justifyContent="end" container>
                        <AppButton
                            icon={<EditIcon />}
                            tooltip="Редактировать"
                            onClick={(e) => handleEdit(e, params.row.id)}
                        />
                        <AppButton
                            icon={<DeleteIcon />}
                            tooltip="Удалить"
                            onClick={(e) => handleDelete(e, params.row.id)}
                        />
                    </Grid>
                );
            }
        }
    ];

    return (
        <DataGrid
            disableRowSelectionOnClick
            autoHeight
            sx={{
                height: "500px",
                "& .MuiDataGrid-cell:focus": {
                    outline: "none"
                },
                "& .MuiDataGrid-cell:focus-within": {
                    outline: "none"
                }
            }}
            rows={rows}
            columns={columns}
            onRowClick={(e) =>
                navigate(`/promocodes/${e.id}`)
            }
        />
    );
};

export default PromocodesDataGrid;
