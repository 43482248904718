import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

interface IInitialState {
    accessToken: string | null;
}

const initialState: IInitialState = {
    accessToken: null
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthData: (
            state,
            { payload: { accessToken } }: PayloadAction<{ accessToken: string }>
        ) => {
            state.accessToken = accessToken;
        },
        logOut: () => initialState
    }
});

export const getIsLoggedIn = (state: RootState) => !!state.auth.accessToken;

export const { setAuthData, logOut } = authSlice.actions;

export default authSlice.reducer;
