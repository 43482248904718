import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import AppTextField from "components/common/appTextField";
import FormHeader from "components/common/formHeader";
import React, { useEffect, useState } from "react";
import { validator } from "utils/validator";
import { toast } from "react-toastify";
import { SizeChartResponseType } from "types/sizeChart/sizeChartResponseType";
import { SizeChartRequestType } from "types/sizeChart/sizeChartRequestType";
import { useCreateSizeChartMutation, useUpdateSizeChartMutation } from "store/services/sizeCharts.service";
import CategoriesMultiSelect from "./selects/categoriesMultiSelect";
import AppTextArea from "components/common/appTextArea";

interface ISizeChartForm {
    onFinish: () => void;
    defaultValues?: SizeChartResponseType;
    title?: string;
}

const SizeChartForm: React.FC<ISizeChartForm> = ({ defaultValues, onFinish, title }) => {
    const isEdit = !!defaultValues;
    const [data, setData] = useState<SizeChartRequestType>(
        isEdit
            ? {
                title: defaultValues.title,
                description: defaultValues?.description || "",
                categories: defaultValues.categories.map(c => c.id.toString()),
            }
            : {
                title: "",
                description: "",
                categories: [],
            }
    );

    const [errors, setErrors] = useState<Partial<SizeChartRequestType>>({});
    const isValid = Object.keys(errors).length === 0;
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [updateSizeChart] = useUpdateSizeChartMutation();
    const [createSizeChart] = useCreateSizeChartMutation();

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [data]);

    const validatorConfig = {
        value: {
            isRequired: {
                message: "Укажите размер"
            }
        }
    };

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (target: { name: string; value: string | string[] }) => {
        setIsUpdated(true);
        setData((prevState) => ({
            ...prevState,
            [target.name]: target.value
        }));
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsTrySubmit(true);
        const isValid = validate();
        if (!isValid) return;
        if (isEdit) {
            try {
                await updateSizeChart({
                    id: defaultValues.id,
                    ...data
                }).unwrap();
                toast.success("Таблица размеров успешно обновлена");
            } catch (e) {
                return toast.error("Не удалось обновить таблицу размеров");
            }
        } else {
            try {
                await createSizeChart(data).unwrap();
                toast.success("Таблица размеров создана");
            } catch (e) {
                return toast.error("Не удалось создать таблицу размеров");
            }
        }
        onFinish();
    };

    return (
        <>
            {title && <FormHeader title={title} />}
            <form onSubmit={handleSubmit}>
                <Grid container flexDirection="column" alignItems="center">
                    <AppTextField
                        name="title"
                        onChange={handleChange}
                        value={data.title}
                        placeholder="Название таблицы"
                        error={errors.title}
                    />
                    <AppTextArea
                        name="description"
                        onChange={handleChange}
                        value={data.description}
                        placeholder="Описание"
                        error={errors.description}
                    />
                    <CategoriesMultiSelect
                        label="Категории"
                        name="categories"
                        value={data.categories}
                        onChange={handleChange}
                        clearable
                    />
                </Grid>
                <Grid container justifyContent="space-between" mt={1}>
                    <AppButton onClick={onFinish} variant="contained">
                        Отмена
                    </AppButton>
                    <AppButton
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={!isValid || !isUpdated}
                    >
                        Сохранить
                    </AppButton>
                </Grid>
            </form>
        </>
    );
};

export default SizeChartForm;
