import React from 'react';
import { DragEvent, useRef } from "react";
import { ColorEntityResponseType } from "types/colorEntity/colorEntityResponseType";
import _ from "lodash";
import { GalleryResponseType } from 'types/gallery/galleryResponseType';
import { Tooltip } from '@mui/material';
import { ColorResponseType } from 'types/color/colorResponseType';

interface IAppDraggableList {
    items: ColorEntityResponseType[] | GalleryResponseType[];
    currentItem: ColorEntityResponseType | GalleryResponseType | undefined;
    onDrop: (data: { sourceId: number; destinationId: number }) => void;
    onChooseItem: (itemId: number) => void;
    renderComponent: React.FC<{ src: string }>,
    style?: Record<string, unknown>,
    activeStyle?: Record<string, unknown>
    srcPath: string
}

const AppDraggableList: React.FC<IAppDraggableList> = ({
    items,
    onDrop,
    onChooseItem,
    renderComponent: RenderComponent,
    currentItem,
    activeStyle,
    style,
    srcPath }) => {
    const sourceItem = useRef<number | null>();
    const destinationItem = useRef<number | null>();

    const dragStart = (e: DragEvent<HTMLDivElement>, id: number) => {
        sourceItem.current = id;
        e.dataTransfer.effectAllowed = "link";
    };

    const dragEnter = (e: DragEvent<HTMLDivElement>, id: number) => {
        destinationItem.current = id;
    };

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const drop = async () => {
        if (sourceItem.current && destinationItem.current) {
            onDrop({
                sourceId: sourceItem.current,
                destinationId: destinationItem.current
            });
        }
    };

    return <>
        {items.map((item) => (
            <Tooltip title={"color" in item
                ? item.color.title + " | " + item.color.value
                : ""}
                key={item.id}>
                <div
                    key={item.id}
                    onClick={() => onChooseItem(item.id)}
                    style={item.id === currentItem?.id ? { ...style, ...activeStyle } : { ...style }}
                    draggable
                    onDragStart={(e) => dragStart(e, item.id)}
                    onDragEnter={(e) => dragEnter(e, item.id)}
                    onDragOver={e => handleDragOver(e)}
                    onDragEnd={drop}
                >
                    <RenderComponent src={_.get(item, srcPath)} />
                </div>
            </Tooltip>
        ))}
    </>
}

export default AppDraggableList;