import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import AppFileField from "components/common/appFileField";
import FormHeader from "components/common/formHeader";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
    useCreateGalleriesMutation,
    useUpdateGalleryMutation
} from "store/services/gallery.service";
import { GalleryResponseType } from "types/gallery/galleryResponseType";
import { validator } from "utils/validator";

interface IData {
    gallery: FileList | null;
}

interface IGalleryForm {
    onFinish: () => void;
    defaultValues: GalleryResponseType | { colorEntityId: number };
    title?: string;
}

const GalleryForm: React.FC<IGalleryForm> = ({
    defaultValues,
    onFinish,
    title
}) => {
    const isEdit = "id" in defaultValues;
    const [data, setData] = useState<IData>({
        gallery: null
    });
    const [errors, setErrors] = useState<{
        gallery?: string;
    }>({});
    const isValid = Object.keys(errors).length === 0;
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const [createGallery] = useCreateGalleriesMutation();
    const [updateGallery] = useUpdateGalleryMutation();
    const [isUpdated, setIsUpdated] = useState<boolean>(false);

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [data]);

    const handleChange = (target: { name: string; value: FileList }) => {
        setIsUpdated(true);
        setData((prevState) => ({
            ...prevState,
            [target.name]: target.value
        }));
    };

    const validatorConfig = {
        gallery: {
            isRequired: {
                message: "Загрузите файлы для галереи"
            }
        }
    };

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsTrySubmit(true);
        const isValid = validate();
        if (!isValid) return;
        const formData = new FormData();
        if (data.gallery) {

            for (const media of Array.from(data.gallery)) {
                formData.append("gallery", media as Blob);
            }
            if (isEdit) {
                try {
                    await updateGallery({
                        id: defaultValues.id,
                        payload: formData
                    }).unwrap();
                    toast.success("Фото обновлено");
                } catch (e) {
                    return toast.error("Не удалось обновить фото");
                }
            } else {
                try {
                    Object.keys(defaultValues).forEach(key => {
                        const typedKey = key as keyof typeof defaultValues
                        formData.append(key, defaultValues[typedKey].toString())
                    })
                    await createGallery(formData).unwrap();
                    toast.success("Фото добалены в галерею");
                } catch (e) {
                    return toast.error("Не удалось добавить фото в галерею");
                }
            }
        }
        onFinish();
    };

    return (
        <>
            {title && <FormHeader title={title} />}
            <form onSubmit={handleSubmit}>
                <Grid container wrap="nowrap" alignItems="center">
                    <AppFileField
                        name="gallery"
                        accept="image/*"
                        multiple={!isEdit}
                        error={errors.gallery}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid container justifyContent="space-between" mt={1}>
                    <AppButton onClick={onFinish} variant="contained">
                        Отмена
                    </AppButton>
                    <AppButton
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={!isValid || !isUpdated}
                    >
                        Далее
                    </AppButton>
                </Grid>
            </form>
        </>
    );
};

export default GalleryForm;
