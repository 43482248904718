import React, { useState } from "react";
import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import AddIcon from "@mui/icons-material/Add";
import AppModal from "components/common/appModal";
import { FormType } from "enums/formType";
import { useConfirm } from "material-ui-confirm";
import { toast } from "react-toastify";
import AppH4 from "components/common/appH4";
import { useDeletePromocodeMutation, useGetPromocodesQuery } from "store/services/promocode.service";
import { PromocodeResponseType } from "types/promocode/promocodeResponseType";
import PromocodesDataGrid from "components/ui/promocodesDataGrid";
import PromocodeForm from "components/ui/forms/promocodeForm";

const PromocodesPage: React.FC = () => {
    const { data: promocodes } = useGetPromocodesQuery();
    const [currentPromocode, setPromocode] = useState<PromocodeResponseType>();
    const confirm = useConfirm();
    const [deletePromocode] = useDeletePromocodeMutation();

    const [form, setForm] = useState<string>("");
    const [open, setOpen] = useState(false);

    const handleChooseForm = (form: string) => {
        setForm(form);
        setOpen(true);
    };

    const handlePromocodeEdit = (promocodeId: number) => {
        const promocode = promocodes?.find((promocode) => promocode.id === promocodeId);
        if (promocode) {
            setPromocode(promocode);
            handleChooseForm(FormType.PROMOCODE_EDIT);
        }
    };

    const handlePromocodeDelete = (promocodeId: number) => {
        confirm({
            title: "Удаление промокода",
            description: "Вы действительно хотите удалить этот промокод?",
            confirmationText: "Удалить",
            cancellationText: "Отмена"
        })
            .then(async () => {
                await deletePromocode(promocodeId).unwrap();
                setPromocode(undefined);
                toast.success("Промокод удалён");
            })
            .catch((e) => {
                if (e) {
                    toast.error("Не удалось удалить промокод");
                }
            });
    };

    const handleFinish = () => {
        setOpen(false);
    };

    return (
        <>
            <AppH4>Промокоды</AppH4>
            <Grid>
                <Grid container justifyContent="end" mb={1}>
                    <AppButton
                        icon={<AddIcon />}
                        tooltip="Создать промокод"
                        onClick={() => handleChooseForm(FormType.PROMOCODE_NEW)}
                    />
                </Grid>
                {promocodes && (
                    <PromocodesDataGrid
                        promocodes={promocodes}
                        onEdit={handlePromocodeEdit}
                        onDelete={handlePromocodeDelete}
                    />
                )}
                <AppModal open={open}>
                    {form === FormType.PROMOCODE_NEW && (
                        <PromocodeForm onFinish={handleFinish} />
                    )}
                    {form === FormType.PROMOCODE_EDIT && (
                        <PromocodeForm
                            onFinish={handleFinish}
                            defaultValues={currentPromocode}
                        />
                    )}
                </AppModal>
            </Grid>
        </>
    );
};

export default PromocodesPage;
