import * as React from "react";
import { DataGrid, GridColDef, GridRowsProp, GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { OrderResponseType } from "types/order/orderResponseType";
import Status from "./status";

import { useGetStatusesQuery } from "store/services/status.service";
import NewTag from "./newTag";

interface IOrdersDataGrid {
    orders: OrderResponseType[];
}

const OrdersDataGrid: React.FC<IOrdersDataGrid> = ({ orders }) => {
    const navigate = useNavigate();
    const { statusesTitles } = useGetStatusesQuery(undefined, {
        selectFromResult: (({ data }) => ({
            statusesTitles: data ? data.map(s => s.title) : []
        }))
    });

    const rows: GridRowsProp = orders.map((o) => ({
        id: o.id,
        col1: o.orderNumber,
        col2: new Date(o.createdAt).toLocaleString(),
        col3: o.status.title,
        col4: o.deletedAt
    })) as GridRowsProp;

    const columns: GridColDef[] = [
        {
            field: "col1", headerName: "Номер заказа", flex: 1, minWidth: 200, filterable: false, renderCell: (data) =>
                <>
                    {data.value} {data.row.col3.toLowerCase() === "оплачен" && <NewTag />}
                </>
        },
        { field: "col2", headerName: "Создан", flex: 1, minWidth: 250, filterable: false },
        {
            field: "col3", headerName: "Статус", flex: 1, minWidth: 250, renderCell: ({ value }) =>
                <Status statusTitle={value} border />, type: "singleSelect", valueOptions: () => statusesTitles
        },
        { field: "col4", headerName: "Удалён", flex: 1, minWidth: 250 },
    ];

    return (
        <DataGrid
            localeText={{
                toolbarFilters: "Фильтры",
            }}
            disableRowSelectionOnClick
            autoHeight
            sx={{
                height: "500px",
                cursor: "pointer",
                "& .MuiDataGrid-cell:focus": {
                    outline: "none"
                }
            }}
            rows={rows}
            columns={columns}
            onRowClick={(e) =>
                navigate(`/orders/${e.id}`)
            }
            slots={{
                toolbar: () => <GridToolbarContainer sx={{ justifyContent: "end" }}>
                    <GridToolbarQuickFilter />
                    <GridToolbarFilterButton />
                </GridToolbarContainer>,
            }}
            getRowClassName={(params) => params.row.col4 ? "softDeleted" : ""}
        />
    );
};

export default OrdersDataGrid;
