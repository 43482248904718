import React, { useState } from "react";
import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import AddIcon from "@mui/icons-material/Add";
import AppModal from "components/common/appModal";
import { FormType } from "enums/formType";
import { useConfirm } from "material-ui-confirm";
import { toast } from "react-toastify";
import { useGetSizeChartQuery } from "store/services/sizeCharts.service";
import { useParams } from "react-router-dom";
import { useDeleteSizeChartValueMutation } from "store/services/sizeChartValues.service";
import { SizeChartValueResponseType } from "types/sizeChartValue/sizeChartValueResponseType";
import SizeChartValuesDataGrid from "components/ui/sizesChartValuesGrid";
import SizeChartValueForm from "components/ui/forms/sizeChartValueForm";
import AppH4 from "components/common/appH4";

const SizeChartPage: React.FC = () => {
    const { sizeChartId } = useParams();
    const { data: sizeChart } = useGetSizeChartQuery(sizeChartId as string);
    const [currentSizeChartValue, setCurrentSizeChartValue] = useState<SizeChartValueResponseType>();
    const confirm = useConfirm();
    const [deleteSizeChartValue] = useDeleteSizeChartValueMutation();

    const [form, setForm] = useState<string>("");
    const [open, setOpen] = useState(false);

    const handleChooseForm = (form: string) => {
        setForm(form);
        setOpen(true);
    };

    const handleSizeChartEdit = (sizeChartValueId: number) => {
        const sizeChartValue = sizeChart?.sizeChartValues.find((sizeChartValue) => sizeChartValue.id === sizeChartValueId);
        if (sizeChartValue) {
            setCurrentSizeChartValue(sizeChartValue);
            handleChooseForm(FormType.SIZE_CHART_VALUE_EDIT);
        }
    };

    const handleSizeChartDelete = (sizeChartValueId: number) => {
        confirm({
            title: "Удаление значения таблицы размеров",
            description: "Вы действительно хотите удалить это значение таблицы размеров?",
            confirmationText: "Удалить",
            cancellationText: "Отмена"
        })
            .then(async () => {
                await deleteSizeChartValue(sizeChartValueId).unwrap();
                setCurrentSizeChartValue(undefined);
                toast.success("Значение удалено");
            })
            .catch((e) => {
                if (e) {
                    toast.error("Не удалось удалить значение из таблицы размеров");
                }
            });
    };

    const handleFinish = () => {
        setOpen(false);
    };

    if (!sizeChart) return null;

    return (
        <>
            <AppH4>{sizeChart.title}</AppH4>
            <Grid>
                <Grid container justifyContent="end" mb={1}>
                    <AppButton
                        icon={<AddIcon />}
                        tooltip="Создать таблицу размеров"
                        onClick={() => handleChooseForm(FormType.SIZE_CHART_VALUE_NEW)}
                    />
                </Grid>
                {sizeChart && (
                    <SizeChartValuesDataGrid
                        sizeChartValues={sizeChart.sizeChartValues}
                        onEdit={handleSizeChartEdit}
                        onDelete={handleSizeChartDelete}
                    />
                )}
                <AppModal open={open}>
                    {form === FormType.SIZE_CHART_VALUE_NEW && (
                        <SizeChartValueForm onFinish={handleFinish} defaultValues={{ sizeChartId: sizeChart.id }} />
                    )}
                    {form === FormType.SIZE_CHART_VALUE_EDIT && (
                        <SizeChartValueForm
                            onFinish={handleFinish}
                            defaultValues={currentSizeChartValue as SizeChartValueResponseType}
                        />
                    )}
                </AppModal>
            </Grid>
        </>
    );
};

export default SizeChartPage;
