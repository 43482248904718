import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import { appBaseQueryWithReauth } from "./http.service";
import { SetResponseType } from "types/set/setResponseType";

const setEndpoint = config.apiEndpoint + "/set";

export const setApi = createApi({
    reducerPath: "setApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: setEndpoint }),
    tagTypes: ["Set"],
    endpoints: (builder) => ({
        getAllSets: builder.query<SetResponseType[], void>({
            query: () => ({
                url: "/all",
                method: "GET"
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.map(({ id }) => ({
                              type: "Set" as const,
                              id
                          })),
                          "Set"
                      ]
                    : ["Set"]
        }),
        getSets: builder.query<SetResponseType[], string>({
            query: (categoryId) => ({
                url: `/list?categoryId=${categoryId}`
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.map(({ id }) => ({
                              type: "Set" as const,
                              id
                          })),
                          "Set"
                      ]
                    : ["Set"]
        }),
        getSet: builder.query<SetResponseType, string>({
            query: (id) => ({
                url: `?id=${id}`
            }),
            providesTags: ["Set"]
        }),
        createSet: builder.mutation<SetResponseType, FormData>({
            query: (payload) => ({
                url: "/",
                method: "POST",
                data: payload
            }),
            invalidatesTags: ["Set"]
        }),
        updateSet: builder.mutation<
            SetResponseType,
            { id: string; payload: FormData }
        >({
            query: ({ id, payload }) => ({
                url: `/${id}`,
                method: "PATCH",
                data: payload
            }),
            invalidatesTags: ["Set"]
        }),
        deleteSet: builder.mutation<number, number>({
            query: (id) => ({
                url: `/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["Set"]
        })
    })
});

export const {
    useCreateSetMutation,
    useLazyGetSetsQuery,
    useGetSetsQuery,
    useGetSetQuery,
    useLazyGetSetQuery,
    useUpdateSetMutation,
    useDeleteSetMutation,
    useGetAllSetsQuery
} = setApi;

export const { getSets, getSet } = setApi.endpoints;
