import React from 'react';

const NewTag = () => {
    return <sup style={{
        fontSize: 10,
        backgroundColor: "red",
        color: "white",
        padding: "3px 5px",
        borderRadius: 10,
        marginLeft: 10
    }}>
        Новый
    </sup>
}

export default NewTag;