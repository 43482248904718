import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";

const AppHeader = () => {
    return (
        <AppBar position="fixed" sx={{ zIndex: 1210 }}>
            <Toolbar>
                <Typography variant="h6" noWrap>
                    LUBÍN
                </Typography>
            </Toolbar>
        </AppBar>
    );
};

export default AppHeader;
