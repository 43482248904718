import React from "react";
import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import AppIconButton from "components/common/appButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirm } from "material-ui-confirm";
import { ProductResponseType } from "types/product/productResponseType";
import { FormType } from "enums/formType";
import AppLink from "components/common/appLink";

interface IProductCard {
    product: ProductResponseType;
    onFormChoose: (form: string) => void;
    onDelete: () => void;
}

const ProductCard: React.FC<IProductCard> = ({
    product,
    onFormChoose,
    onDelete
}) => {
    const confirm = useConfirm();

    return (
        <Card sx={{ mb: 1 }}>
            <CardHeader
                sx={{ float: "right" }}
                action={
                    <Grid container justifyContent="end" mb={1}>
                        <AppIconButton
                            icon={<EditIcon />}
                            tooltip="Редактировать общее описание"
                            onClick={() => onFormChoose(FormType.PRODUCT_EDIT)}
                        />
                        <AppIconButton
                            icon={<DeleteIcon />}
                            tooltip="Удалить продукт"
                            onClick={() =>
                                confirm({
                                    title: "Удаление продукта",
                                    description:
                                        "Вы действительно хотите удалить этот продукт?",
                                    confirmationText: "Удалить",
                                    cancellationText: "Отмена"
                                })
                                    .then(() => onDelete())
                                    .catch(() => undefined)
                            }
                        />
                    </Grid>
                }
            />
            <CardContent>
                <Typography
                    variant="h6"
                    sx={{ textTransform: "uppercase" }}
                    gutterBottom
                >
                    {product.title}
                </Typography>
                <Typography
                    variant="caption"
                    sx={{ textTransform: "uppercase" }}
                >
                    {product.collection.value}
                </Typography>
                {product.setId && <Typography
                    variant="body2"
                    sx={{ textTransform: "uppercase", my: "10px" }}
                >
                    Входит в <AppLink to={`/sets/${product.setId}`}> {product.productSet.title} {product.productSet.collection.value}</AppLink>
                </Typography>}
            </CardContent>
        </Card>
    );
};

export default ProductCard;
