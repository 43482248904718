import { Button, Grid } from "@mui/material";
import React, { useRef, useState } from "react";

interface IAppFileField {
    name: string;
    accept: string;
    error?: string;
    multiple?: boolean;
    onChange: (target: { name: string; value: FileList }) => void;
}

const AppFileField: React.FC<IAppFileField> = ({
    name,
    accept,
    onChange,
    error,
    multiple = true
}) => {
    const ref = useRef<HTMLInputElement | null>(null);
    const [thumbnails, setThumbnails] = useState<string[]>([]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (multiple) {
            onChange({ name: e.target.name, value: e.target.files as FileList });
            setThumbnails((prevState) => [
                ...prevState,
                ...Array.from(e.target.files as FileList).map((img) =>
                    URL.createObjectURL(img)
                )
            ]);
        } else {
            onChange({ name: e.target.name, value: e.target.files as FileList });
            setThumbnails([
                URL.createObjectURL((e.target.files as FileList)[0])
            ]);
        }
    };

    return (
        <Grid>
            <Button onClick={() => ref.current?.click()}>
                Загрузить фото
                <input
                    name={name}
                    type="file"
                    accept={accept}
                    onChange={handleChange}
                    multiple={multiple}
                    hidden
                    ref={ref}
                />
                {error && <div style={{ color: "red" }}>{error}</div>}
            </Button>
            {thumbnails.map((thumb) => (
                <img width={50} src={thumb} alt="thumb" key={thumb} />
            ))}
        </Grid>
    );
};

export default AppFileField;
