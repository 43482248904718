import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import FormHeader from "components/common/formHeader";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
    useCreateColorEntityMutation,
    useUpdateColorEntityMutation
} from "store/services/colorEntity.service";
import { ColorEntityRequestType } from "types/colorEntity/colorEntityRequestType";
import { ColorEntityResponseType } from "types/colorEntity/colorEntityResponseType";
import { validator } from "utils/validator";
import ColorsSelect from "./selects/colorsSelect";

interface IColorForm {
    defaultValues: ColorEntityResponseType | { productId: number, position: number };
    onFinish: () => void;
    title?: string;
}

const ColorEntityForm: React.FC<IColorForm> = ({
    defaultValues,
    onFinish,
    title
}) => {
    const isEdit = "id" in defaultValues;

    const [data, setData] = useState<ColorEntityRequestType>(
        isEdit
            ? {
                colorId: defaultValues.colorId.toString(),
                productId: defaultValues.productId.toString(),
                position: defaultValues.position,
            }
            : {
                colorId: "",
                productId: defaultValues.productId.toString(),
                position: defaultValues.position,
            }
    );
    const [errors, setErrors] = useState<{
        colorId?: string;
    }>({});
    const [createColorEntity] = useCreateColorEntityMutation();
    const [updateColorEntity] = useUpdateColorEntityMutation();
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const isValid = Object.keys(errors).length === 0;
    const [isUpdated, setIsUpdated] = useState<boolean>(false);

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [data]);

    const validatorConfig = {
        colorId: {
            isRequired: {
                message: "Выберите цвет"
            }
        }
    };

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (target: {
        name: string;
        value: string;
    }) => {
        setIsUpdated(true);

        setData((prevState) => ({ ...prevState, [target.name]: target.value }));

    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsTrySubmit(true);
        if (!isValid) return;
        if (isEdit) {
            try {
                const updatedColorEntity = {
                    id: defaultValues.id,
                    ...data
                };
                await updateColorEntity(updatedColorEntity).unwrap();
                toast.success("Цвет изменён");
            } catch (e) {
                return toast.error("Не удалось обновить цвет");
            }
        } else {
            try {
                await createColorEntity(data).unwrap();
                toast.success("Цвет добавлен");
            } catch (e) {
                return toast.error("Не удалось добавить цвет");
            }
        }
        onFinish();
    };

    const handleCancel = () => {
        onFinish();
    };

    return (
        <>
            {title && <FormHeader title={title} />}
            <form onSubmit={handleSubmit}>
                <Grid container wrap="nowrap" alignItems="center">
                    <ColorsSelect
                        label="Цвет"
                        name="colorId"
                        value={data.colorId}
                        onChange={handleChange}
                        error={errors.colorId}
                    />
                </Grid>
                <Grid container justifyContent="space-between" mt={1}>
                    <AppButton onClick={handleCancel} variant="contained">
                        Отмена
                    </AppButton>
                    <AppButton
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={!isValid || !isUpdated}
                    >
                        Сохранить
                    </AppButton>
                </Grid>
            </form>
        </>
    );
};

export default ColorEntityForm;
