import {
    Accordion,
    AccordionSummary,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography
} from "@mui/material";
import { Box } from "@mui/system";
import AppNavLink from "components/common/appNavLink";
import React from "react";
import { Outlet } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const drawerWidth = 240;

const MainLayout: React.FC = () => {
    return (
        <Box sx={{ display: "flex" }}>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        pt: "64px",
                        width: drawerWidth,
                        boxSizing: "border-box"
                    }
                }}
                variant="permanent"
                anchor="left"
            >
                <Divider />
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Сайт</Typography>
                    </AccordionSummary>
                    <List sx={{ textIndent: "30px" }}>
                        <ListItem disablePadding>
                            <AppNavLink to="categories">
                                <ListItemButton>
                                    <ListItemText primary="Категории" />
                                </ListItemButton>
                            </AppNavLink>
                        </ListItem>
                        <ListItem disablePadding>
                            <AppNavLink to="products">
                                <ListItemButton>
                                    <ListItemText primary="Продукты" />
                                </ListItemButton>
                            </AppNavLink>
                        </ListItem>
                        <ListItem disablePadding>
                            <AppNavLink to="sets">
                                <ListItemButton>
                                    <ListItemText primary="Комплекты" />
                                </ListItemButton>
                            </AppNavLink>
                        </ListItem>
                        <ListItem disablePadding>
                            <AppNavLink to="colors">
                                <ListItemButton>
                                    <ListItemText primary="Цвет" />
                                </ListItemButton>
                            </AppNavLink>
                        </ListItem>
                        <ListItem disablePadding>
                            <AppNavLink to="sizes">
                                <ListItemButton>
                                    <ListItemText primary="Размеры" />
                                </ListItemButton>
                            </AppNavLink>
                        </ListItem>
                        <ListItem disablePadding>
                            <AppNavLink to="sizeCharts">
                                <ListItemButton>
                                    <ListItemText primary="Таблицы размеров" />
                                </ListItemButton>
                            </AppNavLink>
                        </ListItem>
                        <ListItem disablePadding>
                            <AppNavLink to="collections">
                                <ListItemButton>
                                    <ListItemText primary="Коллекции" />
                                </ListItemButton>
                            </AppNavLink>
                        </ListItem>
                        <ListItem disablePadding>
                            <AppNavLink to="tags">
                                <ListItemButton>
                                    <ListItemText primary="Тэги" />
                                </ListItemButton>
                            </AppNavLink>
                        </ListItem>
                        <ListItem disablePadding>
                            <AppNavLink to="promocodes">
                                <ListItemButton>
                                    <ListItemText primary="Промокоды" />
                                </ListItemButton>
                            </AppNavLink>
                        </ListItem>
                        <ListItem disablePadding>
                            <AppNavLink to="users">
                                <ListItemButton>
                                    <ListItemText primary="Пользователи" />
                                </ListItemButton>
                            </AppNavLink>
                        </ListItem>
                    </List>
                </Accordion>
                <Divider />
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Инфрастуктура</Typography>
                    </AccordionSummary>
                    <List sx={{ textIndent: "30px" }}>
                        <ListItem disablePadding>
                            <AppNavLink to="orders">
                                <ListItemButton>
                                    <ListItemText primary="Заказы" />
                                </ListItemButton>
                            </AppNavLink>
                        </ListItem>
                        <ListItem disablePadding>
                            <AppNavLink to="delivery">
                                <ListItemButton>
                                    <ListItemText primary="Службы доставки" />
                                </ListItemButton>
                            </AppNavLink>
                        </ListItem>
                        <ListItem disablePadding>
                            <AppNavLink to="yml-feed">
                                <ListItemButton>
                                    <ListItemText primary="YML-Фиды" />
                                </ListItemButton>
                            </AppNavLink>
                        </ListItem>
                    </List>
                </Accordion>
                <Divider />
            </Drawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    minWidth: 0,
                    bgcolor: "background.default",
                    p: 3
                }}
            >
                <Outlet />
            </Box>
        </Box>
    );
};

export default MainLayout;
