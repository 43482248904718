import React from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { CategoryResponseType } from "types/category/categoryResponseType";

interface IAppMultiSelect {
    value: string[];
    name: string;
    label: string;
    error?: string;
    clearable?: boolean;
    onChange: (target: { name: string; value: string | string[] }) => void;
    options: Array<{ value: string; title: string, children?: CategoryResponseType[], parentId?: number | null }>;
}

const AppMultiSelect: React.FC<IAppMultiSelect> = ({
    name,
    onChange,
    label,
    error,
    value,
    options,
    clearable
}) => {
    const handleChange = (e: SelectChangeEvent<string[]>) => {
        const { value, name } = e.target;
        onChange({
            name: name,
            value:
                typeof value === "string"
                    ? value.split(",")
                    : value[value.length - 1] === ""
                        ? []
                        : value
        });
    };
    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="label">{label}</InputLabel>
                <Select
                    name={name}
                    value={value}
                    labelId="label"
                    id="demo-multiple-chip"
                    multiple
                    onChange={handleChange}
                    input={
                        <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    renderValue={(selectedIds) => (
                        <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                            {selectedIds.map((id) => {
                                const option = options.find(
                                    (o) => o.value.toString() === id
                                );
                                return <Chip key={id} label={option?.title} />;
                            })}
                        </Box>
                    )}
                >
                    {clearable && (
                        <MenuItem value="">
                            <em>Очистить</em>
                        </MenuItem>
                    )}
                    {options.map((option) => (
                        <MenuItem
                            style={{
                                paddingLeft: !option.children?.length
                                    ? "50px"
                                    : option.parentId
                                        ? "35px"
                                        : "10px"
                            }}
                            disabled={!!option.children?.length}
                            key={option.value}
                            value={option.value}>
                            {option.title}
                        </MenuItem>
                    ))}
                </Select>
                {error && <div style={{ color: "red" }}>{error}</div>}
            </FormControl>
        </div>
    );
};

export default AppMultiSelect;
