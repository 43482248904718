import React, { useEffect, useState } from 'react';
import AppH4 from 'components/common/appH4';
import CategoriesSelect, { CategoriesTreeType } from 'components/ui/forms/selects/categoriesSelect';
import AppButton from 'components/common/appButton';
import { Grid } from '@mui/material';
import { validator } from 'utils/validator';
import { useLazyGetProductsQuery } from 'store/services/product.service';
import { toast } from 'react-toastify';
import { createYmlFeedFile } from 'utils/createYmlFeed';
import { useGetCategoriesQuery } from 'store/services/category.service';
import { CategoryResponseType } from 'types/category/categoryResponseType';
import { useLazyGetSetsQuery } from 'store/services/set.service';

interface IData {
    categoryId: string
}

const YmlFeedPage = () => {
    const [data, setData] = useState<IData>({
        categoryId: ""
    });
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const [errors, setErrors] = useState<Partial<IData>>({});
    const isValid = Object.keys(errors).length === 0;
    const [getProducts] = useLazyGetProductsQuery();
    const [getSets] = useLazyGetSetsQuery();

    const { data: categories } = useGetCategoriesQuery();

    const validatorConfig = {
        categoryId: {
            isRequired: {
                message: "Выберите категорию"
            }
        },
    };

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [data]);

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (target: { name: string; value: string }) => {
        setData((prevState) => ({
            ...prevState,
            [target.name]: target.value
        }));
    };

    const handleCreateFeed = async () => {
        setIsTrySubmit(true);
        const isValid = validate();
        if (!isValid) return;

        const products = await getProducts(data.categoryId).unwrap();
        const sets = await getSets(data.categoryId).unwrap();

        if (!products?.length) {
            return toast.error("Нет продуктов в выбранной категории");
        }

        createYmlFeedFile(products, sets, categories as CategoryResponseType[], data.categoryId);
    }

    return <Grid
        container
        flexDirection="column"
        alignItems="start"
        gap={1}>
        <AppH4>Сформировать YML-Фид по категории</AppH4>
        <CategoriesSelect
            name="categoryId"
            value={data.categoryId}
            label="Категория"
            onChange={handleChange}
            clearable={false}
            error={errors.categoryId}
            treeType={CategoriesTreeType.YML_FEED}
        />
        <AppButton
            onClick={handleCreateFeed}
            variant="contained"
            disabled={!isValid}>
            Сформировать
        </AppButton>
    </Grid>;
}

export default YmlFeedPage;