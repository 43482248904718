import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import { appBaseQueryWithReauth } from "./http.service";
import { PromocodeResponseType } from "types/promocode/promocodeResponseType";
import { PromocodeRequestType } from "types/promocode/promocodeRequestType";

const promocodeEndpoint = config.apiEndpoint + "/promocode";

export const promocodeApi = createApi({
    reducerPath: "promocodeApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: promocodeEndpoint }),
    tagTypes: ["Promocode"],
    endpoints: (builder) => ({
        getPromocodes: builder.query<PromocodeResponseType[], void>({
            query: () => ({
                url: "/",
                method: "GET"
            }),
            providesTags: ["Promocode"]
        }),
        getPromocode: builder.query<PromocodeResponseType, string>({
            query: (id) => ({
                url: `/${id}`,
                method: "GET"
            }),
            providesTags: ["Promocode"]
        }),
        createPromocode: builder.mutation<
            PromocodeResponseType,
            PromocodeRequestType
        >({
            query: (payload) => ({
                url: "/",
                method: "POST",
                data: payload
            }),
            invalidatesTags: ["Promocode"]
        }),
        updatePromocode: builder.mutation<
            PromocodeResponseType,
            PromocodeRequestType
        >({
            query: ({ id, ...payload }) => ({
                url: `/${id}`,
                method: "PATCH",
                data: payload
            }),
            invalidatesTags: ["Promocode"]
        }),
        deletePromocode: builder.mutation<number, number>({
            query: (id) => ({
                url: `/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["Promocode"]
        })
    })
});

export const {
    useCreatePromocodeMutation,
    useDeletePromocodeMutation,
    useGetPromocodesQuery,
    useGetPromocodeQuery,
    useUpdatePromocodeMutation
} = promocodeApi;
