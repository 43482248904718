import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import { SizeRequestType } from "types/size/sizeRequestType";
import { SizeResponseType } from "types/size/sizeResponseType";
import { appBaseQueryWithReauth } from "./http.service";

const sizeEndpoint = config.apiEndpoint + "/size";

export const sizeApi = createApi({
    reducerPath: "sizeApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: sizeEndpoint }),
    tagTypes: ["Size"],
    endpoints: (builder) => ({
        getSizes: builder.query<SizeResponseType[], void>({
            query: () => ({
                url: "/",
                method: "GET"
            }),
            providesTags: ["Size"]
        }),
        createSize: builder.mutation<SizeResponseType, SizeRequestType>({
            query: (payload) => ({
                url: "/",
                method: "POST",
                data: payload
            }),
            invalidatesTags: ["Size"]
        }),
        updateSize: builder.mutation<SizeResponseType, SizeRequestType>({
            query: ({ id, ...payload }) => ({
                url: `/${id}`,
                method: "PATCH",
                data: payload
            }),
            invalidatesTags: ["Size"]
        }),
        deleteSize: builder.mutation<number, number>({
            query: (id) => ({
                url: `/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["Size"]
        }),
        reorderSizes: builder.mutation<SizeResponseType[], number[]>({
            query: (ids) => ({
                url: `/reorder/${ids}`,
                method: "PATCH"
            }),
            invalidatesTags: ["Size"]
        })
    })
});

export const {
    useGetSizesQuery,
    useCreateSizeMutation,
    useUpdateSizeMutation,
    useDeleteSizeMutation,
    useReorderSizesMutation
} = sizeApi;
