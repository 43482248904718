import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import { appBaseQueryWithReauth } from "./http.service";
import { OrderResponseType } from "types/order/orderResponseType";
import { OrderRequestType } from "types/order/orderRequestType";

const orderEndpoint = config.apiEndpoint + "/order";

export const orderApi = createApi({
    reducerPath: "orderApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: orderEndpoint }),
    tagTypes: ["Order"],
    endpoints: (builder) => ({
        getOrders: builder.query<OrderResponseType[], void>({
            query: () => ({
                url: `/all`
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.map(({ id }) => ({
                              type: "Order" as const,
                              id
                          })),
                          "Order"
                      ]
                    : ["Order"]
        }),
        getOrder: builder.query<OrderResponseType, string>({
            query: (orderId) => ({
                url: `/${orderId}?paranoid`
            }),
            providesTags: ["Order"]
        }),
        updateOrder: builder.mutation<OrderResponseType, OrderRequestType>({
            query: (payload) => ({
                url: `/${payload.id}`,
                method: "PATCH",
                data: payload
            }),
            invalidatesTags: ["Order"]
        })
    })
});

export const { useGetOrdersQuery, useGetOrderQuery, useUpdateOrderMutation } =
    orderApi;
