import React from "react";
import { useGetOrdersQuery } from "store/services/order.service";
import OrdersDataGrid from "components/ui/ordersDataGrid";
import AppH4 from "../common/appH4";

const OrdersPage: React.FC = () => {
    const { data: orders } = useGetOrdersQuery();

    if (!orders) return null

    return <>
        <AppH4>Заказы</AppH4>
        <OrdersDataGrid orders={orders} />

    </>
};

export default OrdersPage;
