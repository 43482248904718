import React, { DragEvent, useRef } from "react";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { SizeResponseType } from "types/size/sizeResponseType";
import { useReorderSizesMutation } from "store/services/size.service";

interface ISizesDataGrid {
    sizes: SizeResponseType[];
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
}

const SizesDataGrid: React.FC<ISizesDataGrid> = ({
    sizes,
    onEdit,
    onDelete
}) => {
    const sourceItem = useRef<number | null>();
    const destinationItem = useRef<number | null>();
    const [reorderSizes] = useReorderSizesMutation();

    const rows: GridRowsProp = sizes.map((c) => ({
        id: c.id,
        col1: c.value
    }));

    const columns: GridColDef[] = [
        { field: "col1", headerName: "Размер", flex: 1, minWidth: 200 },
        {
            field: "col2",
            headerName: "",
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Grid justifyContent="end" container>
                        <AppButton
                            icon={<EditIcon />}
                            tooltip="Редактировать"
                            onClick={() => onEdit(params.row.id)}
                        />
                        <AppButton
                            icon={<DeleteIcon />}
                            tooltip="Удалить"
                            onClick={() => onDelete(params.row.id)}
                        />
                    </Grid>
                );
            }
        }
    ];

    const handleDragStart = (e: DragEvent<HTMLDivElement>) => {
        const size = sizes.find(s => s.id.toString() === e.currentTarget.dataset.id);
        if (size) {
            sourceItem.current = size.id;
            e.dataTransfer.effectAllowed = "link";
        }
    };

    const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
        const size = sizes.find(s => s.id.toString() === e.currentTarget.dataset.id);
        if (size) {
            destinationItem.current = size.id;
        }
    };

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragEnd = async () => {
        if (sourceItem.current && destinationItem.current) {
            try {
                await reorderSizes(
                    [sourceItem.current, destinationItem.current])
                    .unwrap();
            } catch (e: any) {
                console.log(e.message)
            }
        }
    };


    return (
        <DataGrid
            disableRowSelectionOnClick
            autoHeight
            sx={{
                height: "500px",
                "& .MuiDataGrid-cell:focus": {
                    outline: "none"
                },
                "& .MuiDataGrid-cell:focus-within": {
                    outline: "none"
                }
            }}
            rows={rows}
            columns={columns}
            slotProps={{
                row: {
                    draggable: true,
                    onDragStart: handleDragStart,
                    onDragEnter: handleDragEnter,
                    onDragOver: handleDragOver,
                    onDragEnd: handleDragEnd
                }
            }}
        />
    );
};

export default SizesDataGrid;
