import React from "react";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { SizeResponseType } from "types/size/sizeResponseType";
import { UserResponseType } from "types/user/userResponseType";

interface IUsersDataGrid {
    users: UserResponseType[];
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
}

const UsersDataGrid: React.FC<IUsersDataGrid> = ({
    users,
    onEdit,
    onDelete
}) => {
    const rows: GridRowsProp = users.map((c) => ({
        id: c.id,
        col1: c.email
    }));

    const columns: GridColDef[] = [
        { field: "col1", headerName: "email", flex: 1, minWidth: 200 },
        {
            field: "col2",
            headerName: "",
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Grid justifyContent="end" container>
                        <AppButton
                            icon={<EditIcon />}
                            tooltip="Редактировать"
                            onClick={() => onEdit(params.row.id)}
                        />
                        <AppButton
                            icon={<DeleteIcon />}
                            tooltip="Удалить"
                            onClick={() => onDelete(params.row.id)}
                        />
                    </Grid>
                );
            }
        }
    ];

    return (
        <DataGrid
            disableRowSelectionOnClick
            autoHeight
            sx={{
                height: "500px",
                "& .MuiDataGrid-cell:focus": {
                    outline: "none"
                },
                "& .MuiDataGrid-cell:focus-within": {
                    outline: "none"
                }
            }}
            rows={rows}
            columns={columns}
        />
    );
};

export default UsersDataGrid;
