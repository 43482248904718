import React from "react";
import { Checkbox, Stack } from "@mui/material";
import AppFieldLabel from "./appFieldLabel";

interface IAppCheckBoxField {
    checked: boolean;
    name: string;
    onChange: (target: { name: string; value: boolean }) => void;
    error?: string;
    label?: string;
}

const AppCheckBoxField: React.FC<IAppCheckBoxField> = ({
    checked,
    name,
    onChange,
    error,
    label
}) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ name: e.target.name, value: e.target.checked });
    };

    return (
        <Stack sx={{ width: "fit-content" }}>
            {label && <AppFieldLabel>{label}:</AppFieldLabel>}
            <Checkbox
                size="small"
                checked={checked}
                name={name}
                onChange={handleChange}
            />
            {error && <div style={{ color: "red" }}>{error}</div>}
        </Stack>
    );
};

export default AppCheckBoxField;
