import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import { TagRequestType } from "types/tag/tagRequestType";
import { TagResponseType } from "types/tag/tagResponseType";
import { appBaseQueryWithReauth } from "./http.service";

const tagEndpoint = config.apiEndpoint + "/tag";

export const tagApi = createApi({
    reducerPath: "tagApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: tagEndpoint }),
    tagTypes: ["Tag"],
    endpoints: (builder) => ({
        getTags: builder.query<TagResponseType[], void>({
            query: () => ({
                url: "/",
                method: "GET"
            }),
            providesTags: ["Tag"]
        }),
        createTag: builder.mutation<TagResponseType, TagRequestType>({
            query: (payload) => ({
                url: "/",
                method: "POST",
                data: payload
            }),
            invalidatesTags: ["Tag"]
        }),
        updateTag: builder.mutation<TagResponseType, TagResponseType>({
            query: ({ id, ...payload }) => ({
                url: `/${id}`,
                method: "PATCH",
                data: payload
            }),
            invalidatesTags: ["Tag"]
        }),
        deleteTag: builder.mutation<number, number>({
            query: (id) => ({
                url: `/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["Tag"]
        })
    })
});

export const {
    useGetTagsQuery,
    useCreateTagMutation,
    useUpdateTagMutation,
    useDeleteTagMutation
} = tagApi;
