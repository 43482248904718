import React from "react";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { ColorResponseType } from "types/color/colorResponseType";
import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ColorThumb from "components/common/colorThumb";

interface IColorsDataGrid {
    colors: ColorResponseType[];
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
}

const ColorsDataGrid: React.FC<IColorsDataGrid> = ({
    colors,
    onEdit,
    onDelete
}) => {
    const rows: GridRowsProp = colors.map((c) => ({
        id: c.id,
        col1: c.title,
        col2: c.value,
        col3: c.src
    }));

    const columns: GridColDef[] = [
        {
            field: "col1",
            headerName: "Цвет",
            flex: 1,
        },
        {
            field: "col2",
            headerName: "Значение",
            flex: 1,
        },
        {
            field: "col3",
            headerName: "Изображение",
            flex: 1,
            renderCell: (params) => {
                return <ColorThumb src={params.value} />;
            }
        },
        {
            field: "col4",
            headerName: "",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Grid justifyContent="end" container>
                        <AppButton
                            icon={<EditIcon />}
                            tooltip="Редактировать"
                            onClick={() => onEdit(params.row.id)}
                        />
                        <AppButton
                            icon={<DeleteIcon />}
                            tooltip="Удалить"
                            onClick={() => onDelete(params.row.id)}
                        />
                    </Grid>
                );
            }
        }
    ];

    return (
        <DataGrid
            disableRowSelectionOnClick
            autoHeight
            sx={{
                height: "500px",
                "& .MuiDataGrid-cell:focus": {
                    outline: "none"
                },
                "& .MuiDataGrid-cell:focus-within": {
                    outline: "none"
                }
            }}
            rows={rows}
            columns={columns}
        />
    );
};

export default ColorsDataGrid;
