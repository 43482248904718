import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getIsLoggedIn } from "store/slices/auth.slice";

interface IProtectedRoute {
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<IProtectedRoute> = ({ children }) => {
    const isLoggedIn = useSelector(getIsLoggedIn);

    if (!isLoggedIn) {
        return <Navigate to={"/login"} />
    }

    return <>{children}</>
    
};

export default ProtectedRoute;
