import { CategoryResponseType } from "types/category/categoryResponseType";
import { ProductResponseType } from "types/product/productResponseType";
import { config } from "../config";
import { SetResponseType } from "types/set/setResponseType";
import uniqBy from "lodash/uniqBy";

export enum DataType {
    PRODUCT = "product",
    SET = "set"
}

type OfferObjType = {
    id: number;
    name: string;
    categoryId: number;
    picture: string;
    descriptionTitle: string;
    descriptionContent: string;
    price: number;
    color: string;
    url: string;
    available: boolean;
};

const getYmlMarkup = (
    offersObjArray: OfferObjType[],
    categories: CategoryResponseType[]
) => {
    return `<?xml version="1.0" encoding="UTF-8"?>
    <yml_catalog date="${new Date().toISOString()}">
        <shop>
            <name>LUBÍN Only Love</name>
            <company>LUBÍN</company>
            <url>https://lubinonlylove.ru</url>
            <categories>
                ${categories
                    .map(
                        (c) =>
                            `<category id="${c.id}"${
                                c.parentId ? ` parentId="${c.parentId}"` : ""
                            }>${c.title}</category>`
                    )
                    .join("\n")}
            </categories>
            <delivery-options>
                <option cost="300" days="7"/>
            </delivery-options>
            <offers>
                ${offersObjArray
                    .map(
                        (
                            oo
                        ) => `<offer id="${oo.id}" available="${oo.available}">
                             <name>${oo.name}</name>
                            <vendor>LUBÍN</vendor>
                            <categoryId>${oo.categoryId}</categoryId>
                            <picture>${oo.picture}</picture>
                            <url>${oo.url}</url>
                            <description>
                                <![CDATA[
                                    <h3>${oo.descriptionTitle}</h3>
                                    <p>${oo.descriptionContent}</p>
                            ]]>
                            </description>
                            <country_of_origin>Россия</country_of_origin>
                            <price>${oo.price}</price>
                            <currencyId>RUR</currencyId>
                            <param name="Цвет">${oo.color}</param>
                        </offer>\n`
                    )
                    .join("")}
            </offers>
        </shop>
    </yml_catalog>`;
};

export const createYmlFeedFile = (
    products: ProductResponseType[] = [],
    sets: SetResponseType[] = [],
    categories: CategoryResponseType[],
    rootCategoryId: string
) => {
    const offersObjArray: OfferObjType[] = [];

    products
        .filter((p) => !p.deletedAt)
        .forEach((p) =>
            p.colorEntities.forEach(
                (ce) => {
                    const product = ce.productEntities[0];

                    offersObjArray.push({
                        id: product.id,
                        name: `${p.title} ${p.collection.title.replace(
                            /коллекция /i,
                            ""
                        )} цвет ${ce.color.title} размер ${product.size.value}`,
                        categoryId: p.categoryId,
                        picture: config.endpoint + ce.gallery[0].src,
                        descriptionTitle: `${
                            p.title
                        } ${p.collection.title.replace(
                            /коллекция /i,
                            ""
                        )} цвет ${ce.color.title} размер ${product.size.value}`,
                        descriptionContent: p.description.split(".")[0],
                        price: Number(product.price),
                        color: ce.color.title,
                        url:
                            config.siteEndpoint +
                            "catalog/" +
                            p.category.slug +
                            "/" +
                            p.collection.slug +
                            "/" +
                            ce.color.slug,
                        available: product.stock > 0
                    });
                }
                // ce.productEntities
                //     .filter((pe) => !pe.deletedAt || pe.stock > 0)
                //     .forEach((pe) => {
                //         offersObjArray.push({
                //             id: pe.id,
                //             name: `${p.title} ${p.collection.title.replace(
                //                 /коллекция /i,
                //                 ""
                //             )} цвет ${ce.color.title} размер ${
                //                 pe.size.value
                //             }`,
                //             categoryId: p.categoryId,
                //             picture: config.endpoint + ce.gallery[0].src,
                //             descriptionTitle: `${
                //                 p.title
                //             } ${p.collection.title.replace(
                //                 /коллекция /i,
                //                 ""
                //             )} цвет ${ce.color.title} размер ${
                //                 pe.size.value
                //             }`,
                //             descriptionContent: p.description.split(".")[0],
                //             price: Number(pe.price),
                //             color: ce.color.title,
                //             url:
                //                 config.siteEndpoint +
                //                 "catalog/" +
                //                 p.category.slug +
                //                 "/" +
                //                 p.collection.slug +
                //                 "/" +
                //                 ce.color.slug,
                //             available: true
                //         });
                //     })
            )
        );

    sets.forEach((s) => {
        const colors = uniqBy(
            s.products.flatMap((p) =>
                p.colorEntities.flatMap((ce) => ce.color)
            ),
            (obj) => obj.id
        );

        colors.forEach((c, index) => {
            const product: OfferObjType = {
                id: Number(`${s.id}${index}${c.id}`),
                name: `${s.title} ${s.collection.title.replace(
                    /коллекция /i,
                    ""
                )} цвет ${c.title}`,
                categoryId: s.categoryId,
                color: c.title,
                descriptionTitle: `${s.title} ${s.collection.title.replace(
                    /коллекция /i,
                    ""
                )} цвет ${c.title}`,
                descriptionContent: s.description.split("\n")[0],
                picture: "",
                price: 0,
                url:
                    config.siteEndpoint +
                    "catalog/set/" +
                    s.category.slug +
                    "/" +
                    s.collection.slug +
                    "/" +
                    c.slug,
                available: true
            };

            s.products.forEach((p) =>
                p.colorEntities.forEach((ce) => {
                    if (ce.colorId === c.id) {
                        (product.picture = config.endpoint + ce.gallery[0].src),
                            (product.price += Number(
                                ce.productEntities[0].price
                            ));
                        if (ce.productEntities[0].stock <= 0) {
                            product.available = false;
                        }
                    }
                })
            );
            offersObjArray.push(product);
        });
    });

    const rootCategory = categories.find(
        (c) => c.id.toString() === rootCategoryId
    );

    const ymlMarkup = getYmlMarkup(offersObjArray, categories);
    const element = document.createElement("a");
    const file = new Blob([ymlMarkup], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `products_${rootCategory?.slug}.xml`;
    // document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
};
