import React from "react";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { ColorEntityResponseType } from "types/colorEntity/colorEntityResponseType";
import { FormType } from "enums/formType";
import { useConfirm } from "material-ui-confirm";
import AppIconButton from "components/common/appButton";
import ColorThumb from "components/common/colorThumb";
import { useReorderColorEntitiesMutation } from "store/services/colorEntity.service";
import { toast } from "react-toastify";
import AppDraggableList from "components/common/appDraggableList";

interface IColorEntitiesCard {
    currentColorEntity?: ColorEntityResponseType;
    colorEntities: ColorEntityResponseType[];
    onColorEntityChoose: (id: number) => void;
    onFormChoose: (form: string) => void;
    onDelete: () => void;
}

const ColorEntitiesCard: React.FC<IColorEntitiesCard> = ({
    onFormChoose,
    onDelete,
    currentColorEntity,
    colorEntities,
    onColorEntityChoose
}) => {
    const confirm = useConfirm();
    const [reorderColorEntities] = useReorderColorEntitiesMutation();

    const handleReorder = async (data: { sourceId: number, destinationId: number }) => {
        const { sourceId, destinationId } = data;
        try {
            await reorderColorEntities([sourceId, destinationId]).unwrap();
        } catch (e) {
            console.log(e);
            toast.error("Не удалось отсортировать список");
        }
    }

    return (
        <Card>
            <CardHeader
                sx={{ float: "right" }}
                action={
                    <Grid container justifyContent="end" mb={1}>
                        <AppIconButton
                            icon={<AddIcon />}
                            tooltip="Добавить цвет"
                            onClick={() =>
                                onFormChoose(FormType.COLOR_ENTITY_NEW)
                            }
                        />

                        {currentColorEntity && (
                            <AppIconButton
                                icon={<EditIcon />}
                                tooltip="Редактировать цвет"
                                onClick={() =>
                                    onFormChoose(FormType.COLOR_ENTITY_EDIT)
                                }
                            />
                        )}

                        {currentColorEntity && (
                            <AppIconButton
                                icon={<DeleteIcon />}
                                tooltip="Удалить Цвет"
                                onClick={() =>
                                    confirm({
                                        description:
                                            "Вы действительно хотите удалить этот цвет?"
                                    })
                                        .then(() => onDelete())
                                        .catch(() => undefined)
                                }
                            />
                        )}
                    </Grid>
                }
            />
            <CardContent>
                <Box sx={{ display: "flex", gap: "5px", marginBottom: "25px" }}>
                    <AppDraggableList
                        items={colorEntities}
                        currentItem={currentColorEntity}
                        activeStyle={{
                            outline: "1px solid black",
                            borderRadius: "100%"
                        }}
                        onChooseItem={onColorEntityChoose}
                        onDrop={handleReorder}
                        renderComponent={ColorThumb}
                        srcPath={"color.src"}
                    />
                </Box>
            </CardContent>
        </Card>
    );
};

export default ColorEntitiesCard;
