import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import { ReceiptResponseType } from "types/payment/receiptResponseType";

const paymentEndpoint = config.apiEndpoint + "/payment";

export const paymentApi = createApi({
    reducerPath: "paymentApi",
    baseQuery: fetchBaseQuery({ baseUrl: paymentEndpoint }),
    endpoints: (builder) => ({
        createReceipt: builder.mutation<ReceiptResponseType, number>({
            query: (orderId) => ({
                url: `/receipt/${orderId}`,
                method: "POST"
            })
        })
    })
});

export const { useCreateReceiptMutation } = paymentApi;
