import React from "react";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { UserResponseType } from "types/user/userResponseType";

interface IPromocodeUsersDataGrid {
    users: UserResponseType[];
}

const PromocodeUsersDataGrid: React.FC<IPromocodeUsersDataGrid> = ({
    users,
}) => {

    const rows: GridRowsProp = users.map((u) => ({
        id: u.id,
        col1: u.id,
        col2: u.email,
    }));

    const columns: GridColDef[] = [
        { field: "col1", headerName: "id" },
        { field: "col2", headerName: "email", flex: 1, minWidth: 200 },
    ];

    return (
        <DataGrid
            disableRowSelectionOnClick
            autoHeight
            sx={{
                height: "500px",
                "& .MuiDataGrid-cell:focus": {
                    outline: "none",
                },
                "& .MuiDataGrid-cell:focus-within": {
                    outline: "none"
                },
            }}
            rows={rows}
            columns={columns}
        />
    );
};

export default PromocodeUsersDataGrid;
