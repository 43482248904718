import React, { useState } from "react";
import { Grid } from "@mui/material";
import AppButton from "components/common/appButton";
import AddIcon from "@mui/icons-material/Add";
import AppModal from "components/common/appModal";
import { FormType } from "enums/formType";
import { useConfirm } from "material-ui-confirm";
import { toast } from "react-toastify";
import { SizeResponseType } from "types/size/sizeResponseType";
import SizesDataGrid from "components/ui/sizesDataGrid";
import SizeForm from "components/ui/forms/sizeForm";
import {
    useDeleteSizeMutation,
    useGetSizesQuery
} from "store/services/size.service";
import AppH4 from "components/common/appH4";

const SizesPage: React.FC = () => {
    const { data: sizes } = useGetSizesQuery();
    const [currentSize, setCurrentSize] = useState<SizeResponseType>();
    const confirm = useConfirm();
    const [deleteSize] = useDeleteSizeMutation();

    const [form, setForm] = useState<string>("");
    const [open, setOpen] = useState(false);

    const handleChooseForm = (form: string) => {
        setForm(form);
        setOpen(true);
    };

    const handleSizeEdit = (sizeId: number) => {
        const size = sizes?.find((size) => size.id === sizeId);
        if (size) {
            setCurrentSize(size);
            handleChooseForm(FormType.SIZE_EDIT);
        }
    };

    const handleSizeDelete = (sizeId: number) => {
        confirm({
            title: "Удаление размера",
            description: "Вы действительно хотите удалить этот размер?",
            confirmationText: "Удалить",
            cancellationText: "Отмена"
        })
            .then(async () => {
                await deleteSize(sizeId).unwrap();
                setCurrentSize(undefined);
                toast.success("Размер удалён");
            })
            .catch((e) => {
                if (e) {
                    toast.error("Не удалось удалить размер");
                }
            });
    };

    const handleFinish = () => {
        setOpen(false);
    };

    return (
        <>
            <AppH4>Размеры</AppH4>
            <Grid>
                <Grid container justifyContent="end" mb={1}>
                    <AppButton
                        icon={<AddIcon />}
                        tooltip="Создать размер"
                        onClick={() => handleChooseForm(FormType.SIZE_NEW)}
                    />
                </Grid>
                {sizes && (
                    <SizesDataGrid
                        sizes={sizes}
                        onEdit={handleSizeEdit}
                        onDelete={handleSizeDelete}
                    />
                )}
                <AppModal open={open}>
                    {form === FormType.SIZE_NEW && (
                        <SizeForm onFinish={handleFinish} />
                    )}
                    {form === FormType.SIZE_EDIT && (
                        <SizeForm
                            onFinish={handleFinish}
                            defaultValues={currentSize}
                        />
                    )}
                </AppModal>
            </Grid>
        </>
    );
};

export default SizesPage;
