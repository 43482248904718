import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import { ColorEntityRequestType } from "types/colorEntity/colorEntityRequestType";
import { ColorEntityResponseType } from "types/colorEntity/colorEntityResponseType";
import { productApi } from "./product.service";
import { appBaseQueryWithReauth } from "./http.service";

const colorEntityEndpoint = config.apiEndpoint + "/color-entity";

export const colorEntityApi = createApi({
    reducerPath: "colorEntityApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: colorEntityEndpoint }),
    tagTypes: ["ColorEntity"],
    endpoints: (builder) => ({
        getColorEntity: builder.query<ColorEntityResponseType, string>({
            query: (id) => ({
                url: `/${id}`
            }),
            providesTags: (result, error, arg) => [
                { type: "ColorEntity", id: arg }
            ]
        }),
        createColorEntity: builder.mutation<
            ColorEntityResponseType,
            ColorEntityRequestType
        >({
            query: (payload) => ({
                url: "/",
                method: "POST",
                data: payload
            }),
            invalidatesTags: ["ColorEntity"],
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(productApi.util.invalidateTags(["Product"]));
            }
        }),
        updateColorEntity: builder.mutation<
            ColorEntityResponseType,
            ColorEntityRequestType
        >({
            query: ({ id, ...payload }) => ({
                url: `/${id}`,
                method: "PATCH",
                data: payload
            }),
            invalidatesTags: ["ColorEntity"],
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(productApi.util.invalidateTags(["Product"]));
            }
        }),
        deletColorEntity: builder.mutation<number, number>({
            query: (id) => ({
                url: `/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["ColorEntity"],
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(productApi.util.invalidateTags(["Product"]));
            }
        }),
        reorderColorEntities: builder.mutation<
            ColorEntityResponseType[],
            number[]
        >({
            query: (ids) => ({
                url: `/reorder/${ids}`,
                method: "PATCH"
            }),
            invalidatesTags: ["ColorEntity"],
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(productApi.util.invalidateTags(["Product"]));
            }
        })
    })
});

export const {
    useCreateColorEntityMutation,
    useGetColorEntityQuery,
    useLazyGetColorEntityQuery,
    useUpdateColorEntityMutation,
    useDeletColorEntityMutation,
    useReorderColorEntitiesMutation
} = colorEntityApi;
