import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import { appBaseQueryWithReauth } from "./http.service";
import { DeliveryResponseType } from "types/delivery/deliveryResponseType";
import { DeliveryRequestType } from "types/delivery/deliveryRequestType";

const deliveryEndpoint = config.apiEndpoint + "/delivery";

export const deliveryApi = createApi({
    reducerPath: "deliveryApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: deliveryEndpoint }),
    tagTypes: ["Delivery"],
    endpoints: (builder) => ({
        getDeliveries: builder.query<DeliveryResponseType[], void>({
            query: () => ({
                url: "/",
                method: "GET"
            }),
            providesTags: ["Delivery"]
        }),
        createDelivery: builder.mutation<
            DeliveryResponseType,
            DeliveryRequestType
        >({
            query: (payload) => ({
                url: "/",
                method: "POST",
                data: payload
            }),
            invalidatesTags: ["Delivery"]
        }),
        updateDelivery: builder.mutation<
            DeliveryResponseType,
            DeliveryRequestType
        >({
            query: ({ id, ...payload }) => ({
                url: `/${id}`,
                method: "PATCH",
                data: payload
            }),
            invalidatesTags: ["Delivery"]
        }),
        deleteDelivery: builder.mutation<number, number>({
            query: (id) => ({
                url: `/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["Delivery"]
        })
    })
});

export const {
    useGetDeliveriesQuery,
    useCreateDeliveryMutation,
    useUpdateDeliveryMutation,
    useDeleteDeliveryMutation
} = deliveryApi;
