import React from "react";
import { Stack, TextField, TextareaAutosize } from "@mui/material";
import AppFieldLabel from "./appFieldLabel";

interface IAppTextArea {
    value: string;
    name: string;
    placeholder: string;
    onChange: (target: { name: string; value: string }) => void;
    error?: string;
    label?: string;
}

const AppTextArea: React.FC<IAppTextArea> = ({
    value,
    name,
    placeholder,
    onChange,
    error,
    label
}) => {
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange({ name: e.target.name, value: e.target.value });
    };

    return (
        <Stack sx={{ width: "100%", mb: 1 }}>
            {label && <AppFieldLabel>{label}:</AppFieldLabel>}
            <TextField
                value={value}
                name={name}
                multiline
                minRows={10}
                onChange={handleChange}
                placeholder={placeholder}
            />
            {error && <div style={{ color: "red" }}>{error}</div>}
        </Stack>
    );
};

export default AppTextArea;
