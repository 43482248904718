import * as React from "react";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SetResponseType } from "types/set/setResponseType";
import { config } from "config";

interface ISetsDataGrid {
    sets: SetResponseType[];
}

const SetsDataGrid: React.FC<ISetsDataGrid> = ({ sets }) => {
    const navigate = useNavigate();
    const [search] = useSearchParams();

    const rows: GridRowsProp = sets.map((p) => ({
        id: p.id,
        col1: p.title,
        col2: p.collection.value,
        col3: p.banner
    })) as GridRowsProp;

    const columns: GridColDef[] = [
        { field: "col1", headerName: "Комплект", flex: 1, minWidth: 200 },
        { field: "col2", headerName: "Коллекция", flex: 1, minWidth: 250 },
        { field: "col3", headerName: "Баннер", flex: 1, minWidth: 250, renderCell: (params) => params.value && <img width={25} src={config.endpoint + params.value} /> }
    ];
    return (
        <DataGrid
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight
            sx={{
                height: "500px",
                cursor: "pointer",
                "& .MuiDataGrid-cell:focus": {
                    outline: "none"
                }
            }}
            rows={rows}
            columns={columns}
            onRowClick={(e) =>
                navigate(`${e.id}`, {
                    state: { categoryId: search.get("categoryId") }
                })
            }
        />
    );
};

export default SetsDataGrid;
