import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppModal from "components/common/appModal";
import { toast } from "react-toastify";
import { FormType } from "enums/formType";
import { useDeleteSetMutation, useGetSetQuery } from "store/services/set.service";
import SetCard from "components/ui/cards/setCard";
import ProductsDataGrid from "components/ui/productsDataGrid";
import SetForm from "components/ui/forms/setForm";
import AppH4 from "components/common/appH4";

const SetPage: React.FC = () => {
    const { setId } = useParams();
    const categoryId = useLocation().state?.categoryId;
    const { data: set } = useGetSetQuery(setId as string);
    const [form, setForm] = useState<string>("");
    const [open, setOpen] = useState<boolean>(false);
    const [deleteSet] = useDeleteSetMutation();
    const navigate = useNavigate();

    if (!set) return null;

    const handleChooseForm = (form: string) => {
        setForm(form);
        setOpen(true);
    };

    const handleFinish = () => {
        setOpen(false);
    };

    const handleDeleteSet = async () => {
        if (setId) {
            try {
                await deleteSet(Number(setId)).unwrap();
                toast.success("Комплект удалён");
                navigate(
                    categoryId
                        ? `/sets?categoryId=${categoryId}`
                        : "/sets"
                );
            } catch (e) {
                toast.error("Не удалось удалить комплект");
            }
        }
    };

    if (!set) return null;

    return (
        <>
            <AppH4>{set.title}</AppH4>
            <SetCard
                set={set}
                onFormChoose={handleChooseForm}
                onDelete={handleDeleteSet}
            />
            <ProductsDataGrid
                products={set.products}
            />
            <AppModal open={open}>
                {form === FormType.SET_EDIT && (
                    <SetForm
                        title="Редактировать комплект"
                        defaultValues={set}
                        onFinish={handleFinish}
                    />
                )}
            </AppModal>
        </>
    );
};

export default SetPage;
