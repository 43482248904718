import React from 'react';
import AppSelect from "components/common/appSelect";
import { useGetCollectionsQuery } from 'store/services/collection.service';

interface ICollectionsSelect {
    name: string;
    label: string;
    value: string;
    onChange: (target: { name: string; value: string }) => void;
    error?: string;
}

const CollectionsSelect: React.FC<ICollectionsSelect> = ({
    name,
    label,
    value,
    onChange,
    error
}) => {
    const { data: collections } = useGetCollectionsQuery();

    if (!collections) return null;

    return <AppSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        error={error}
    >
        {collections.map((collection) => (
            <AppSelect.AppOption
                value={collection.id.toString()}
                key={collection.id}
            >
                {collection.title} ({collection.value}) ({collection.slug})
            </AppSelect.AppOption>
        ))}
    </AppSelect>;
}

export default CollectionsSelect;