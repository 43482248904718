import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from "../../config";
import { UserResponseType } from "types/user/userResponseType";
import { appBaseQueryWithReauth } from "./http.service";
import { UserRequestType } from "types/user/userRequestType";

const userEndpoint = config.apiEndpoint + "/user";

export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: userEndpoint }),
    tagTypes: ["User"],
    endpoints: (builder) => ({
        getMe: builder.query<UserResponseType, void>({
            query: () => ({
                url: "/getMe",
                method: "GET"
            })
        }),
        createUser: builder.mutation<UserResponseType, UserRequestType>({
            query: (payload) => ({
                url: "/",
                method: "POST",
                data: payload
            }),
            invalidatesTags: ["User"]
        }),
        getUsers: builder.query<UserResponseType[], void>({
            query: () => ({
                url: "/all",
                method: "GET"
            }),
            providesTags: ["User"]
        }),
        updateUser: builder.mutation<UserResponseType, UserRequestType>({
            query: ({ id, ...payload }) => ({
                url: `/${id}`,
                method: "PATCH",
                data: payload
            }),
            invalidatesTags: ["User"]
        }),
        deleteUser: builder.mutation<number, number>({
            query: (id) => ({
                url: `/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["User"]
        })
    })
});

export const {
    useGetMeQuery,
    useLazyGetMeQuery,
    useGetUsersQuery,
    useDeleteUserMutation,
    useUpdateUserMutation,
    useCreateUserMutation
} = userApi;
