import React from "react";
import {
    FormControl,
    InputLabel,
    ListSubheader,
    MenuItem,
    Select,
    SelectChangeEvent,
    SxProps
} from "@mui/material";

interface IAppSelect {
    children: React.ReactNode;
    name: string;
    value: string;
    label: string;
    error?: string;
    clearable?: boolean;
    onChange: (target: { name: string; value: string }) => void;
}

interface IAppSelectExtensions {
    AppOptGroup: typeof AppOptGroup;
    AppOption: typeof AppOption;
}

const AppSelect: React.FC<IAppSelect> & IAppSelectExtensions = ({
    name,
    value,
    onChange,
    children,
    label,
    error,
    clearable = true
}) => {
    const handleChange = (e: SelectChangeEvent) => {
        onChange({ name: e.target.name, value: e.target.value });
    };

    return (
        <FormControl variant="filled" sx={{ m: 1, minWidth: 120, flex: 1 }}>
            <InputLabel id="label">{label}</InputLabel>
            <Select
                size="small"
                labelId="label"
                name={name}
                value={value.toString()}
                onChange={handleChange}
                fullWidth
            >
                {clearable && (
                    <AppSelect.AppOption value="">
                        <em>Очистить</em>
                    </AppSelect.AppOption>
                )}
                {children}
            </Select>
            {error && <div style={{ color: "red" }}>{error}</div>}
        </FormControl>
    );
};

interface IAppOptGroup {
    children: React.ReactNode;
    sx?: SxProps;
}

const AppOptGroup: React.FC<IAppOptGroup> = ({ children, sx }) => {
    return (
        <ListSubheader sx={{ ...sx, lineHeight: 2 }}>{children}</ListSubheader>
    );
};

interface IAppOption {
    children: React.ReactNode;
    value: string;
    sx?: SxProps;
}

const AppOption: React.FC<IAppOption> = ({
    children,
    value,
    sx,
    ...menuItemProps
}) => {
    return (
        <MenuItem sx={{ ...sx }} value={value} {...menuItemProps}>
            {children}
        </MenuItem>
    );
};

AppSelect.AppOptGroup = AppOptGroup;
AppSelect.AppOption = AppOption;

export default AppSelect;
