import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { config } from "config";
import {
    BaseQueryFn,
    FetchBaseQueryError,
    FetchBaseQueryMeta
} from "@reduxjs/toolkit/dist/query";
import { RootState } from "store";
import { logOut, setAuthData } from "store/slices/auth.slice";
import { getFingerprint } from "utils/fingerprint";

const refreshEndpoint = config.apiEndpoint + "/auth/refresh";

type AxiosBaseQueryArgsType = {
    url: string;
    method?: AxiosRequestConfig["method"];
    data?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
    withCredentials?: AxiosRequestConfig["withCredentials"];
    onUploadProgress?: AxiosRequestConfig["onUploadProgress"];
};

const appBaseQuery =
    (
        {
            baseUrl
        }: {
            baseUrl: string;
        } = { baseUrl: "" }
    ): BaseQueryFn<
        AxiosBaseQueryArgsType,
        unknown,
        FetchBaseQueryError,
        unknown,
        FetchBaseQueryMeta
    > =>
    //@ts-ignore
    async (
        {
            url = "",
            method = "GET",
            withCredentials,
            data,
            params,
            onUploadProgress
        },
        api
    ) => {
        try {
            const result = await axios({
                url: baseUrl + url,
                method,
                data,
                withCredentials,
                params,
                headers: {
                    Authorization: `Bearer ${
                        (api.getState() as RootState).auth.accessToken
                    }`
                },
                onUploadProgress
            });
            return { data: result.data };
        } catch (axiosError) {
            const err = axiosError as AxiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message
                }
            };
        }
    };

export const appBaseQueryWithReauth = ({
    baseUrl
}: {
    baseUrl: string;
}): BaseQueryFn<AxiosBaseQueryArgsType, unknown, FetchBaseQueryError> => {
    const baseQuery = appBaseQuery({
        baseUrl
    });

    return async (args, api, extraOptions) => {
        let result = await baseQuery(args, api, extraOptions);
        if (result.error && result.error.status === 401) {
            // try to get a new token
            const refreshQuery = appBaseQuery();
            const { data }: any = await refreshQuery(
                {
                    url: refreshEndpoint,
                    withCredentials: true,
                    method: "POST",
                    data: { fingerprint: getFingerprint() }
                },
                api,
                extraOptions
            );
            if (data) {
                api.dispatch(
                    setAuthData({
                        accessToken: data.accessToken
                    })
                );

                result = await baseQuery(args, api, extraOptions);
            } else {
                api.dispatch(logOut());
            }
        }
        return result;
    };
};
