import React, { useEffect, useState } from "react";
import { validator } from "utils/validator";
import AppTextField from "components/common/appTextField";
import { Box, Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FormHeader from "components/common/formHeader";
import AppButton from "components/common/appButton";
import { SetResponseType } from "types/set/setResponseType";
import { useCreateSetMutation, useUpdateSetMutation } from "store/services/set.service";
import AppTextArea from "components/common/appTextArea";
import CategoriesSelect, { CategoriesTreeMode, CategoriesTreeType } from "./selects/categoriesSelect";
import CollectionsSelect from "./selects/collectionsSelect";
import { ImageActions } from "enums/imageActions";
import AppFileField from "components/common/appFileField";
import { config } from "config";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface IData {
    categoryId: string,
    collectionId: string;
    title: string;
    description: string;
    care: string;
    banner: string | FileList | null | ImageActions.IMAGE_DELETED;
    bannerDescription: string;
}

interface ISetForm {
    onFinish: () => void;
    defaultValues?: SetResponseType;
    title?: string;
}

const SetForm: React.FC<ISetForm> = ({
    defaultValues,
    onFinish,
    title
}) => {
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const navigate = useNavigate();
    const isEdit = !!defaultValues;

    const [data, setData] = useState<IData>(
        isEdit
            ? {
                categoryId: defaultValues.categoryId.toString(),
                collectionId: defaultValues.collectionId.toString(),
                title: defaultValues.title,
                description: defaultValues.description,
                care: defaultValues.care || "",
                banner: defaultValues.banner || "",
                bannerDescription: defaultValues.bannerDescription || ""
            }
            : {
                categoryId: "",
                collectionId: "",
                title: "",
                description: "",
                care: "",
                banner: "",
                bannerDescription: ""
            }
    );

    const [errors, setErrors] = useState<Partial<Record<keyof IData, string>>>({});

    const isValid = Object.keys(errors).length === 0;
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const [updateSet] = useUpdateSetMutation();
    const [createSet] = useCreateSetMutation();
    const isOldImage = data.banner && typeof data.banner === "string" && data.banner !== ImageActions.IMAGE_DELETED;

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [data]);

    const validatorConfig = {
        categoryId: {
            isRequired: {
                message: "Выберите категорию"
            }
        },
        collectionId: {
            isRequired: {
                message: "Выберите коллекцию"
            }
        },
        title: {
            isRequired: {
                message: "Введите название комплекта"
            }
        }
    };

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (target: { name: string; value: string | FileList }) => {
        setIsUpdated(true);
        setData((prevState) => ({ ...prevState, [target.name]: target.value }));
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsTrySubmit(true);
        const isValid = validate();
        if (!isValid) return;

        const formData = new FormData();

        Object.keys(data).forEach((key) => {
            const typedKey = key as keyof IData;
            if (data[typedKey] instanceof FileList) {
                formData.append(key, data[typedKey]?.[0] as Blob);
            } else {
                formData.append(key, data[typedKey] as string);
            }
        });

        if (isEdit) {
            try {
                await updateSet({
                    id: defaultValues.id.toString(),
                    payload: formData
                }).unwrap();
                toast.success("Комплект успешно обновлён");
                onFinish();
            } catch (e) {
                toast.error("Не удалось обновить комплект");
            }
        } else {
            try {
                const { id } = await createSet(formData).unwrap();
                toast.success("Комплект создан");
                navigate(`/sets/${id}`);
            } catch (e) {
                toast.error("Не удалось создать комплект");
            }
        }
    };

    return (
        <>
            {title && <FormHeader title={title} />}
            <form onSubmit={handleSubmit}>
                <AppTextField
                    name="title"
                    onChange={handleChange}
                    value={data.title}
                    placeholder="Название"
                    error={errors.title}
                />
                <Grid container wrap="nowrap" alignItems="center">
                    <CategoriesSelect
                        name="categoryId"
                        value={data.categoryId}
                        label="Категория"
                        onChange={handleChange}
                        clearable={false}
                        error={errors.categoryId}
                        treeType={CategoriesTreeType.SET}
                        treeMode={CategoriesTreeMode.CREATE}
                    />
                    <CollectionsSelect
                        label="Коллекция"
                        name="collectionId"
                        value={data.collectionId}
                        onChange={handleChange}
                        error={errors.collectionId}
                    />
                </Grid>
                <Grid container flexDirection="column">
                    <AppTextArea
                        name="description"
                        onChange={handleChange}
                        value={data.description}
                        placeholder="Описание"
                        error={errors.description}
                    />
                    <AppTextArea
                        name="care"
                        onChange={handleChange}
                        value={data.care}
                        placeholder="Уход"
                        error={errors.care}
                    />
                </Grid>
                <Box>
                    <Typography sx={{ textTransform: "uppercase" }}>Баннер (1 x 1)</Typography>
                    <AppFileField
                        name="banner"
                        accept="image/*"
                        multiple={false}
                        error={errors.banner}
                        onChange={handleChange}
                    />
                    {isOldImage && (
                        <Box sx={{ position: "relative" }}>
                            <img
                                width={50}
                                src={config.endpoint + data.banner}
                                alt="thumb"
                            />
                            <HighlightOffIcon
                                onClick={() => handleChange({ name: "banner", value: ImageActions.IMAGE_DELETED })}
                                sx={{ position: "absolute", top: 0, right: 0 }} />
                        </Box>
                    )}
                </Box>
                <AppTextArea
                    name="bannerDescription"
                    onChange={handleChange}
                    value={data.bannerDescription}
                    placeholder="Описание для баннера"
                    error={errors.bannerDescription}
                />
                <Grid container justifyContent="space-between" mt={1}>
                    <AppButton onClick={onFinish} variant="contained">
                        Отмена
                    </AppButton>
                    <AppButton
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={!isValid || !isUpdated}
                    >
                        Сохранить
                    </AppButton>
                </Grid>
            </form>
        </>
    );
};

export default SetForm;
