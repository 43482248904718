import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { categoryApi } from "./services/category.service";
import { collectionApi } from "./services/collection.service";
import { colorApi } from "./services/color.service";
import { colorEntityApi } from "./services/colorEntity.service";
import { galleryApi } from "./services/gallery.service";
import { productApi } from "./services/product.service";
import { productEntityApi } from "./services/productEntity.service";
import { sizeApi } from "./services/size.service";
import { tagApi } from "./services/tag.service";
import { authApi } from "./services/auth.service";
import authReducer from "./slices/auth.slice";
import { userApi } from "./services/user.service";
import { setApi } from "./services/set.service";
import { deliveryApi } from "./services/delivery.service";
import { orderApi } from "./services/order.service";
import { statusApi } from "./services/status.service";
import { sizeChartApi } from "./services/sizeCharts.service";
import { sizeChartValueApi } from "./services/sizeChartValues.service";
import { promocodeApi } from "./services/promocode.service";
import { orderItemApi } from "./services/orderItem.service";
import { paymentApi } from "./services/payment.service";
import { cdekApi } from "./services/cdek.service";

const store = configureStore({
    reducer: {
        [categoryApi.reducerPath]: categoryApi.reducer,
        [productApi.reducerPath]: productApi.reducer,
        [setApi.reducerPath]: setApi.reducer,
        [productEntityApi.reducerPath]: productEntityApi.reducer,
        [sizeApi.reducerPath]: sizeApi.reducer,
        [galleryApi.reducerPath]: galleryApi.reducer,
        [colorApi.reducerPath]: colorApi.reducer,
        [colorEntityApi.reducerPath]: colorEntityApi.reducer,
        [collectionApi.reducerPath]: collectionApi.reducer,
        [tagApi.reducerPath]: tagApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [deliveryApi.reducerPath]: deliveryApi.reducer,
        [orderApi.reducerPath]: orderApi.reducer,
        [statusApi.reducerPath]: statusApi.reducer,
        [sizeChartApi.reducerPath]: sizeChartApi.reducer,
        [sizeChartValueApi.reducerPath]: sizeChartValueApi.reducer,
        [promocodeApi.reducerPath]: promocodeApi.reducer,
        [orderItemApi.reducerPath]: orderItemApi.reducer,
        [paymentApi.reducerPath]: paymentApi.reducer,
        [cdekApi.reducerPath]: cdekApi.reducer,
        auth: authReducer
    },
    devTools: process.env.NODE_ENV === "development",
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([
            categoryApi.middleware,
            productApi.middleware,
            setApi.middleware,
            productEntityApi.middleware,
            sizeApi.middleware,
            galleryApi.middleware,
            colorApi.middleware,
            colorEntityApi.middleware,
            collectionApi.middleware,
            tagApi.middleware,
            authApi.middleware,
            userApi.middleware,
            deliveryApi.middleware,
            orderApi.middleware,
            statusApi.middleware,
            sizeChartApi.middleware,
            sizeChartValueApi.middleware,
            promocodeApi.middleware,
            orderItemApi.middleware,
            paymentApi.middleware,
            cdekApi.middleware
        ])
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
