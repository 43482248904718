import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import { ProductEntityRequestType } from "types/productEntity/productEntityRequestType";
import { ProductEntityResponseType } from "types/productEntity/productEntityResponseType";
import { productApi } from "./product.service";
import { appBaseQueryWithReauth } from "./http.service";

const productEntityEndpoint = config.apiEndpoint + "/product-entity";

export const productEntityApi = createApi({
    reducerPath: "productEntityApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: productEntityEndpoint }),
    tagTypes: ["ProductEntity"],
    endpoints: (builder) => ({
        createProductEntity: builder.mutation<
            ProductEntityResponseType,
            ProductEntityRequestType
        >({
            query: (payload) => ({
                url: "/",
                method: "POST",
                data: payload
            }),
            invalidatesTags: ["ProductEntity"],
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(productApi.util.invalidateTags(["Product"]));
            }
        }),
        updateProductEntity: builder.mutation<
            ProductEntityResponseType,
            ProductEntityRequestType
        >({
            query: ({ id, ...payload }) => ({
                url: `/${id}`,
                method: "PATCH",
                data: payload
            }),
            invalidatesTags: ["ProductEntity"],
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(productApi.util.invalidateTags(["Product"]));
            }
        }),
        deleteProductEntity: builder.mutation<number, number>({
            query: (id) => ({
                url: `/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["ProductEntity"],
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(productApi.util.invalidateTags(["Product"]));
            }
        })
    })
});

export const {
    useCreateProductEntityMutation,
    useUpdateProductEntityMutation,
    useDeleteProductEntityMutation
} = productEntityApi;
